import React, {useEffect, useState} from "react";
import httpNoAuth from "../../services/httpNoAuthService";
import config from "../../constants";
import {toast} from "react-toastify";
import {validate} from "../common/forms/validation";
import {Redirect, useParams} from "react-router-dom";
import {Input} from "../common/forms/Input";
import Form from "../common/forms/Form";
import {getCurrentUser} from "../../services/authService";
import {passwordErrorMessage, passwordRegEx} from "../../utils/regularExpressions";

const Joi = require('joi');

function PlacerAccessAcceptInvite() {

    const {id, inviteCode} = useParams();
    const [data, setData] = useState()
    const [errors, setErrors] = useState()
    const [invalidInvite, setInvalidInvite] = useState(false)
    const [acceptSuccess, setAcceptSuccess] = useState(false)

    const apiUrl = config.API_URL + "/placer_access_accept/" + id + "/?invite_code=" + inviteCode

    const schema = {
        1: {
            user: {
                password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
                    .messages({"string.pattern.base": passwordErrorMessage}),
                confirm_password: Joi.any()
                    .valid(Joi.ref('password')).label("Confirm Password")
                    .messages({"any.only": "Passwords must match"})
            }
        }
    }

    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {
        try {
            const {data} = await httpNoAuth.get(apiUrl);
            setData(data)
        } catch (e) {
            if (e.response && e.response.status === 404) {
                setInvalidInvite(true)
            }
        }
    }

    function handleValidate() {
        return validate(schema, data)
    }

    function handleUserChange(field, value) {
        let newData = {...data}
        newData["user"][field] = value
        setData(newData)
        setErrors(handleValidate())
    }

    async function handleSubmit() {
        const errors = handleValidate()
        setErrors(errors || {})
        if (errors) return

        try {
            const response = await httpNoAuth.patch(apiUrl, data);
            setData(response.data)
            setAcceptSuccess(true)

            if (getCurrentUser()) {
                toast.success("Invite accepted. You can now access this placer's account.")
            } else {
                toast.success("Invite accepted. You can now log in to access this placer's account.")
            }
        } catch (e) {
            toast.error("Something went wrong")
        }
    }

    if (acceptSuccess) return <Redirect to={{pathname: "/"}}/>

    return (
        <div className="container">

            {invalidInvite &&
                <div className="alert alert-danger">Invalid invite. Please try again or contact Debtplacer.</div>}

            {!invalidInvite && <>
                <h1>Accept Invite</h1>

                {data && <Form onSubmit={handleSubmit}
                               validate={handleValidate}
                               options={{submitButtonLabel: "Accept Invite"}}
                >

                    {!data.user.has_password && <div className="form-row mb-2">
                        <div className="col-6">
                            <p>Choose your account password:</p>
                            <Input
                                placeholder="Password"
                                id="password"
                                type="password"
                                name="password"
                                error={errors ? errors["user.password"] || null : null}
                                value={data.user.password}
                                onChange={(event) => handleUserChange("password", event.target.value)}
                            />
                            <Input
                                placeholder="Confirm Password"
                                id="confirm_password"
                                type="password"
                                name="confirm_password"
                                error={errors ? errors["user.confirm_password"] || null : null}
                                value={data.user.confirm_password}
                                onChange={(event) => handleUserChange("confirm_password", event.target.value)}
                            />
                        </div>
                    </div>}
                </Form>}
            </>}
        </div>
    );
}

export default PlacerAccessAcceptInvite;
