import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export function paginate(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize;
    return _(items)
        .slice(startIndex)
        .take(pageSize)
        .value();
}

const APIPagination = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  return (
      <nav>
        <ul className="pagination">
          {pages.map(page => (
              <li
                  key={page}
                  className={page === currentPage ? "page-item active" : "page-item"}
              >
                <a className="page-link" onClick={() => onPageChange(page)}>
                  {page}
                </a>
              </li>
          ))}
        </ul>
      </nav>
  );
};

const pageCount = (itemsCount, pageSize) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  return pagesCount;
};

APIPagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export {
   APIPagination,
   pageCount
};


