import React from "react";

import auth from "../../services/authService";

import {Link} from 'react-router-dom';
import {getIndustries} from "../../utils/fetchConfiguration";
import {getURLParamString} from "../../utils/search";
import {renderSelect} from "../common/forms/Select";

import _ from "lodash";
import SidebarSearchForm from "./SidebarSearchForm";
import Result from "./Result";
import http from "../../services/httpService";
import config from "../../constants";

import '../../forms.scss';
import '../../search.scss';
import {formatCurrency} from "../common/numberFormats";
import {APIPagination, pageCount} from "../common/pagination";
import SaveSearch from "./SaveSearch";
import {Alert, Spinner} from "react-bootstrap"

const apiPath = "/search_listings/";


class SearchResults extends React.Component {

    state = {
        loading: true,
        user: null,
        listings: [],
        searchParams: {},
        totalResults: 0,
        currentPage: 1,
        pageSize: 20,
        maxDebtAmount: null,
        industries: [],
        userStatus: {},
        daysOverdueOptions: [
            {
                label: "30+ days",
                value: 30,
            },
            {
                label: "60+ days",
                value: 60,
            },
            {
                label: "90+ days",
                value: 90,
            },
            {
                label: "120+ days",
                value: 120,
            }
        ],
        sortOptions: [
            {
                label: "Total Value (High to Low)",
                value: "-total_value"
            },
            {
                label: "Total Value (Low to High)",
                value: "total_value"
            },
            {
                label: "Date Listed (Newest to Oldest)",
                value: "-published_date"
            },
            {
                label: "Date Listed (Oldest to Newest)",
                value: "published_date"
            }
        ],
        errors: []
    };

    getDefaultSearchParams = () => {
        return {
            keywords: null,
            debtor_industry: null,
            placer_industry: null,
            days_overdue: null,
            amount_min: null,
            amount_max: null,
            exclude_disputed: false,
            only_personally_guaranteed: false,
            order_by: null,
        };
    }

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        const user = auth.getCurrentUser();
        const userStatus = await auth.getUserStatus();
        const {data: industries} = await getIndustries();

        // set max amount based on user's plan
        let {maxDebtAmount} = this.state;
        let searchParams = this.getDefaultSearchParams();

        // set the search parameters from url
        const paramsStr = this.props.location.search
        const urlParams = new URLSearchParams(paramsStr);

        for (const [key, value] of Object.entries(searchParams)) {
            if (urlParams.has(key)) {

                let newValue = urlParams.get(key)

                if (!isNaN(newValue) && parseInt(newValue) >= 0) {
                    newValue = parseInt(newValue);
                }

                if (newValue === "true") newValue = true;
                if (newValue === "false") newValue = false;
                searchParams[key] = newValue;
            }
        }

        if (userStatus.maximum_listing_value !== null) {
            let amount_max = searchParams.amount_max
            if (amount_max > userStatus.maximum_listing_value && userStatus.maximum_listing_value) {
                searchParams.amount_max = userStatus.maximum_listing_value;
            }
            maxDebtAmount = userStatus.maximum_listing_value;
        }

        this.setState({user, industries, searchParams, maxDebtAmount, loading: true, userStatus});

        await this.loadListings();
    }

    async loadListings() {
        // load listings from API
        let params = Object.assign({}, this.state.searchParams)

        var re = new RegExp("^(\\d *?){11}$");
        let isAbn = re.test(params.keywords)
        if (isAbn) params.keywords = params.keywords.replace(/ /g, '')

        const urlParams = getURLParamString(params);

        const {data: response} = await http.get(config.API_URL + apiPath + "?" + urlParams + "&page=" + this.state.currentPage);
        this.setState({
            loading: false,
            listings: response.results,
            totalResults: response.count
        });
    }

    handleSetPage = (page) => {
        this.setState({currentPage: page, loading: true});
        this.loadListings();
    }

    handleAmountChange = ({currentTarget: input}) => {
        const {searchParams, maxDebtAmount, errors} = this.state;
        let value = Number(input.value.replace(/\D/g,''))

        _.set(searchParams, input.name, value);

        if (input.name === "amount_max") {
            if (value <= 0) {
                _.set(searchParams, input.name, maxDebtAmount);
            }
            if (value > maxDebtAmount && maxDebtAmount) {
                errors["amount_max"] = "Upgrade your subscription to access debts over " + formatCurrency(maxDebtAmount);
                _.set(searchParams, input.name, maxDebtAmount);
            }
        }

        this.setState({searchParams, errors})

    };

    handleChange = ({currentTarget: input}) => {

        const {searchParams, errors} = this.state;

        if (input.type === 'checkbox') {
            _.set(searchParams, input.name, input.checked);
        } else {
            _.set(searchParams, input.name, input.value);
        }

        this.setState({searchParams, errors})

    };

    handleSelect = (name, value) => {
        const {searchParams} = this.state;
        _.set(searchParams, name, value);
        this.setState({searchParams});
    }

    handleSubmit = async (e) => {
        this.setState({loading: true});

        // load new result based on state search params
        await this.loadListings();

        // update URL without reloading the page
        const urlParams = getURLParamString(this.state.searchParams);
        history.pushState({}, null, "/results?" + urlParams);
    };

    handleSort = async (e) => {
        this.handleChange(e)
        await this.handleSubmit(e);
    }

    handleClearFilters = async (e) => {
        if (e) e.preventDefault();

        await this.setState({
            searchParams: this.getDefaultSearchParams()
        });

        await this.handleSubmit();
    }

    findOutMore(id) {
        return () => {
            sessionStorage.setItem("search", JSON.stringify(this.props.location))
            this.props.history.push("/listings/" + id)
        }
    }

    render() {

        const {
            industries, errors, daysOverdueOptions, searchParams, sortOptions,
            listings, maxDebtAmount, totalResults, currentPage, pageSize, userStatus, loading
        } = this.state;

        return (
            <div className="container container-wide searchResult pt-0">

                <div className="container-row row">
                    <div className="col-sm-3 sidebarFilters">
                        <SidebarSearchForm
                            handleChange={this.handleChange}
                            handleAmountChange={this.handleAmountChange}
                            handleSelect={this.handleSelect}
                            handleSubmit={this.handleSubmit}
                            handleClearFilters={this.handleClearFilters}
                            searchParams={searchParams}
                            industries={industries}
                            daysOverdueOptions={daysOverdueOptions}
                            maxDebtAmount={maxDebtAmount}
                            errors={errors}
                        />
                    </div>

                    <div className="col-sm-9 searchResults">

                        <div className="searchResultsHeader">

                            <div className="row pull-right" style={{width: 500}}>
                                <div className="col-sm-8 padding-5">
                                    {renderSelect(searchParams, [], this.handleSort, "order_by", "", sortOptions, "Sort By", "value", "label")}
                                </div>
                                <div className="col-sm-4 padding-5">
                                    <SaveSearch searchParams={searchParams}/>
                                </div>
                            </div>
                            <h2>Search Results ({loading ? null : totalResults + " total"}) </h2>
                            {listings.length === 0 || loading ? null : <>Page {currentPage}
                            of {pageCount(totalResults, pageSize)}</>}


                        </div>

                        <div className="searchResultsBody">

                            {userStatus.maximum_listing_value === 0 &&
                                <Alert className="mt-3" variant='warning'><Link to="/subscription">Upgrade your plan</Link> to find new listings.</Alert>}

                            {loading ? <Spinner className="spinner" animation="border" role="status"/>
                                : listings.length === 0 ? <Alert variant="light">
                                    No listings matched your search query.
                                    {userStatus.title === 'Unlimited' && " Try searching with wider criteria."}
                                    {userStatus.title !== 'Unlimited' && " Try searching with wider criteria or upgrade your plan to access higher value debts."}
                                </Alert> : listings.map(listing => (
                                    <Result key={listing.id} listing={listing}
                                            findOutMore={this.findOutMore(listing.id)}/>
                                ))}
                        </div>

                        <div className="searchResultsPagination">
                            {
                                listings.length === 0 || loading ? null :
                                    <APIPagination currentPage={currentPage} itemsCount={totalResults}
                                                   onPageChange={this.handleSetPage} pageSize={pageSize}/>
                            }
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default SearchResults;
