import React, {Component} from "react";
import {formatAbn} from '../common/numberFormats'

class DebtorContact extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { debtor } = this.props;

        return (
            <div className="debtorDetails">
                {debtor.name} <br/>

                {/*Businesses*/}
                {debtor.debtor_type === 'business' &&
                <div>
                    {debtor.industry_description} <br/>
                    ABN: {formatAbn(debtor.abn)}
                </div>
                }
                <br/>
                {debtor.address_1 && <div>{debtor.address_1} <br/></div>}
                {debtor.address_2 && <div>{debtor.address_2} <br/></div> }
                {debtor.suburb} {debtor.state} {debtor.zip_code}<br/>
                <br/>
                {debtor.contact_name && <div>{debtor.contact_name} <br/></div>}
                {debtor.contact_email && <div>{debtor.contact_email} <br/></div> }
                {debtor.contact_phone && <div>{debtor.contact_phone} </div> }
            </div>
        );
    }
}

export default DebtorContact;
