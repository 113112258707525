import {React} from "react";

import ImportInvoices from "./ImportInvoices";


export default function StartListingStep({token, activeService, services, handleCreateListingManually, handleLoadListingFromInvoices}) {

    return (
        <>
            {!token && <>
                <div className="createListingOptions">
                    <h2>Connect with your accounting software</h2>
                    <p>Easily create a listing by select invoices from your accounting software.</p>

                    <ImportInvoices token={token} service={activeService} availableServices={services}
                                    handleLoadListingFromInvoices={handleLoadListingFromInvoices}
                                    handleCreateListingManually={handleCreateListingManually} />
                </div>

                <div className="createListingOptions manual">
                    <h2>Accounting software not listed?</h2>
                    <p>Get started by listing your overdue debts in 3 easy steps.</p>

                    <div className="manualTile" onClick={handleCreateListingManually}>
                        <span>Continue <i className="fa fa-arrow-right"></i></span>
                    </div>

                </div>
            </>}

            {token && <ImportInvoices token={token} service={activeService} availableServices={services}
                            handleLoadListingFromInvoices={handleLoadListingFromInvoices}
                            handleCreateListingManually={handleCreateListingManually} />}

        </>
    )
}