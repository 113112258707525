import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import http from "../../services/httpService";
import httpNoAuth from "../../services/httpNoAuthService";
import config from "../../constants";
import ListingDetailBody from "./ListingDetailBody";
import ListingSummary from "./ListingSummary";
import OfferSummary from "./OfferSummary";
import {Button, Tab, Tabs} from "react-bootstrap"
import {StickyContainer, Sticky} from 'react-sticky';
import {formatAbn} from '../common/numberFormats';
import ProgressTrackerCollectionPartner from "./ProgressTrackerCollectionPartner";
import {getOptionSet} from "../../utils/fetchConfiguration";
import {getUserStatus} from "../../services/authService";
import {Media} from "../../AppMedia";

const apiPath = "/collection_partner_listings/";

export default function CollectionPartnerListingDetail (props) {

    const [listing, setListing] = useState()
    const [resolvedAmountOptions, setResolvedAmountOptions] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [loggedInUser, setLoggedInUser] = useState(false)
    const {listingId, debtId} = useParams();

    let debtor = null
    let debt = null
    if (listing) {
        if (listing.debts?.length === 1) {
            debt = listing.debts[0]
            debtor = debt.debtor
        }
        else if (debtId) {
            debt = listing.debts.find(debt => debt.id === parseInt(debtId))
            debtor = debt.debtor
        }
    }

    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {

        // check whether user is logged in or not
        const userStatus = await getUserStatus(false);
        const loggedInUser = userStatus && userStatus.hasOwnProperty('timestamp');

        var apiUrl = config.API_URL + apiPath + listingId + "/";
        const resp = await executeHttpGet(apiUrl);
        const listing = resp.data;

        // resolved amount options
        const {data: resolvedAmountOptions} = await getOptionSet("RESOLVED_AMOUNT");

        setListing(listing)
        setResolvedAmountOptions(resolvedAmountOptions[0].options)
        setLoggedInUser(loggedInUser)
        setLoaded(true)

    }

    function backToPrevious() {
        props.history.goBack()
    }

    async function executeHttpGet (apiUrl) {
        if (props.secret && !loggedInUser) {
            apiUrl += "?secret=" + props.secret;
            return await httpNoAuth.get(apiUrl);
        }
        return await http.get(apiUrl);
    }

    async function executeHttpPost (apiUrl, data={}) {
        if (props.secret && !loggedInUser) {
            apiUrl += "?secret=" + props.secret;
            return await httpNoAuth.post(apiUrl, data);
        }
        return await http.post(apiUrl, data);
    }

    async function completeLetterOfDemand () {
        const apiUrl = config.API_URL + apiPath + listing.id + "/complete_letter_of_demand/";
        const resp = await executeHttpPost(apiUrl, {debt_id: debt.id});
        setListing(resp.data)
    }

    async function commenceNegotiations () {
        const apiUrl = config.API_URL + apiPath + listing.id + "/commence_negotiations/";
        const resp = await executeHttpPost(apiUrl, {debt_id: debt.id});
        setListing(resp.data)
    }

    async function negotiationsOngoing () {
        const apiUrl = config.API_URL + apiPath + listing.id + "/negotiations_ongoing/";
        const resp = await executeHttpPost(apiUrl, {debt_id: debt.id});
        setListing(resp.data)
    }

    async function markCourtActionRequired () {
        const apiUrl = config.API_URL + apiPath + listing.id + "/mark_court_action_required/";
        const resp = await executeHttpPost(apiUrl, {debt_id: debt.id});
        setListing(resp.data)
    }

    async function resolveListing (resolved_type, resolved_amount) {
        const apiUrl = config.API_URL + apiPath + listing.id + '/resolve/';
        const resp = await executeHttpPost(apiUrl, {resolved_type, resolved_amount, debt_id: debt.id});
        setListing(resp.data)
    }

    let search = sessionStorage.getItem("search");
    if (!loaded) return (<div></div>);

    const offer = listing.own_offer;

    const showTabs = debt && (listing.status === 'resolved' || listing.status === 'offer_accepted');

    let defaultActiveTab = "listing";
    if (debt && (listing.status === 'offer_accepted' || listing.status === 'resolved'))
        defaultActiveTab = "track-progress"

    return (
        <div className="container listingDetail">
            <StickyContainer>
                <div className="row">
                    <div className="col-md-12  padding-20">

                        {search && !debtId && <Button
                            onClick={backToPrevious}
                            variant="secondary">
                            <i className="fa fa-arrow-left"/> &nbsp;
                            Back to {search ? "Results" : "Dashboard"}
                        </Button>}

                        {!search && !debtId &&
                        <Link to={`/dashboard`}>
                            <button className="btn btn-secondary">
                                <i className="fa fa-arrow-left"/> &nbsp; Back to Dashboard
                            </button>
                        </Link>}

                        {debtId &&
                            <a className="btn btn-secondary" href={`/listings/${listingId}`}>
                                    <i className="fa fa-arrow-left"/> &nbsp; Back to Listing</a>}
                        <br/>
                        <br/>
                    </div>
                    <div className="col-md-8">

                        <div className="listingHeader">

                            {/* Just one debtor */}
                            {debtor && <React.Fragment>
                                <h1 style={{"marginBottom": "15px"}}>{debtor.name}</h1>

                                {debtor.debtor_type === 'business' &&
                                    <div>ABN: {formatAbn(debtor.abn)} | {debtor.industry_description} <br/>
                                        Listing ID {listing.reference}</div>}
                                {debtor.debtor_type === 'individual' &&
                                    <div>Individual | Listing ID {listing.reference}</div>}

                                {debtId &&
                                    <div>Part of Listing <a
                                        href={`/listings/${listingId}`}>{listing.reference}</a>
                                    </div>}

                            </React.Fragment>}

                            {/* Multiple debtors */}
                            {!debtor && <React.Fragment>
                                <h1 style={{"marginBottom": "15px"}}>{listing.debtor_names_abbr}</h1>
                                <div>{listing.placer.industry_description} | {listing.number_of_debts} Debts</div>
                            </React.Fragment>}

                        </div>

                        <div className="listingBody">
                            <OfferSummary offer={offer}/>

                            {!showTabs &&
                            <ListingDetailBody listing={listing} debt={debt} viewer="collection_partner"/>}

                            {showTabs &&
                            <Tabs defaultActiveKey={defaultActiveTab} id="listing-detail-tabs">
                                <Tab eventKey="listing" title="Listing">
                                    <ListingDetailBody listing={listing} debt={debt} viewer="collection_partner" />
                                </Tab>
                                 <Tab eventKey="track-progress" title="Track Progress">
                                    <ProgressTrackerCollectionPartner listing={listing}
                                                                      debt={debt}
                                                                      handleCompleteLetterOfDemand={completeLetterOfDemand}
                                                                      handleCommenceNegotiations={commenceNegotiations}
                                                                      handleNegotiationsOngoing={negotiationsOngoing}
                                                                      handleMarkCourtActionRequired={markCourtActionRequired}
                                                                      handleResolveListing={resolveListing}
                                                                      resolvedAmountOptions={resolvedAmountOptions}
                                    />
                                </Tab>
                            </Tabs>}

                        </div>

                    </div>
                    <div className="col-md-4">
                        <Media at='mobile'>
                            <ListingSummary listing={listing} debt={debt}
                                            loggedInUser={loggedInUser} viewer="collection_partner"
                                            reload={loadData}/>
                        </Media>
                        <Media greaterThan='mobile'>
                            <Sticky topOffset={80} disableCompensation>{({style}) => {
                                let newStyle = {...style}
                                newStyle.top = 10
                                return <div style={newStyle}>
                                    <ListingSummary listing={listing} debt={debt}
                                                    loggedInUser={loggedInUser} viewer="collection_partner"
                                                    reload={loadData}/>
                                </div>
                            }}
                            </Sticky>
                        </Media>


                    </div>
                </div>
            </StickyContainer>

        </div>
    );

}
