import React, {Component} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {CheckboxGroup} from "../common/forms/Checkbox";
import {APIPagination, paginate} from "../common/pagination";
import {overdueDisplay} from "../../utils/listingRenderers";
import _ from "lodash";
import {filterWithArray} from "../../utils/filtering";
import Table from "../common/table";
import {Link} from "react-router-dom";
import Resolve from "../common/resolve";
import {Input} from "../common/forms/Input";
import {Alert, Badge, Spinner} from "react-bootstrap"
import {getOptionSet} from "../../utils/fetchConfiguration";
import {Media} from '../../AppMedia'
import MobileTableCollectionPartner from "../common/mobileTableCollectionPartner";
import moment from "moment";

var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});

class CollectionPartnerDashboard extends Component {

    columns = [
        {
            path: "reference",
            label: "Listing ID",
            key: "reference",
            sort: true,
            content: listing => ( <Link to={`/listings/${listing.id}`}> {listing.reference} </Link>)
        },
        {
            path: "debtor_names_abbr",
            label: "Debtor",
            key: "debtor_names",
            sort: true
        },
        {
            // for search only, will be hidden
            path: "debtor_names",
            label: "Debtor",
            key: "debtor_names_search",
            sort: true,
            hide: true
        },
        {
            label: "Total Value",
            key: "total_value",
            sort: true,
            path: "total_value",
            content: listing => (
                <span>{formatter.format(listing.total_value)}</span>
            )
        },
        {
            label: "Overdue",
            key: "overdue",
            sort: true,
            path: "min_overdue_days",
            content: listing => (
                overdueDisplay(listing)
            )
        },
        {
            path: "collection_partner_status_display",
            label: "Status",
            key: "status",
            sort: true,
            content: listing => {
                let status_display = listing.collection_partner_status_display;
                return <><Badge bsPrefix={"status"} variant={listing.collection_partner_status}> {status_display}</Badge>
                    {listing.collection_partner_status === 'offer_accepted' && listing.offer_document_status && <p className="ata-status">Authority to Act Document {listing.offer_document_status}</p>}
                </>;
            }
        },
        {
            label: "Awarded date",
            key: "awarded_date",
            sort: true,
            path: "awarded_date",
            content: listing => {
                if (!listing.awarded_date) return null
                return <>
                    <span className="d-none">{moment(listing.awarded_date).valueOf()}</span>
                    <span>{moment(listing.awarded_date).format("DD/MM/YYYY")}</span>
                    </>
            }
        },
        {
            path: "placer.business_name",
            label: "Client",
            key: "placer",
            sort: true
        },
        {
            key: "download",
            sort: false,
            content: listing => {
                if (listing.status === 'offer_accepted' || listing.status === 'resolved') return (
                <a href={`${config.API_URL}/collection_partner_listing_download/${listing.id}/download/?secret=${listing.secret}`} target="_blank">
                    <i style={{cursor: "pointer"}}
                       className="download-dashboard"
                       aria-hidden="true"/>
                </a>)
            }
        },
        {
            key: "messenger",
            sort: false,
            content: listing => <Link to={`/messenger${listing.status === 'offer_accepted' && listing.chats.length >=1 ? "/"+listing.chats[0]: '?listing='+listing.id}`}>
                    {listing.has_unread_chats ?
                        <span className="fa-stack fa-5x has-badge">
                        <i style={{cursor: "pointer"}}
                           className="envelope"
                           aria-hidden="true"/>
                                    </span>
                        :
                        <i style={{cursor: "pointer"}}
                           className="envelope"
                           aria-hidden="true"/>

                    }
                </Link>
        },
        {
            key: "link",
            sort: false,
            content: listing => <Link className="listingDetail" to={`/listings/${listing.id}`}>
                <i style={{cursor: "pointer"}} className="chevron"/> </Link>
        },
        {
            path: "days_since_published",
            label: "Published",
            key: "days_since_published",
            sort: true,
            hide: true
        },
    ];

    state = {
        loading: true,
        listings: [],
        statuses: [],
        currentPage: 1,
        pageSize: 10,
        searchQuery: "",
        selectedStatuses: ["active", "offer_pending", "offer_accepted", "invited"],
        resolvedAmountOptions: [],
        sortColumn: {path: "debtor.name", order: "asc"}
    };

    async componentDidMount() {
        sessionStorage.removeItem('search');
        const {data: statuses} = await http.get(config.API_URL + "/collection_partner_listing_statuses/");
        const {data: listings} = await http.get(config.API_URL + "/collection_partner_listings/dashboard/");
        const {data: resolvedAmountOptions} = await getOptionSet("RESOLVED_AMOUNT");

        this.setState({listings, statuses, loading: false,
        resolvedAmountOptions: resolvedAmountOptions[0].options});
    };

    handleResolve(listing) {
        return async (resolved_type, resolved_amount) => {
            const listings = [...this.state.listings];
            const index = listings.indexOf(listing);
            listings[index] = {...listings[index]};
            const {data: updatedListing} = await http.post(config.API_URL + "/collection_partner_listings/" + listing.id + '/resolve/', {resolved_type, resolved_amount});
            listings[index] = updatedListing;
            this.setState({listings});
        }
    };

    handlePageChange = page => {
        this.setState({currentPage: page});
    };

    handleStatusSelect = async (e) => {
        // needs to be here to control the state from this component (to clear all)
        const item = e.target.name;
        const isChecked = e.target.checked;

        let selectedStatuses = this.state.selectedStatuses;
        let prevselectedStatuses = [...this.state.selectedStatuses]

        let state = {searchQuery: "", currentPage: 1}
        if (isChecked) selectedStatuses.push(item);
        else selectedStatuses = selectedStatuses.filter(e => e !== item);
        this.setState({loading: true, selectedStatuses});

        if (!prevselectedStatuses.includes('resolved') && item === 'resolved' && isChecked) {
            const {data: listings} = await http.get(config.API_URL + "/collection_partner_listings/dashboard/?resolved=true");
            state.listings = listings
        } else if (prevselectedStatuses.includes('resolved') && item === 'resolved' && !isChecked) {
            const {data: listings} = await http.get(config.API_URL + "/collection_partner_listings/dashboard/?resolved=false");
            state.listings = listings
        }
        state.selectedStatuses = selectedStatuses
        state.loading = false

        this.setState(state);
    };

    handleSearch = ({target: {value}}) => {
        this.setState({searchQuery: value, selectedStatus: null, currentPage: 1});
    };

    clearFilters = (e) => {
        e.preventDefault();
        this.setState({searchQuery: "", selectedStatuses: [], currentPage: 1});
    };

    handleSort = sortColumn => {
        this.setState({sortColumn});
    };

    isIncludedInSearch = (listing, searchQuery) => {
        // add more search lookups here when needed
        return (
            (listing.debtor_names && listing.debtor_names?.toLowerCase().includes(searchQuery.toLowerCase()))
         || listing.placer.business_name?.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            selectedStatuses,
            searchQuery,
            listings: allListings
        } = this.state;

        let filtered = allListings.map(listing => {
            listing.total_value = Number(listing.total_value)
            return listing
        });

        if (searchQuery) {
            filtered = allListings.filter(m => this.isIncludedInSearch(m, searchQuery));
        }

        else if (selectedStatuses.length > 0)
            filtered = filterWithArray(allListings, selectedStatuses, "collection_partner_status", "value");

        const sorted = _.orderBy(filtered, (item) => [_.get(item, sortColumn.path, 0)], [sortColumn.order]);

        const listings = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, data: listings};
    };


    render() {
        const {length: count} = this.state.listings;
        const {pageSize, currentPage, sortColumn, searchQuery, loading} = this.state;
        // const { user } = this.props;

        const {totalCount, data: listings} = this.getPagedData();

        return (
            <div className="container container-wide searchResult pt-0">
                <div className="container-row row">

                    <div className="col-sm-12 col-lg-3  sidebarFilters">
                        <div className="searchFormHeader">
                            <span className="pull-right"><a onClick={this.clearFilters} href="src/components/dashboard/CollectionPartnerDashboard">Clear All</a></span>
                            <h3>Filter</h3>
                        </div>
                        <form className="sidebarSearchForm">
                            <div className="form-row">
                                <h5>Search</h5>
                                <br/>
                                <Input
                                    type="text"
                                    name="keywords"
                                    value={searchQuery || ""}
                                    label="Enter name, industry or ABN"
                                    onChange={this.handleSearch}
                                    placeholder="Search by Debtor Name"
                                    iconClass="fa fa-search"
                                    labelClass="sr-only"
                                />
                            </div>
                            <div className="form-row">
                                <h5>Status</h5>
                                <br/>
                                <CheckboxGroup
                                    items={this.state.statuses}
                                    selectedItems={this.state.selectedStatuses}
                                    onChange={this.handleStatusSelect}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-sm-12 col-lg-9  searchResults">
                        <br/>
                        {!this.props.userStatus?.terms_completed &&
                        <Alert variant='danger'>Please add your terms and conditions to your profile before making an offer on any debt listing - <Link to={`/profile?edit=terms`}>Add Terms and Conditions</Link> </Alert>}
                        <div className="searchResultsHeader">
                            <div className="pull-right" style={{width: 300}}>
                            </div>
                            <h2>Dashboard</h2>
                        </div>
                        <div className="searchResultsBody">
                            {loading ? <Spinner className="spinner" animation="border" role="status"/>
                                : totalCount === 0 ? <Alert variant="light">
                                    Listings you have made an offer on or been invited to will appear here.
                                </Alert> : <>
                                <Media at='mobile'>
                                    <MobileTableCollectionPartner
                                        columns={this.columns}
                                        data={listings}
                                        sortColumn={sortColumn}
                                        onSort={this.handleSort}
                                    />
                                </Media>
                                <Media greaterThan='mobile'>
                                    <Table
                                        columns={this.columns}
                                        data={listings}
                                        sortColumn={sortColumn}
                                        onSort={this.handleSort}
                                    />
                                </Media></>
                            }
                        </div>
                        <div className="searchResultsPagination">
                            {
                                totalCount === 0 || loading ? null: <APIPagination currentPage={currentPage} itemsCount={totalCount}
                                                     onPageChange={this.handlePageChange} pageSize={pageSize}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CollectionPartnerDashboard;
