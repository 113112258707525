import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {formatCurrency} from "../common/numberFormats";

class Debts extends Component {

    state = {
        expanded: false
    }
    constructor(props) {
        super(props);
    }

    toggleExpanded = (e) => {
        this.setState({expanded: !this.state.expanded});
    };
    render() {

        const { debts, listing } = this.props;

        return (
            <div>
                <div style={{width: "100%", textAlign: "center"}}>
                    <button className="btn btn-secondary expandButton"
                            type="button" onClick={this.toggleExpanded}>
                        {!this.state.expanded && <span>View Breakdown</span>}
                        {this.state.expanded && <span>Hide</span>}
                    </button>
                </div>

                {this.state.expanded &&
                <table className="debts listingTable table-striped">
                    <thead>
                    <tr>
                        <th>Debtor</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {debts?.map(debt => (
                        <tr key={debt.id}>
                            <td>{debt.debtor.name}</td>
                            <td>{formatCurrency(debt.amount)}</td>
                            <td>{debt.days_overdue} days</td>
                            <td><a href={`/listings/${listing.id}/debts/${debt.id}`}><i style={{cursor: "pointer"}} className="chevron"/></a></td>
                        </tr>
                    ))}
                    </tbody>
                </table>}

            </div>
        );
    }
}

export default Debts;
