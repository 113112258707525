import React, {Component} from "react";
import {Link} from "react-router-dom";
import http from "../../services/httpService";
import config from "../../constants";
import {Ratings} from "../common/ratings";
import {Card, Button, Container, Row, Col, Badge} from "react-bootstrap"
import moment from 'moment'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import InviteListing from '../common/inviteListing'
import {toast} from "react-toastify";

const apiPath = "/collection_partners/";

class CollectionPartnerDetail extends Component {

    state = {
        data: {},
        errors: {},
        loaded: false
    };

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        // get collection partner
        const collectionPartnerId = this.props.match.params.id;
        const resp = await http.get(config.API_URL + apiPath + collectionPartnerId + "/");
        const collectionPartner = resp.data;

        this.setState({
            data: collectionPartner,
            loaded: true
        });
    }

    async reload(success) {
        const collectionPartnerId = this.props.match.params.id;
        const resp = await http.get(config.API_URL + apiPath + collectionPartnerId + "/");
        const collectionPartner = resp.data;

        this.setState({
            data: collectionPartner,
            loaded: true,
            success
        });
    }

    render() {

        if (!this.state.loaded) return (<div></div>);

        const collectionPartner = this.state.data;

        if (this.state.error) {
            toast.error(this.state.error);
        }
        if (this.state.success) {
            toast.success(this.state.success, {autoClose: 3000});
        }

        const listCards = () => {
            return collectionPartner.reviews.map((review, idx) => {
                    return <Card key={idx}>
                        <Card.Body>
                            <Row noGutters>
                                <Col xs={12}>
                                    <Card.Title>{review.placer}</Card.Title>
                                    <Card.Subtitle>
                                        <Ratings value={review.rating}></Ratings> &nbsp;
                                        <span className={'text-muted'}>{moment(review.creation_date).format('MMMM YYYY')}</span>
                                    </Card.Subtitle>
                                </Col>
                            </Row><br/>
                            <Row noGutters><Card.Text>{review.comments}</Card.Text></Row>
                            <br/>
                            <span className="tag-names">{review.tags.map((tag, index) => <Badge key={index} pill variant="secondary">{tag}</Badge>)}
                            </span>
                        </Card.Body>
                    </Card>
                }
            )
        }
        return (
            <div className="container listingDetail">

                <div className="row">
                    <div className="col-md-12  padding-20">
                        <Link to={'/collection-partners'}>
                            <Button
                                variant="secondary">
                                <i className="fa fa-arrow-left"/> &nbsp;
                                Back to Search Results
                            </Button>
                        </Link>
                    </div>
                    <div className="col-md-12">
                        <br/>
                        <div className="listingHeader">
                            <InviteListing reload={this.reload.bind(this)} collectionPartner={collectionPartner}/>

                            <h1>{collectionPartner.business_name}</h1>

                            {collectionPartner.reviews.length > 0 &&
                            <Ratings value={collectionPartner.rating_avg}> {collectionPartner.reviews.length}
                                &nbsp;reviews </Ratings>
                            }
                            <br/>
                            <br/>
                            <Card.Text>{collectionPartner.suburb}, {collectionPartner.state}</Card.Text>
                            <Card.Text>
                                {collectionPartner.description}
                            </Card.Text>
                        </div>

                        {collectionPartner.reviews.length > 0 &&
                        <div className="listingBody" style={{marginBottom: 80, marginTop: 30}}>
                            <h5>Reviews</h5>
                            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 800: 2}}>
                                <Masonry gutter={"20px"}>
                                    {listCards()}
                                </Masonry>
                            </ResponsiveMasonry>

                        </div>}

                        {collectionPartner.terms.length > 0 &&
                            <div className="listingBody" style={{marginBottom: 80, marginTop: 30}}>
                                <h5>Terms & Conditions</h5>
                                {collectionPartner.terms.map((termsItem, idx) => (
                                    <div key={idx}>
                                        <div className="termsDate"><span className="text-muted">Updated {moment(termsItem.creation_date).format("DD/MM/YYYY")}</span></div>
                                        <div dangerouslySetInnerHTML={{__html: termsItem.content}}></div>
                                    </div>
                                    )
                                )}
                            </div>}


                    </div>

                </div>

            </div>
        )
            ;
    }
}

export default CollectionPartnerDetail;
