import React from "react";
import {ToastContainer} from "react-toastify";
import TopBar from "../components/layout/TopBar";
import NavBar from "../components/layout/navBar";

export default function MainLayout({children, user, userStatus}) {

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={false}
            />
            <TopBar user={user} userStatus={userStatus}/>

            <div className="mainAndNavContainer container-full">
                <NavBar user={user} userStatus={userStatus}/>
                <main className="mainContent">
                    {children}
                </main>
            </div>

        </>
    );
}
