import React from 'react'
import {Prompt} from 'react-router-dom'
import {Modal} from "react-bootstrap";

export class RouteLeavingGuard extends React.Component {
    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    }
    showModal = (location) => this.setState({
        modalVisible: true,
        lastLocation: location,
    })
    closeModal = (callback) => this.setState({
        modalVisible: false
    }, callback)

    handleBlockedNavigation = (nextLocation) => {
        const {confirmedNavigation} = this.state
        const {shouldBlockNavigation} = this.props
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation)
            return false
        }
        return true
    }
    handleConfirmNavigationClick = () => this.closeModal(() => {
        const {navigate} = this.props
        const {lastLocation} = this.state
        if (lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                navigate(lastLocation.pathname)
            })
        }
    })

    render() {
        const {when} = this.props
        const {modalVisible, lastLocation} = this.state
        return (
            <>
            <Prompt
                when={when}
                message={this.handleBlockedNavigation}/>

            <Modal show={modalVisible} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Navigation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You haven't saved your changes.
                        <br/>
                        Are you sure you want to leave this page?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        type="button" onClick={()=>this.closeModal()}>
                        Stay on this Page
                    </button>
                    <button
                        className="btn btn-default"
                        type="button" onClick={this.handleConfirmNavigationClick}>
                        Leave this Page
                    </button>
                </Modal.Footer>
            </Modal>
            </>
        )
    }
}

export default RouteLeavingGuard