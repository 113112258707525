import React, {useState} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {lookupAbn, lookupBusinessName} from "../../utils/abnLookup";
import {apiUrlRegEx, urlRegEx} from "../../utils/regularExpressions";
import {baseHandleAddressChange, baseHandleChange} from "../common/forms/helpers";
import {validate} from "../common/forms/validation";
import Form from "../common/forms/Form";
import {renderInput} from "../common/forms/Input";
import {renderSelect} from "../common/forms/Select";
import {loadAddressFinderScript} from "../../utils/addressFinder";
import {renderTextArea} from "../common/forms/TextArea";
import {Tabs, Tab} from "react-bootstrap"

import {EditorState, ContentState, convertFromHTML, convertToRaw} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import toolbarConfig from '../../utils/wysiwygToolbarConfig'
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {getSetting} from "../../utils/fetchConfiguration";
import _ from "lodash";
import CurrencyInput from "../common/forms/currencyInput";

const Joi = require('joi');
const apiPath = "/collection_partner_profiles/";

class CollectionPartnerProfile extends React.Component {

    state = {
        data: {
            business_name: "",
            abn: "",
            address_1: "",
            address_2: "",
            suburb: "",
            zip_code: "",
            state: "",
            user: {
                first_name: "",
                last_name: ""
            },
            contact_role: "",
            phone: "",
            website: null,
            debtcol_api_username: null,
            debtcol_api_password: null,
            debtcol_api_key: null,
            debtcol_api_url: null,
            preferred: false,
            commission_rates: []
        },
        contactRoles: [],
        addressFinderWidget: null,
        addressPathBase: "",
        abnValidatedClass: "",
        debtColIntegrationEnabled: false,
        errors: {},
        isLoading: false
    };

    schema = {
        1: {
            id: Joi.number(),
            business_name: Joi.string().label("Business name"),
            abn: Joi.string().label("ABN"),
            address_1: Joi.any(),
            address_2: Joi.any(),
            suburb: Joi.string(),
            zip_code: Joi.string().length(4).label("Post code"),
            state: Joi.string().max(4).label("State"),
            user: {
                first_name: Joi.string(),
                last_name: Joi.string()
            },
            contact_role: Joi.any(),
            phone: Joi.string(),
            website: Joi.string().regex(urlRegEx, {}).allow(null, '')
                .messages({
                    "object.regex": "Please enter a valid URL starting with http:// or https://",
                    "string.pattern.base": "Please enter a valid URL starting with http:// or https://"
                })
                .label("Website"),
            debtcol_api_username: Joi.any(),
            debtcol_api_password: Joi.any(),
            debtcol_api_key: Joi.any(),
            debtcol_api_url: Joi.string().regex(apiUrlRegEx, {}).allow(null, '')
                .messages({
                    "object.regex": "Your DebtCol API URL looks similar to this: https://company.webstream.net.au/integration/111/ - please make sure it starts with https:// and ends with a / ",
                    "string.pattern.base": "Your DebtCol API URL looks similar to this: https://company.webstream.net.au/integration/111/ - please make sure it starts with https:// and ends with a / "
                })
                .label("DebtCol API URL"),
            commission_rates: Joi.array().items(Joi.object({
                id: Joi.number().allow(null),
                from_amount: Joi.number().integer().allow(null, '').label("Amount from"),
                to_amount: Joi.number().integer().allow(null, '').label("Amount to"),
                commission_rate: Joi.number().max(100).label("Commission percentage")
            })),
        }
    };

    async componentDidMount() {
        loadAddressFinderScript(this.loadWidget); // address finder
        const paramsStr = this.props.location
        await this.loadData();
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    loadWidget = () => {
        this.addressFinderWidget = new AddressFinder.Widget(
            document.getElementById("address_1"),
            config.ADDRESS_FINDER_API_KEY,
            'AU'
        );
        this.addressFinderWidget.on('result:select', (fullAddress, metaData) => {
            const {data} = baseHandleAddressChange(this.state.data, metaData);
            this.setState({data});
        });
    }

    componentWillUnmount() {
        if (this.addressFinderWidget) {
            this.addressFinderWidget.destroy()
            this.addressFinderWidget = null
        }
    };

    async loadData() {
        const {data: collectionPartners} = await http.get(config.API_URL + apiPath);
        const {data: terms} = await http.get(config.API_URL + "/terms/");
        const {data: contactRoles} = await http.get(config.API_URL + "/option_sets/?code=CONTACT_ROLES");
        const {data: debtColIntegrationEnabled} = await getSetting("DEBTCOL_INTEGRATION_ENABLED");

        if (terms.length > 0) {
            let editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(terms[0].content)
                )
            )
            this.setState({
                editorState
            })

        }

        const paramsStr = this.props.location.search
        const urlParams = new URLSearchParams(paramsStr);
        let key = urlParams.get("edit") || "profile";
        let options = [{
            value: collectionPartners[0].abn, text: collectionPartners[0].business_name
        }]

        this.setState({
            singleSelections: options,
            options,
            key,
            data: collectionPartners[0],
            contactRoles: contactRoles[0].options,
            debtColIntegrationEnabled: debtColIntegrationEnabled[0].value_boolean
        });
    }

    handleChange = ({currentTarget: input}) => {
        const {data, errors} = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({data, errors});
    };

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        if (this.state.key === 'terms') {
            return await this.doSubmitTermsCondition();
        }

        const errors = validate(this.schema, this.state.data);
        this.setState({errors: errors || {}});
        if (errors) return false;

        // additional function based validation (async)
        const asyncErrors = await this.validateOnSubmit();
        this.setState({errors: asyncErrors || {}});
        if (asyncErrors) return false;

        return await this.doSubmit();

    };

    doSubmit = async () => {
        try {
            await http.patch(config.API_URL + apiPath + this.state.data.id + "/", this.state.data);
            toast.success("Saved successfully.", {autoClose: 3000});
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Error.");
            }
        }
    };

    validateAbn = async () => {
        // ABN validation
        const abn = this.state.data.abn;
        const abnValid = await lookupAbn(abn);

        if (abnValid) {
            this.setState({abnValidatedClass: "valid"})
            return null;
        }

        this.setState({abnValidatedClass: "invalid"})
        return {'abn': "Please enter a valid ABN"};
    };

    validateOnSubmit = async () => {
        return await this.validateAbn();
    };

    doSubmitTermsCondition = async () => {
        try {
            let content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

            await http.post(config.API_URL + "/terms/", {content});
            toast.success("Saved successfully.", {autoClose: 3000});
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Error.");
            }
        }
    };

    setKey(key) {
        this.setState({key})
    }

    setSingleSelections(singleSelections) {
        let data = this.state.data
        if (singleSelections[0]) {
            data.abn = singleSelections[0].value
            data.business_name = singleSelections[0].text
        }
        this.setState({data, singleSelections})
    }


    setSingleInput(singleInput) {
        let data = this.state.data
        if (singleInput) {
            data.business_name = singleInput
        }
        let singleSelections = [{value: data.abn, text: singleInput}]
        this.setState({data, singleSelections})
    }

    render() {
        const {data, debtColIntegrationEnabled, errors} = this.state;

        return (
            <div className="container">
                <h1>Manage Profile</h1>
                <Form onSubmit={this.handleSubmit}
                      validate={this.handleValidate}
                >
                    <Tabs defaultActiveKey={"profile"}
                          activeKey={this.state.key}
                          id="profile-tabs"
                          onSelect={this.setKey.bind(this)}>
                        <Tab eventKey="profile" title="Address">
                            <br/>

                            <div>
                                <div className={"form-group mb-2"}>
                                    <label htmlFor={name} className={""}>Business or entity name</label>
                                    <AsyncTypeahead
                                        id="business_name"
                                        delay={1500}
                                        filterBy={() => true}
                                        labelKey="text"
                                        minLength={3}
                                        isLoading={this.state.isLoading}
                                        onSearch={async (query) => {
                                            this.setState({isLoading: true})
                                            let items = await lookupBusinessName(query);
                                            const options = items.map((i) => ({
                                                text: i.Name,
                                                value: i.Abn,
                                            }));
                                            this.setState({options, isLoading: false})
                                            this.setSingleInput(query)
                                        }}
                                        options={this.state.options}
                                        placeholder="Business or entity name"
                                        onChange={this.setSingleSelections.bind(this)}
                                        selected={this.state.singleSelections}
                                    />
                                </div>
                                {renderInput(data, errors, this.handleChange, "abn", "ABN", "", "text", this.state.abnValidatedClass, this.validateAbn, null, '11 111 111 111')}
                                {renderInput(data, errors, this.handleChange, "address_1", "Address Line 1")}
                                {renderInput(data, errors, this.handleChange, "address_2", "Address Line 2")}

                                <div className="form-row">
                                    <div className="col-6">
                                        {renderInput(data, errors, this.handleChange, "suburb", "Suburb")}
                                    </div>
                                    <div className="col-3">
                                        {renderInput(data, errors, this.handleChange, "zip_code", "Post Code")}
                                    </div>
                                    <div className="col-3">
                                        {renderInput(data, errors, this.handleChange, "state", "State")}
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Contact">
                            <br/>
                            <div>
                                <div className="form-row">
                                    <div className="col-6">
                                        {renderInput(data, errors, this.handleChange, "user.first_name", "First Name")}
                                    </div>
                                    <div className="col-6">
                                        {renderInput(data, errors, this.handleChange, "user.last_name", "Last Name")}
                                    </div>
                                </div>
                                {renderSelect(data, errors, this.handleChange, "contact_role", "Role", this.state.contactRoles)}
                                {renderInput(data, errors, this.handleChange, "phone", "Phone")}
                                {renderInput(data, errors, this.handleChange, "website", "Website")}

                                {renderTextArea(data, errors, this.handleChange, "description",
                                    "Your business profile description (optional)",
                                    "Your business profile description"
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="terms" title="Terms & Conditions">
                            <Editor
                                editorState={this.state.editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassNameEdit"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={toolbarConfig}
                            />
                        </Tab>
                        <Tab eventKey="rates" title="Commission Rates">
                            <CommissionRates data={this.state.data}
                                             setData={(data) => this.setState({data})}
                                             errors={this.state.errors}
                                             handleValidate={this.handleValidate}
                            />
                        </Tab>
                        {debtColIntegrationEnabled && <Tab eventKey="debtcol" title="DebtCol Integration">
                            <br/>
                            <div>
                                {renderInput(data, errors, this.handleChange, "debtcol_api_url", "API URL", "https://company.webstream.net.au/integration/111/")}
                                {renderInput(data, errors, this.handleChange, "debtcol_api_username", "API Username")}
                                {renderInput(data, errors, this.handleChange, "debtcol_api_password", "API Password")}
                                {renderInput(data, errors, this.handleChange, "debtcol_api_key", "API Key")}
                            </div>
                        </Tab>}
                    </Tabs>
                </Form>
            </div>
        );
    }
}

export default CollectionPartnerProfile;


function CommissionRates({data, setData, errors, handleValidate}) {

    const [crErrors, setCrErrors] = useState()

    function addRow() {
        let commissionRates = data.commission_rates
        commissionRates.push({from_amount: 0, to_amount: 0, commission_rate: 0})
        setData({...data, commission_rates: commissionRates})
    }

    function deleteRow(index) {
        let commissionRates = data.commission_rates
        commissionRates.splice(index, 1)
        setData({...data, commission_rates: commissionRates})
    }

    function handleChange(index, field, value) {
        let commissionRates = data.commission_rates
        commissionRates[index][field] = value.replace(/[^\d.-]/g, '')
        setData({...data, commission_rates: commissionRates})
        setCrErrors(handleValidate())
    }

    return (

        <>
            <h5>Schedule of Commission Rates</h5>

            <p>Enter in your firm's Schedule of Commission Rates. This schedule will automatically be inserted into your Appointment to Act form which will be signed by the client upon your appointment.</p>

            <ul>
                {crErrors && Object.entries(crErrors).map(({key, value}, index) => (
                    <li key={index}>{value}, {key}</li>
                ))}
            </ul>

            <table className="table table-striped commissionRatesTable">
                <thead>
                <tr>
                    <th>From Amount</th>
                    <th>To Amount</th>
                    <th>Commission in %</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {data.commission_rates.map((rate, index) => (
                    <tr key={index}>
                        <td>
                            <CurrencyInput maskOptions={{allowDecimal: false}}
                                           className={"form-control mb-2 from_amount"}
                                           placeholder="$ 0"
                                           id={index + "_from_amount"}
                                           type="text"
                                           name="from_amount"
                                           value={rate.from_amount}
                                           onChange={(event) => handleChange(index, "from_amount", event.target.value)}
                            />

                            {displayCommissionRateError(index, "from_amount", crErrors)}
                        </td>
                        <td>
                            <CurrencyInput maskOptions={{allowDecimal: false}}
                                           className={"form-control mb-2 to_amount"}
                                           placeholder="$ 0"
                                           id={index + "_to_amount"}
                                           type="text"
                                           name="to_amount"
                                           value={rate.to_amount}
                                           onChange={(event) => handleChange(index, "to_amount", event.target.value)}
                            />

                            {displayCommissionRateError(index, "to_amount", crErrors)}
                        </td>
                        <td>
                            <CurrencyInput maskOptions={{suffix: ' %', prefix: ''}}
                                           className={"form-control mb-2"}
                                           placeholder="0.0 %"
                                           type="text"
                                           name="commission_rate"
                                           value={rate.commission_rate}
                                           onChange={(event) => handleChange(index, "commission_rate", event.target.value)}/>

                            {displayCommissionRateError(index, "commission_rate", crErrors)}
                        </td>
                        <td>
                            <button type="button"
                                    title="Delete this row"
                                    className="btn text-danger" onClick={() => deleteRow(index)}><i
                                className="fa fa-trash"></i></button>
                        </td>
                    </tr>
                ))}

                </tbody>

            </table>

            <button type="button" className="addRowButton btn btn-secondary"
                    onClick={addRow}><i className="fa fa-plus"></i> Add row
            </button>

        </>
    )

}

function displayCommissionRateError(index, field, errors) {
    try {
        const path = `commission_rates.${index}.${field}`
        return <p className="text-danger commissionRateError">{errors[path]}</p>
    } catch (e) {
        return null
    }
}