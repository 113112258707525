import React, {useEffect, useState, useMemo} from 'react'
import moment from 'moment'
import Table from '../common/table'
import {formatCurrency} from '../common/numberFormats'
import {Checkbox, ThreeStateCheckbox} from "../common/forms/Checkbox";
import _ from "lodash";
import {Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";


function ImportInvoices({service, availableServices, token, handleLoadListingFromInvoices, handleCreateListingManually}) {
    const [invoices, setInvoices] = useState(null)
    const [selectedContacts, setSelectedContacts] = useState(new Set())
    const [loading, setLoading] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const invoiceTotals = useMemo(() => calculateInvoiceTotals(invoices), [invoices]);

    const [sortColumn, setSortColumn] = useState({
        path: 'invoice.invoice_number',
        order: 'asc',
    })

    const columns = [
        {
            path: '',
            label: <ThreeStateCheckbox name="select_all_invoice"
                             id="select_all_invoice"
                             checked={allSelected}
                             indeterminate={invoices && selectedContacts.size < invoices.length && selectedContacts.size > 0}
                             onChange={handleSelectAll} />,
            key: 'select',
            sort: false,
            // name, id, checked = false, onChange, value, label
            content: (invoice) => (invoice.contact_id ? <Checkbox key={invoice.contact_id}
                                            name="select_invoice"
                                            id={invoice.contact_id + "select_invoice"}
                                            checked={selectedContacts.has(invoice.contact_id)}
                                            onChange={() => {handleSelect(invoice.contact_id)}}
                                    /> : <></>),
        },
        {
            path: 'id',
            label: 'ID',
            key: 'id',
            hide: true
        },
        {
            path: 'contact_name',
            label: <>{(selectedContacts.size > 0) ? `${selectedContacts.size} selected` : `Debtor` }</>,
            key: 'contact_name',
            sort: true
        },
        {
            path: 'total_value',
            label: 'Total Value',
            key: 'total_value',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.total_value)}</>,
        },
        {
            path: 'current_invoices',
            label: 'Current',
            key: 'current_invoices',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.current_invoices)}</>
        },
        {
            path: 'due_0_30_days',
            label: '0-30 days',
            key: 'due_0_30_days',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.due_0_30_days)}</>
        },
        {
            path: 'due_31_60_days',
            label: '31-60 days',
            key: 'due_31_60_days',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.due_31_60_days)}</>
        },
        {
            path: 'due_61_90_days',
            label: '61-90 days',
            key: 'due_61_90_days',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.due_61_90_days)}</>
        },
        {
            path: 'due_90_days',
            label: '90+ days',
            key: 'due_90_days',
            sort: true,
            content: (invoice) => <>{formatCurrency(invoice.due_90_days)}</>
        }
    ]

    useEffect(() => {
        setInvoices(null)
        if (!service) return
        service.api.getToken().then((res) => {
            if (res.data === 'access_token') {
                service.api.getInvoices().then((res) => {
                    const newInvoices = res.data
                    // import invoices from the service if no invoices found
                    if (newInvoices.length <= 0) {
                        setLoading(true)
                        service.api.importInvoices().finally((res) => {
                            setLoading(false)
                            service.api.getInvoices().then((res) => setInvoices(res.data))
                        })
                    } else {
                        setInvoices(newInvoices)
                    }

                })
                return
            }
        })

    }, [service])

    function calculateInvoiceTotals (invoices) {
        if (!invoices || invoices.length <= 0) return
        const totals = [{
            "contact_name": "TOTAL",
            "total_value": invoices.reduce((previousValue, invoice) => previousValue + invoice.total_value, 0),
            "current_invoices": invoices.reduce((previousValue, invoice) => previousValue + invoice.current_invoices, 0),
            "due_0_30_days": invoices.reduce((previousValue, invoice) => previousValue + invoice.due_0_30_days, 0),
            "due_31_60_days": invoices.reduce((previousValue, invoice) => previousValue + invoice.due_31_60_days, 0),
            "due_61_90_days": invoices.reduce((previousValue, invoice) => previousValue + invoice.due_61_90_days, 0),
            "due_90_days": invoices.reduce((previousValue, invoice) => previousValue + invoice.due_90_days, 0)
        }]
        return totals
    }

    function handleLogin(service) {
        service.api.getToken().then((res) => {
            if (res.data !== 'access_token') {
                window.location.assign(res.data)
            }
        })
    }

    const handleImport = () => {
        try {
            setLoading(true)
            service.api.importInvoices().finally((res) => {
                setLoading(false)
                service.api.getInvoices().then((res) => setInvoices(res.data))
            })
        } catch (e) {
            toast.error(`Your invoices could not be imported. If the problem persists, please disconnect and reconnect your ${service.serviceLabel} account.`)
        }

    }

    function handleSelectAll() {
        if (allSelected) {
            setSelectedContacts(new Set())
        } else {
            const newSelectedContacts = new Set(invoices.map((invoice) => (invoice.contact_id)));
            setSelectedContacts(newSelectedContacts)
        }
        setAllSelected(!allSelected)
    }

    function isSelected(contact) {
        return selectedContacts.has(contact.contact_id)
    }

    function handleSelect(contactId) {
        const newSelectedContacts = new Set(selectedContacts)
        if (newSelectedContacts.has(contactId)) {
            newSelectedContacts.delete(contactId)
        } else {
            newSelectedContacts.add(contactId)
        }
        setSelectedContacts(newSelectedContacts)
    }

    async function handleCreateListingFromInvoices() {
        const resp = await service.api.createListingFromInvoices(Array.from(selectedContacts))
        handleLoadListingFromInvoices(resp.data)
    }

    const handleSort = (sortColumn) => {
        setSortColumn(sortColumn)
    }

    function getProcessedData () {
        // sorting only for now - might add search/filters/pagination later
        const sorted = _.orderBy(invoices, [sortColumn.path], [sortColumn.order]);
        return sorted
    }

    const processedInvoices = getProcessedData()

    return (
        <div className="importInvoices">
            {!token && (
                <div className="integrationServices">
                {availableServices.map((item, index) => {
                    return (
                    <div key={index} className="integrationServiceTile" onClick={() => handleLogin(item)}>
                        <div className="serviceTileBanner" style={{backgroundColor: `${item.backgroundColour}`}}>
                            <img src={item.image} title={item.serviceLabel} />
                        </div>
                        <div className="serviceTileName">
                            <span>{item.serviceLabel}</span>
                            <button className="btn btn-secondary btn-sm">Connect</button>
                        </div>
                    </div>)
                    })}
                </div>
            )}

            {!loading && token && service && (
                <button className="btn btn-secondary float-right mb-2" onClick={handleImport}>
                    Check for new invoices in {service.serviceLabel} <i className="fa fa-rotate-left"/>
                </button>
            )}

            {loading &&
                <div className="spinnerContainer">
                    <Spinner className="spinner" animation="border" role="status"/>
                </div>}

            {processedInvoices && processedInvoices.length > 0 && !loading && (
                <Table
                    columns={columns}
                    data={processedInvoices}
                    isSelected={isSelected}
                    sortColumn={sortColumn}
                    onSort={handleSort}
                    footerData={invoiceTotals}
                />
            )}

            {token &&
                <>
                    <hr className="importInvoicesDivider"/>

                    <div className="importInvoicesActions">
                        <div className="disconnectAction">
                            {service?.disconnectRoute && <Link to={service.disconnectRoute} >Disconnect {service.serviceLabel}</Link>}
                        </div>
                        <div className="importActions">
                            {selectedContacts.size > 0 && <button className="btn btn-primary" onClick={handleCreateListingFromInvoices}>
                                Create listing from {selectedContacts.size} selected debtors
                            </button>}

                            {selectedContacts.size <= 0 && <>
                                <p>Can’t find the debtor you’re looking for?</p>
                                <button className="btn btn-primary" onClick={handleCreateListingManually}>Create Listing Manually</button>
                            </>}
                        </div>
                    </div>
            </>}

        </div>
    )
}

export default ImportInvoices
