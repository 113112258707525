import {React} from "react";
import {DPTooltip} from "../common/tooltip";
import SupportingDocumentsForm from "./SupportingDocumentsForm";


export default function SupportingDocumentsStep({
                                                    listing, supportingDocuments, documentTypes,
                                                    handleDocumentChange, handleAddDocument, handleDeleteDocument,
                                                    documentTypeOtherId
                                                }) {

    return (
        <div className="form-group listingFormContainer">
            <h4>Supporting Documents <DPTooltip content="Note: Collection Partners will only accept files in the following formats: .pdf, .jpg and .png.
If you have additional documents which you can provide in original file formats, please provide these to your Collection Partner directly."></DPTooltip>
            </h4>
            <SupportingDocumentsForm
                listingId={listing.id}
                listing={listing}
                documents={supportingDocuments}
                documentTypes={documentTypes}
                onChange={handleDocumentChange}
                onAdd={handleAddDocument}
                onDelete={handleDeleteDocument}
                documentTypeOtherId={documentTypeOtherId}
                status={listing.placer_filter_status}
            />
        </div>
    )
}