import http from './httpService'
import config from '../constants'

const headers = { 'Content-Type': 'multipart/form-data' }


// use slice here for get API_URL without /api
export function importInvoices() {
  return http.get(config.API_URL + '/integrations/xero/invoices/import/', {
    timeout: 0,
  })
}

export function getToken() {
  return http.get(config.API_URL + '/integrations/xero/oauth2.0/status/')
}

export function getInvoices() {
  return http.get(config.API_URL + '/integrations/xero/invoice_summary/')
}

export function postCode(code) {
  return http.post(
    config.API_URL + '/integrations/xero/oauth2.0/exchange/code/',
    { ...code },
  )
}

export function createListingFromInvoices(contacts) {
  return http.post(
      config.API_URL + '/integrations/xero/create_listing_from_invoices/',
      { "contacts": contacts },
  )
}

