import React from "react";
import {NavLink} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import {collectionPartnerNavItems, placerNavItems} from "./navItems";
import {useAccess} from "../../contexts/AccessContext";

const NavBar = ({user}) => {

    const {isPlacer, isCollectionPartner} = useAccess()

    if (!user) return <></>
    return (

        <Navbar className="navigationSidebar d-none d-lg-block">

            <Navbar.Collapse id="mainNav">
                <Nav className="flex-column">

                    {user && isCollectionPartner() && (
                        collectionPartnerNavItems.map((item, index) => (
                            <NavLink key={index} className="nav-item nav-link" to={item.to}>
                                {item.icon && <img src={item.icon} width="32" height="32" />}
                                {item.icon && <img className="activeIcon" src={item.activeIcon} width="32" height="32" />}
                                {item.label}
                            </NavLink>
                        ))
                    )}

                    {user && isPlacer() && (
                        placerNavItems.map((item, index) => (
                            <NavLink key={index} className="nav-item nav-link" to={item.to} exact={item.exact} >
                                {item.icon && <img src={item.icon} width="32" height="32" />}
                                {item.icon && <img className="activeIcon" src={item.activeIcon} width="32" height="32" />}
                                {item.label}
                            </NavLink>
                        ))
                    )}
                </Nav>

            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
