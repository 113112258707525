import _ from "lodash";
import CurrencyInput from "../common/forms/currencyInput";
import React, {useState, useEffect} from "react";
import {Radio} from "../common/forms/radioGroup";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {lookupBusinessName} from "../../utils/abnLookup";
import {renderInput} from "../common/forms/Input";
import {renderSelect} from "../common/forms/Select";
import {formatCurrency} from "../common/numberFormats";
import {loadAddressFinderScript} from "../../utils/addressFinder";
import config from "../../constants";
import DatePicker from "react-datepicker";


import warningIcon from '../../img/icons/warning.svg'
import binIcon from "../../img/icons/bin.svg"
import pencilIcon from "../../img/icons/pencil.svg"
import moment from "moment";
import {isMissingInformation} from "../../utils/listingRenderers";

export default function DebtDetail({   debtValidationErrors,
                                       debt,
                                       index,
                                       industries,
                                       handleDebtChange,
                                       handleDebtDateChange,
                                       handleSaveDebt,
                                       handleDeleteDebt,
                                       handleReloadDebt,
                                       handleValidateDebt,
                                       setDebt,
                                       validateAbn,
                                       editingActive,
                                       setEditingActive,
                                       abnsValidated,
                                       editingDisabled,
                                        listing
                                   }) {

    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState(null)
    const [singleSelections, setSingleSelections] = useState([])
    const [addressFinderWidget, setAddressFinderWidget] = useState(null)

    useEffect(() => {
        if (debt.debtor && editingActive) {
            loadAddressFinderScript(loadWidget); // address finder
            if (debt.debtor.abn || debt.debtor.business_name) {
                setSingleSelections([{
                    value: debt.debtor.abn,
                    textValue: debt.debtor.business_name,
                    text: debt.debtor.business_name
                }])
            }
        }

    }, [editingActive]);

    function loadWidget() {
        const newAddressFinderWidget = new AddressFinder.Widget(
            document.querySelector("#debtor-details-" + index + " #debtor\\.address_1"),
            config.ADDRESS_FINDER_API_KEY,
            'AU'
        );
        newAddressFinderWidget.on('result:select', (fullAddress, metaData) => {
            handleDebtorAddressChange(fullAddress, metaData);
        });
        setAddressFinderWidget(newAddressFinderWidget)
    }

    function handleDebtorAddressChange(fullAddress, metaData) {
        let newDebt = debt
        _.set(newDebt, "debtor.address_1", metaData.address_line_1);
        _.set(newDebt, "debtor.address_2", metaData.address_line_2);
        _.set(newDebt, "debtor.suburb", metaData.locality_name);
        _.set(newDebt, "debtor.state", metaData.state_territory);
        _.set(newDebt, "debtor.zip_code", metaData.postcode);
        setDebt(newDebt, index)
    }

    function updateSingleSelections(newSingleSelections) {
        let newDebt = {...debt}
        if (newSingleSelections[0]) {
            newDebt.debtor.abn = newSingleSelections[0].value
            newDebt.debtor.business_name = newSingleSelections[0].textValue
        }
        // replace text with textValue in single selections
        newSingleSelections.forEach(option => {
            option.text = option.textValue
        });
        setDebt(newDebt, index)
        setSingleSelections(newSingleSelections)

        validateAbn(index)
    }

    function setSingleInput(singleInput) {
        let newDebt = {...debt}
        if (singleInput) {
            newDebt.debtor.business_name = singleInput
        }
        let newSingleSelections = [{value: newDebt.debtor.abn, text: singleInput}]
        setDebt(newDebt)
        setSingleSelections(newSingleSelections)
    }

    function handleSave(event) {

        event.preventDefault()

        // validate first before saving
        const validationErrors = handleValidateDebt(index)
        if (validationErrors && Object.keys(validationErrors).length > 0) return;

        handleSaveDebt(event, index);
        setEditingActive(index, false)
    }

    if (!debt || !debt.debtor) return <></>

    const hasMissingInformation = isMissingInformation(debt)

    return <>

        <div className="debtDetail">

            <div className="debtDetailHeader">

                <div className="debtorDetailDebtorName">
                    {(Object.keys(debtValidationErrors).length > 0 || hasMissingInformation) && listing.listing_type !== 'sale' && <img src={warningIcon} width="" height="" title="Some debtor information missing" />}
                    <h4>{debt.debtor.name || "New Debtor"}</h4>
                </div>

                <div className="debtorDetailValue">

                    <span className="debtValue">{formatCurrency(debt.amount)}</span>

                    {!editingActive && !editingDisabled && <button className="btn" onClick={() => setEditingActive(index, true)}>
                        <img src={pencilIcon} alt="Edit" title="Edit Debtor" width="12" height="12"/></button>}

                    {!editingDisabled && <button className="btn" onClick={(event) => handleDeleteDebt(event, index)}>
                        <img src={binIcon} alt="Delete" title="Delete Debtor" width="12" height="12"/>
                    </button>}
                </div>
            </div>

            {/* Summary display */}
            {!editingActive && <div className={editingActive ? "d-none" : "listingDetailBodyDisplay"}>

                <div className="row">
                    <div className="col-2 label">Type</div>
                    <div className="col-4">{debt.debtor.debtor_type === 'business' ? "Business" : "Individual"}</div>

                    {debt.debtor.debtor_type === 'business' &&
                        <>
                            <div className="col-2 label">ABN</div>
                            <div className="col-4">{debt.debtor.abn}</div>
                        </>}

                    {debt.debtor.debtor_type === 'individual' &&
                        <>
                            <div className="col-2 label">DOB</div>
                            <div className="col-4">
                                {debt.debtor.date_of_birth ? moment(debt.debtor.date_of_birth).format('DD/MM/YYYY') : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}
                            </div>
                        </>}
                </div>

                {debt.debtor.debtor_type === 'individual' &&
                    <div className="row">
                        <div className="col-2 label">First Name</div>
                        <div className="col-4">{debt.debtor.first_name}</div>
                        <div className="col-2 label">Last Name</div>
                        <div className="col-4">{debt.debtor.last_name}</div>
                    </div>}

                {debt.debtor.debtor_type === 'business' &&
                    <div className="row">
                        <div className="col-2 label">Contact First Name</div>
                        <div className="col-4">{debt.debtor.contact_first_name ? debt.debtor.contact_first_name : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}</div>
                        <div className="col-2 label">Contact Last Name</div>
                        <div className="col-4">{debt.debtor.contact_last_name ? debt.debtor.contact_last_name : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}</div>
                    </div>}

                <div className="row">
                    <div className="col-2 label">Phone</div>
                    <div className="col-4">{debt.debtor.contact_phone ? debt.debtor.contact_phone : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}</div>
                    <div className="col-2 label">Email</div>
                    <div className="col-4">{debt.debtor.contact_email ? debt.debtor.contact_email : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}</div>
                </div>

                <div className="row">
                    <div className="col-2 label">Address</div>
                    <div className="col-10">
                        {(!debt.debtor.address_1 || !debt.debtor.suburb || !debt.debtor.zip_code) && (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}
                        {debt.debtor.address_1 ? debt.debtor.address_1 + ", " : ""}
                        {debt.debtor.address_2 ? debt.debtor.address_2 + ", " : ""}
                        {debt.debtor.suburb ? debt.debtor.suburb + ", " : ""}
                        {debt.debtor.state ? debt.debtor.state + ", " : ""}
                        {debt.debtor.zip_code}
                    </div>
                </div>

                <div className="row">
                    <div className="col-2 label">Due date</div>
                    <div className="col-4">{moment(debt.due_date).format("DD/MM/YYYY")}</div>
                    {debt.debtor.debtor_type === 'business' && <>
                        <div className="col-2 label">Industry</div>
                        <div className="col-4">
                            {debt.debtor.industry_description ? debt.debtor.industry_description : (editingDisabled ? <></> : <a onClick={() => setEditingActive(index, true)} className="editDebtDetailsLink">Add</a>)}
                        </div>
                    </>}
                </div>
            </div>}

            {/* Editing view */}
            {editingActive && <div className={!editingActive ? "d-none" : ""}>

                <div className="debtorType">
                    <div className={"form-group mb-4"}>
                        <label>Debtor Type</label>

                        <Radio name="debtor.debtor_type"
                               value="business"
                               checked={debt.debtor.debtor_type === "business"}
                               onChange={handleDebtChange}
                               label={"Business"}
                        />
                        <Radio name="debtor.debtor_type"
                               value="individual"
                               checked={debt.debtor.debtor_type === "individual"}
                               onChange={handleDebtChange}
                               label={"Individual"}
                        />

                    </div>
                </div>
                <div id={"debtor-details-" + index}>

                    {debt.debtor.debtor_type === "business" &&
                        <div className={"form-group mb-4"}>
                            <label htmlFor={name} className={""}>Business or entity name</label>
                            <AsyncTypeahead
                                id="businessNameLookup"
                                delay={1500}
                                labelKey="text"
                                minLength={3}
                                isLoading={isLoading}
                                onSearch={async (query) => {
                                    setIsLoading(true)
                                    let items = await lookupBusinessName(query);
                                    const options = items.map((i) => ({
                                        text: i.Name + " - " + i.State + " " + i.Postcode,
                                        textValue: i.Name,
                                        value: i.Abn,
                                    }));
                                    setOptions(options)
                                    setIsLoading(false)
                                    setSingleInput(query, index)
                                }}
                                options={options || singleSelections}
                                placeholder="Business or entity name"
                                onChange={updateSingleSelections}
                                selected={singleSelections}
                            />
                        </div>}

                    {debt.debtor.debtor_type === "individual" &&
                        <div className="form-row mb-2">
                            <div className="col-6">
                                {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.first_name", "First Name")}
                            </div>
                            <div className="col-6">
                                {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.last_name", "Last Name")}
                            </div>
                        </div>}

                    {debt.debtor.debtor_type === "business" &&
                        <div className="form-row mb-2">
                            <div className="col-6">
                                {debt.debtor.debtor_type === "business" && renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.abn", "ABN", "", "text", abnsValidated[index], () => validateAbn(index), null, '11 111 111 111')}
                            </div>
                            <div className="col-6">
                                {debt.debtor.debtor_type === "business" && renderSelect(debt, debtValidationErrors, handleDebtChange, "debtor.industry", "Industry", industries, "", "id", "anzsic_division_description")}
                            </div>
                        </div>}

                    <div className="form-row mb-2">
                        <div className="col-12">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.address_1", "Address Line 1")}
                        </div>
                    </div>
                    <div className="form-row mb-2">
                        <div className="col-12">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.address_2", "Address Line 2")}
                        </div>
                    </div>

                    <div className="form-row mb-2">
                        <div className="col-6">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.suburb", "Suburb")}
                        </div>
                        <div className="col-3">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.zip_code", "Post Code")}
                        </div>
                        <div className="col-3">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.state", "State")}
                        </div>
                    </div>

                    {debt.debtor.debtor_type === "business" && <>
                    <div className="form-row mb-2">
                        <div className="col-6">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.contact_first_name", "Contact first name")}
                        </div>
                        <div className="col-6">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.contact_last_name", "Contact last name")}
                        </div>
                    </div>
                    </>}

                    <div className="form-row mb-2">
                        <div className="col-6">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.contact_email", "Contact email")}
                        </div>
                        <div className="col-6">
                            {renderInput(debt, debtValidationErrors, handleDebtChange, "debtor.contact_phone", "Contact phone")}
                        </div>
                    </div>

                    {debt.debtor.debtor_type === "individual" && <>
                    <div className="form-row mb-2">
                        <div className="col-6">
                            <label htmlFor="date_of_birth">Date of Birth</label>
                            <DatePicker
                                className={'form-control mb-2'}
                                id={index + "_date_of_birth"}
                                name="date_of_birth"
                                selected={_.get(debt, "debtor.date_of_birth") && new Date(_.get(debt, "debtor.date_of_birth")) || null}
                                onChange={(date) => handleDebtDateChange(date, index, "debtor.date_of_birth")}
                                label="Date of Birth"
                                dateFormat="dd/MM/yyyy"
                                maxDetail="day"
                                placeholderText="DD/MM/YYYY"
                                maxDate={new Date()}/>
                        </div>
                    </div>
                    </>}

                    <div className="form-row mb-4">
                        <div className="col-6">
                            <label htmlFor="amount">Amount</label>
                            <CurrencyInput className={"form-control mb-2 invoice"}
                                           placeholder="$ 0.00"
                                           id={debt.id + "_amount"}
                                           type="text"
                                           name="amount"
                                           value={_.get(debt, "amount") || ""}
                                           onChange={handleDebtChange}
                            />
                            {debtValidationErrors["amount"] && <div className="alert alert-danger">{debtValidationErrors["amount"]}</div>}
                        </div>
                        <div className="col-6">
                            <label htmlFor="due_date">Due Date</label>
                            <DatePicker
                                className={'form-control mb-2'}
                                id={index + "_due_date"}
                                name="due_date"
                                selected={_.get(debt, "due_date") && new Date(_.get(debt, "due_date")) || null}
                                onChange={(date) => handleDebtDateChange(date, index, "due_date")}
                                label="Due Date"
                                dateFormat="dd/MM/yyyy"
                                maxDetail="day"
                                placeholderText="DD/MM/YYYY"
                                maxDate={new Date()}/>
                              {debtValidationErrors["due_date"] && <div className="alert alert-danger">{debtValidationErrors["due_date"]}</div>}
                        </div>
                    </div>
                </div>

                <div className="debtDetailButtons">
                    <button className="btn btn-tertiary" onClick={(event) => {
                        event.preventDefault()
                        handleReloadDebt(event, index);
                        setEditingActive(index, false)
                    }}>Cancel
                    </button>
                    <button className="btn btn-primary" onClick={handleSave}>Save
                    </button>
                </div>
            </div>}
        </div>
    </>
}