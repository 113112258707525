import React, {Component} from "react";
import doRequestEmailConfirmation from "../../utils/requestEmailConfirmation"

class ConfirmEmail extends Component {

    state = {};

    async componentDidMount() {

        if (this.props.location) {
            const { requestEmailConfirmation, userId } = this.props.location.state

            if (requestEmailConfirmation) {
                await doRequestEmailConfirmation(userId)
            }
        }

        gtag('event', 'conversion', {'send_to': 'AW-300499110/5WaLCI2L9qgDEKaBpY8B'});
    };

    render() {

        return (
            <div className="container my-5">
                    <h1>Confirm your email</h1>
                    <p>Please confirm your email by clicking on the link that was just sent to you.</p>
            </div>
        );
    }
}
export default ConfirmEmail;


ConfirmEmail.defaultProps = {
    requestEmailConfirmation: false,
    userId: null
};
