import React from "react";
import ReactSelect from 'react-select';
import _ from "lodash";

const Select = ({label, options, valueProperty, textProperty, error, labelClass, emptyLabel, value, name, onChange, ...rest}) => {
    const selectedOption = options.find(option => option[valueProperty] === value);
    return (
        <div className="form-group">
            {label?<label htmlFor={name} className={labelClass}>{label}</label>: null}
            <ReactSelect {...rest}
                         options={options}
                         name={name}
                         value={selectedOption}
                         onChange={(selectedOption) => {
                             if (!selectedOption) return onChange({currentTarget:{name, data:null}});
                             const value = selectedOption[valueProperty];
                             return onChange({currentTarget:{name, value}});
                         }}
                         getOptionValue={option => option[valueProperty]}
                         getOptionLabel={option => option[textProperty]}
                         placeholder={emptyLabel}
                         isClearable
            />

            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};


Select.defaultProps = {
    textProperty: "title",
    valueProperty: "pk"
};


function renderSelect(data, errors, onChange, name, label, options, emptyLabel, valueProperty, textProperty) {

    return (
        <Select
            name={name}
            value={_.get(data, name) || ""}
            label={label}
            options={options}
            valueProperty={valueProperty}
            textProperty={textProperty}
            onChange={onChange}
            error={errors[name]}
            emptyLabel={emptyLabel}
        />
    );
}

export {
    Select,
    renderSelect
}
