import React, {Component} from "react";

class SupportingDocumentsSummary extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { supportingDocumentsSummary } = this.props;

        return (
            <div className="detailTile">

                <ul className="iconList">
                    {supportingDocumentsSummary?.map((document_type, index) => (
                        <li key={index} className={document_type.available ? "available" : "unavailable"} >
                            <i className={document_type.available ? "fa fa-check-circle" : "fa fa-times-circle"}></i>
                            {document_type.title}

                            {document_type.title === "Other" && <React.Fragment>
                                :<br/>
                                <div className="listItemDetails">{document_type.comments}</div>
                            </React.Fragment>}

                        </li>
                    ))}

                </ul>

            </div>
        );
    }
}

export default SupportingDocumentsSummary;
