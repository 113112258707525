import React, {useState} from "react";
import {getCurrentUser} from "../../services/authService";
import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {passwordErrorMessage, passwordRegEx} from "../../utils/regularExpressions";
import {baseHandleChange} from "../common/forms/helpers";
import {validate} from "../common/forms/validation";
import Form from "../common/forms/Form";
import {renderInput} from "../common/forms/Input";
import {logout} from "../../services/authService";

const Joi = require('joi');

class AccountSettings extends React.Component {

    state = {
        data: { email: "", old_password: "", password: "", confirm_password: ""},
        errors: {}
    };

    schema = {
        1: {
            email: Joi.string().email({ tlds: {allow: false} }).required().label("Email"),
            old_password: Joi.any().when('password', {
                is: Joi.string().required().min(1),
                then: Joi.string().required(),
                otherwise: Joi.optional(),
            }).messages({ "any.only" : "Please enter your old password" }),
            password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
                .messages({ "string.pattern.base": passwordErrorMessage }),
            confirm_password: Joi.any()
                .valid(Joi.ref('password')).label("Confirm Password")
                .messages({ "any.only" : "Passwords must match" })
        }
    };

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {
        const { data } = await http.get(config.API_URL + "/users/" + getCurrentUser().user_id);
        this.setState({ data });
    }

    handleChange = ({ currentTarget: input }) => {
        const { data, errors } = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({ data, errors });
    };

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        const errors = validate(this.schema, this.state.data);
        this.setState({ errors: errors || {} });
        if (errors) return false;

        return await this.doSubmit();

    };

    doSubmit = async () => {
        return await this.saveAccountSettings();
    };

    saveAccountSettings = async () => {

        try {
            let data = this.state.data;
            if (!data.password) delete data.password;
            await http.patch(config.API_URL + "/users/" + getCurrentUser().user_id + "/", data);
            toast.success("Saved successfully.", {autoClose: 3000});
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                this.setState({ errors: ex.response.data });
                toast.error("Error.");
            }
        }
    };

    handleDeleteAccount = async () => {
        try {
            await http.post(config.API_URL + "/users/" + getCurrentUser().user_id + "/delete_account/");
            await logout()
            toast.success("Account deleted successfully", {autoClose: 3000});
            window.location = "/login" // redirect & reload
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                this.setState({ errors: ex.response.data });
                toast.error("Error.");
            }
        }
    }

    render() {

        return (
            <div className="container">
                <h1>Account Settings</h1>
                <Form onSubmit={this.handleSubmit}
                      validate={this.handleValidate}
                >
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "email", "Email")}
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "old_password", "Old Password", "", "password")}
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "password", "Password", "", "password")}
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "confirm_password", "Confirm Password", "", "password")}

                </Form>

                <DeleteAccount onDelete={this.handleDeleteAccount} />
            </div>
        );
    }
}

export default AccountSettings;


export function DeleteAccount ({onDelete}) {

    const [open, setOpen] = useState(false)

    function handleDelete() {
        onDelete()
        setOpen(false)
    }

    return (
        <>
            {open && <div className="">
                <span>Are you sure you want to delete your Debtplacer account?</span>
                <div className="flex deleteLinkOptionsContainer">
                    <button type="button" className="btn btn-primary"
                            onClick={handleDelete}>Yes</button>
                    <button type="button"
                            className="btn btn-secondary"
                            onClick={() => setOpen(false)}>Cancel</button>
                </div>
            </div>}

            {!open && <a href="#"
                className="text-danger"
                onClick={() => setOpen(true)}> Delete account</a>}

        </>
    )

}
