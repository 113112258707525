import React from "react";
import {toast} from "react-toastify";
import _ from "lodash";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

import http from "../../services/httpService";
import httpNoAuth from "../../services/httpNoAuthService";
import config from "../../constants";
import {lookupAbn} from "../../utils/abnLookup";
import {getIndustries, getOptionSet} from "../../utils/fetchConfiguration";
import {passwordErrorMessage, passwordRegEx, urlErrorMessage, urlRegEx} from "../../utils/regularExpressions";
import * as Sentry from "@sentry/react";

import {Checkbox} from "../common/forms/Checkbox";
import {baseHandleAddressChange, baseHandleChange} from "../common/forms/helpers";
import {validate, validateStep} from "../common/forms/validation";
import Form from "../common/forms/Form";
import FormStepNavLink from "../common/forms/FormStepNavLink";
import FormStep from "../common/forms/FormStep";
import {renderSelect} from "../common/forms/Select";

import '../../forms.scss';
import UserDetailsStep from "./UserDetailsStep";
import BusinessDetailsStep from "./BusinessDetailsStep";
import auth from "../../services/authService";

const Joi = require('joi');
const apiPath = "/placer_registration/";
import {RouteLeavingGuard} from "../../routing/RouteLeavingGuard";
import {Media} from '../../AppMedia'

const params = new URLSearchParams(window.location.search);


class VendorRegistration extends React.Component {

    state = {
        // Placer
        data: {
            id: null,
            user: {
                first_name: "",
                last_name: "",
                username: "",
                // user_type: "placer",
                is_registration_complete: false,
                password: "",
                confirm_password: "",
                opt_in_to_marketing_emails: true
            },
            business_name: "",
            abn: null,
            address_1: "",
            address_2: "",
            suburb: "",
            zip_code: "",
            state: "",
            contact_role: null,
            phone: "",
            website: null,
            industry: null,
            outstanding_accounts: null,
            outstanding_amount: null,
            has_accepted_terms: false,
            has_accepted_privacy_policy: false,
            registration_source: params.get('source') || null
        },
        contactRoles: [],
        industries: [],
        outstandingAmountOptions: [],
        outstandingAccountsOptions: [],
        abnValidatedClass: "",
        addressFinderWidget: null,
        addressPathBase: "",
        errors: {},
        // form logic & config
        currentStep: 1,
        numberOfSteps: 3,
        formOptions: {
            nextButtonLabel: "Next",
            submitButtonLabel: "Complete signup",
            previousButtonLabel: "Back"
        },
        isBlocking: false,
        token: null // ReCaptcha token
    };

    schema = {
        1: {
            user: {
                first_name: Joi.string(),
                last_name: Joi.string(),
                username: Joi.string()
                    .required()
                    .email({tlds: {allow: false}})
                    .label("Email"),
                password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
                    .messages({"string.pattern.base": passwordErrorMessage}),
                confirm_password: Joi.any()
                    .valid(Joi.ref('password')).label("Confirm Password")
                    .messages({"any.only": "Passwords must match"})
            },
            phone: Joi.string().allow(null).label("Phone"),
            business_name: Joi.string().required()
        },
        2: {
            abn: Joi.string().required().label("ABN"),
            address_1: Joi.string(),
            address_2: Joi.string().optional().allow(null, ''),
            suburb: Joi.string().required().label("Suburb"),
            zip_code: Joi.string().length(4).label("Post Code"),
            state: Joi.string().max(4),
            website: Joi.string().regex(urlRegEx, {}).allow(null, '')
                .messages({
                    "object.regex": urlErrorMessage,
                    "string.pattern.base": urlErrorMessage
                })
                .label("Website"),
            industry: Joi.number().optional().allow(null),
        },
        3: {
            has_accepted_terms: Joi.bool().invalid(false),
            has_accepted_privacy_policy: Joi.bool().invalid(false)
        }
    };

    async componentDidMount() {
        await this.loadData();
    };

    onVerify = (token) => {
        // re-captcha verification
        this.setState({token})
    };

    handleChange = ({currentTarget: input}) => {
        const {
            data,
            errors
        } = baseHandleChange(input, this.state.data, this.state.errors, this.schema, this.state.currentStep);
        this.setState({data, errors, isBlocking: true});
    };

    handleAddressChange = (fullAddress, metaData) => {
        const {data} = baseHandleAddressChange(this.state.data, metaData);
        this.setState({data, isBlocking: true});
    }

    setStep = (index) => {
        this.setState({currentStep: index});
    };

    setStepLink = (index) => {
        if (this.state.currentStep > index)
            this.setState({currentStep: index});
    };

    handleValidateStep = (stepIndex) => {
        const errors = validateStep(stepIndex, this.schema, this.state.data)
        return errors;
    }

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    gtagReportConversion = (url) => {
        gtag('event', 'conversion', {
            'send_to': 'AW-300499110/zZpuCPCw86oDEKaBpY8B'
        });
    }

    handleSubmit = async (e) => {
        if (e) e.preventDefault();

        let errors = {}
        if (this.state.currentStep < this.state.numberOfSteps) {
            errors = validateStep(this.state.currentStep, this.schema, this.state.data);
        } else {
            errors = validate(this.schema, this.state.data);
        }

        this.setState({errors: errors || {}});
        if (errors) return false
        // Google event tracking after completion of first step
        if (this.state.currentStep === 1) this.gtagReportConversion(window.location)

        return await this.doSubmit();
    };

    doSubmit = async () => {
        if (this.state.data.id)
            return await this.updateVendor()

        return await this.createVendor()
    };

    doAfterLastStep = async () => {
        try {
            // finalise registration
            const {data: placer} = await http.post(config.API_URL + apiPath + this.state.data.id + "/complete/");
            this.setState({data: placer});

            // redirect
            window.location = "/register-client-complete";

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                toast.error("Unexpected error");
                this.setState({errors});
                return false;
            }
        }
    }

    async loadData() {

        // config data
        const {data: contactRoles} = await getOptionSet("CONTACT_ROLES");
        const {data: outstandingAmountOptions} = await getOptionSet("OUTSTANDING_AMOUNT");
        const {data: outstandingAccountsOptions} = await getOptionSet("OUTSTANDING_ACCOUNTS");
        const {data: industries} = await getIndustries();

        // placer
        const placerId = this.props.match.params.id;
        let placer = this.state.data;
        let currentStep = this.state.currentStep;
        if (placerId) {
            const resp = await http.get(config.API_URL + apiPath + placerId + "/");
            placer = resp.data;
            currentStep = 2; // go straight to step 2 if placer already created
        }

        let options = []
        if (placer) {
            options = [{
                value: placer.abn, text: placer.business_name
            }]
        }

        this.setState({
            singleSelections: options,
            data: placer,
            contactRoles: contactRoles[0].options,
            outstandingAmountOptions: outstandingAmountOptions[0].options,
            outstandingAccountsOptions: outstandingAccountsOptions[0].options,
            industries: industries,
            currentStep: currentStep
        });
    }

    createVendor = async () => {

        if (!this.state.user && !this.state.token) return false;

        try {

            const {data} = this.state;

            delete data.user.confirm_password // does not need to be sent to API
            const {data: placer} = await httpNoAuth.post(config.API_URL + apiPath, data);

            this.setState({data: placer, isBlocking: false});

            // login the new user
            await auth.login(data.user.username, data.user.password);

            return true;

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};

                if (ex.response.data.user && ex.response.data.user.password) {
                    toast.error(ex.response.data.user.password[0]);
                } else if (ex.response.data.message && ex.response.data.message.includes("duplicate key value violates unique constraint")) {
                    errors["username"] = "This email address is already registered. Please try logging in instead.";
                    toast.error(errors["username"]);
                } else {
                    toast.error("Unexpected error");
                }

                this.setState({errors});
                return false;
            }
        }
    }

    updateVendor = async () => {
        try {
            const {data} = this.state

            delete data.user.password; // password will be empty on subsequent loads
            delete data.user.confirm_password // does not need to be sent to API

            const {data: placer} = await http.patch(config.API_URL + apiPath + data.id + "/", data);
            this.setState({data: placer, isBlocking: false});

            return true;
        } catch (ex) {
            Sentry.captureException(ex)
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Error.");
                return false;
            }
        }
    }

    validateOnSubmit = async () => {
        return await this.validateAbn();
    }

    validateAbn = async () => {

        // ABN validation
        const abn = this.state.data.abn;
        const abnValid = await lookupAbn(abn);

        if (abnValid) {
            this.setState({abnValidatedClass: "valid"})
            return null;
        }

        this.setState({abnValidatedClass: "invalid"})
        return {'abn': "Please enter a valid ABN"};
    };

    setSingleSelections(singleSelections) {

        let data = this.state.data
        if (singleSelections[0]) {
            data.abn = singleSelections[0].value
            data.business_name = singleSelections[0].textValue
        }
        // replace text with textValue in single selections
        singleSelections.forEach(option => {
            option.text = option.textValue
        });
        this.setState({data, singleSelections})
    }

    setSingleInput(singleInput) {
        let data = this.state.data
        if (singleInput) {
            data.business_name = singleInput
        }
        let singleSelections = [{value: data.abn, text: singleInput}]
        this.setState({data, singleSelections})
    }

    render() {

        const user = auth.getCurrentUser();

        let registrationForm = (media) => (
            <div className="container">

                <RouteLeavingGuard
                    when={this.state.isBlocking}
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={location => {
                        return this.state.isBlocking
                    }}
                /><Form numberOfSteps={this.state.numberOfSteps}
                        currentStep={this.state.currentStep}
                        setStep={this.setStep}
                        onSubmit={this.handleSubmit}
                        afterLastStep={this.doAfterLastStep}
                        validateStep={this.handleValidateStep}
                        validate={this.handleValidate}
                        options={this.state.formOptions}
            >
                <h1>Sign up as a Placer</h1>

                <div className="FormStepNavigation">
                    <ol>
                        <FormStepNavLink
                            currentStep={this.state.currentStep}
                            step={1}
                            label="Primary Contact"
                            media={media}
                            setStep={this.setStepLink}
                        />
                        <FormStepNavLink
                            currentStep={this.state.currentStep}
                            step={2}
                            label="Business Details"
                            media={media}
                            setStep={this.setStepLink}
                        />
                        <FormStepNavLink
                            currentStep={this.state.currentStep}
                            step={3}
                            label="Preferences"
                            media={media}
                            setStep={this.setStepLink}
                        />
                        <FormStepNavLink
                            currentStep={this.state.currentStep}
                            step={this.state.currentStep}
                            label="Complete"
                            media={media}
                            setStep={this.setStepLink}
                        />
                    </ol>
                </div>

                <FormStep stepIndex={1} currentStep={this.state.currentStep}>
                    <UserDetailsStep
                        registrationType={"placer"}
                        singleSelections={this.state.singleSelections}
                        setSingleSelections={this.setSingleSelections.bind(this)}
                        setSingleInput={this.setSingleInput.bind(this)}
                        data={this.state.data}
                        errors={this.state.errors}
                        contactRoles={this.state.contactRoles}
                        handleChange={this.handleChange}
                    />
                    {!user && <GoogleReCaptcha onVerify={this.onVerify} />}
                </FormStep>

                <FormStep stepIndex={2} currentStep={this.state.currentStep}>
                    <BusinessDetailsStep
                        registrationType={"placer"}
                        data={this.state.data}
                        errors={this.state.errors}
                        industries={this.state.industries}
                        abnValidatedClass={this.state.abnValidatedClass}
                        validateAbn={this.validateAbn}
                        handleChange={this.handleChange}
                        handleAddressChange={this.handleAddressChange}
                    />
                </FormStep>

                <FormStep stepIndex={3} currentStep={this.state.currentStep}>
                    <h2>Preferences</h2>
                    {renderSelect(this.state.data, this.state.errors, this.handleChange, "outstanding_accounts", "Number of customers with unpaid invoices", this.state.outstandingAccountsOptions)}
                    {renderSelect(this.state.data, this.state.errors, this.handleChange, "outstanding_amount", "Approximate value of unpaid debt", this.state.outstandingAmountOptions)}

                    <div className="row">
                        <div className="col-12">
                            <Checkbox
                                label={<>I have read and agree to the <a href={config.VENDOR_TERMS_AND_CONDITIONS_URL}
                                                                         target="_blank">Terms of Use</a>.</>}
                                name="has_accepted_terms"
                                id="has_accepted_terms"
                                checked={_.get(this.state.data, "has_accepted_terms") === true}
                                onChange={this.handleChange}
                                error={this.state.errors["has_accepted_terms"]}
                            />
                            <br/>
                            <Checkbox
                                label={<>I have read and agree to the <a href={config.PRIVACY_POLICY_URL}
                                                                         target="_blank">Privacy Policy</a>.</>}
                                name="has_accepted_privacy_policy"
                                id="has_accepted_privacy_policy"
                                checked={_.get(this.state.data, "has_accepted_privacy_policy") === true}
                                onChange={this.handleChange}
                                error={this.state.errors["has_accepted_privacy_policy"]}
                            />
                        </div>
                    </div>
                </FormStep>
            </Form>
            </div>
        );

        return <>
            <Media at='mobile'>
                {registrationForm("mobile")}
            </Media>
            <Media at='tablet'>
                {registrationForm('tablet')}
            </Media>
            <Media greaterThan='tablet'>
                {registrationForm("others")}
            </Media></>
    }
}

export default VendorRegistration;
