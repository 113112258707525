import React from "react";

const NotFound = () => {
  return (
      <div className="container container-wide">
        <h1>Not Found</h1>
      </div>
  );
};

export default NotFound;
