import React from "react";
import {Input} from "../common/forms/Input";
import {BetterSelect} from "../common/forms/BetterSelect";
import '../../forms.scss';

class SidebarSearchCollectionPartner extends React.Component {

    render() {

        const {searchParams, errors, handleChange, handleSubmit, handleClearFilters} = this.props;

        return (
            <div className="">
                <div className="searchFormHeader">
                    <span className="pull-right"><a onClick={handleClearFilters} href="src/components/search/SidebarSearchCollectionPartner">Clear All</a></span>
                    <h3>Filter</h3>
                </div>
                <form className="sidebarSearchForm" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <h5>Search</h5>
                        <Input
                            type="text"
                            name="keywords"
                            value={searchParams.keywords||""}
                            label="Enter name, industry or ABN"
                            onChange={handleChange}
                            error={errors.keywords}
                            placeholder="Search by Business Name"
                            iconClass="fa fa-search"
                            labelClass="sr-only"
                        />
                    </div>
                    <div className="form-row">
                        <h5>State</h5>
                        <BetterSelect
                            data={searchParams}
                            name="state"
                            options={[{value: 'ACT', text: 'Australian Capital Territory'},
                                {value: 'NSW', text: 'New South Wales'},
                                {value: 'NT', text: 'Northern Territory'},
                                {value: 'QLD', text: 'Queensland'},
                                {value: 'SA', text: 'South Australia'},
                                {value: 'TAS', text: 'Tasmania'},
                                {value: 'VIC', text: 'Victoria'},
                                {value: 'WA', text: 'Western Australia'}]}
                            valueProperty="value"
                            textProperty="text"
                            onChange={handleChange}
                            emptyLabel="All State"
                        />
                    </div>
                    <button
                        className="btn btn-outline-primary btn-block searchButton mb-5"
                        type="button" onClick={handleSubmit}>
                        Search
                    </button>
                </form>
            </div>
        );
    }
}

export default SidebarSearchCollectionPartner;
