import React from "react";
import config from "../../constants";
import {passwordErrorMessage, passwordRegEx} from "../../utils/regularExpressions";
import http from "../../services/httpNoAuthService";
import {baseHandleChange} from "../common/forms/helpers";
import {validate} from "../common/forms/validation";
import Form from "../common/forms/Form";
import {renderInput} from "../common/forms/Input";
import {toast} from "react-toastify";

const Joi = require('joi');

class ResetPassword extends React.Component {

    state = {
        data: { password: "", confirm_password: "" },
        errors: {},
        token: ""
    };

    schema = {
        1: {
            password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
                .messages({ "string.pattern.base": passwordErrorMessage }),
            confirm_password: Joi.any()
                .valid(Joi.ref('password')).label("Confirm Password")
                .messages({ "any.only" : "Passwords must match" })
        }
    };

    componentDidMount() {
        const token = this.props.match.params.token;
        this.setState({token});
    }

    handleChange = ({ currentTarget: input }) => {
        const { data, errors } = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({ data, errors });
    };

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        const errors = validate(this.schema, this.state.data);
        this.setState({ errors: errors || {} });
        if (errors) return false;

        return await this.doSubmit();

    };

    doSubmit = async () => {
        try {
            const { data } = this.state;
            await http.post(config.API_URL + "/password_reset/confirm/", {
                token: this.state.token,
                password: data.password
            });

            this.props.history.push({
                pathname: '/login',
                state: {
                    success_message: "Password successfully changed. You can now login again."
                }
            })

        } catch (ex) {
            if (ex.response.data.password) {
                toast.error(ex.response.data.password[0]);
            }

            if (ex.response && ex.response.status === 404) {
                const errors = { ...this.state.errors };
                this.setState({ errors });

                this.props.history.push({
                    pathname: '/forgot',
                    state: {
                        error_message: "Your link is expired, please request a new link to reset your password."
                    }
                })
            }
        }
    };

    render() {
        return (
            <div className="container-narrow pt-5">
                <h1>Reset Password</h1>
                <Form onSubmit={this.handleSubmit}
                      validate={this.handleValidate} >
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "password", "New Password", "", "password")}
                    {renderInput(this.state.data, this.state.errors, this.handleChange, "confirm_password", "Confirm Password", "", "password")}
                </Form>

            </div>
        );
    }
}

export default ResetPassword;
