import React, { Component } from "react";
import _ from "lodash";

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  createKey = (item, index, column) => {
    return index + (column.path || column.key);
  };

  render() {
    const { data, isSelected, columns } = this.props;

    return (
      <tbody>
        {data.map((item, index) => (
          <tr key={index} id={"row-" + index} className={isSelected && isSelected(item) ? "selected": ""}>
            {columns.map(column => (
              <td key={this.createKey(item, index, column)} className={column.hide ? "d-none" : ""}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
