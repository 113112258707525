import React, {useState} from "react";
import {Button, Modal, Form} from 'react-bootstrap'
import {Radio} from "./forms/radioGroup";

import tickCircle from "../../img/icons/tick-circle.svg"
import tickCircleDisabled from "../../img/icons/tick-circle-grey.svg"

const Resolve = props => {

    const [show, setShow] = useState(false);
    const [resolvedType, setResolvedType] = useState(null);
    const [resolvedAmount, setResolvedAmount] = useState(null);
    const [unresolvedReason, setUnresolvedReason] = useState(null);

    let classes = props.style === 'large' ? "btn btn-outline-primary" : "btn";
    if (props.status === 'resolved') classes = "btn resolved";

    let buttonLabel = "Resolve";
    if (props.label) buttonLabel = props.label;
    else if (props.status === 'resolved') buttonLabel = "Resolved";


    let showUnresolved = true;
    if (props.showUnresolved != null) showUnresolved = props.showUnresolved;

    let deactivated = false;
    if (props.deactivated) deactivated = props.deactivated;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleResolve = () => {
        setShow(false);
        props.onClick(resolvedType, resolvedAmount, unresolvedReason)
    }

    return (
        <>
            <button onClick={handleShow}
                    className={classes}
                    disabled={props.status === 'resolved' || deactivated}
                    aria-hidden="true">

                {props.style === 'large' && <>{buttonLabel}</> }
                {props.style !== 'large' && props.status === 'resolved' && <img src={tickCircleDisabled} width="20" height="20" alt={buttonLabel} />}
                {props.style !== 'large' && props.status !== 'resolved' && <img src={tickCircle} width="20" height="20" alt={buttonLabel} />}
            </button>

        <Modal show={show} onHide={handleClose} className="wideModal">
            <Modal.Header closeButton>
                <Modal.Title>Resolve Debt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Has this debt been resolved?

                <br/>
                <br/>
                <Form.Group className="mb-3 resolve">
                    <Radio name="resolved_type"
                           value="full"
                           id="full"
                           className="nobox"
                           checked={resolvedType === "full"}
                           onChange={({target: {value}}) => setResolvedType(value)}
                    />
                    <label htmlFor="full">
                        <div className="tile">
                            Yes - in full
                        </div>
                    </label>

                    <Radio name="resolved_type"
                           value="partial"
                           id="partial"
                           className="nobox"
                           checked={resolvedType === "partial"}
                           onChange={({target: {value}}) => setResolvedType(value)}
                    />
                    <label htmlFor="partial">
                        <div className="tile">
                            Yes - in part
                        </div>
                    </label>

                    {showUnresolved && <React.Fragment>
                    <Radio name="resolved_type"
                           value="unresolved"
                           id="unresolved"
                           className="nobox"
                           checked={resolvedType === "unresolved"}
                           onChange={({target: {value}}) => setResolvedType(value)}
                    />
                    <label htmlFor="unresolved">
                        <div className="tile">
                            No
                        </div>
                    </label></React.Fragment>}
                </Form.Group>

                {resolvedType === 'partial' &&
                    <React.Fragment>
                        Great! What did the debtor pay?
                        <br/><br/>
                        <Form.Group className="mb-3 resolve">
                        {props.resolvedAmountOptions.map(option => (
                            <React.Fragment key={option.pk}>
                                <Radio name="resolved_amount"
                                       id={option.pk}
                                       value={option.pk}
                                       className="nobox"
                                       checked={resolvedAmount == option.pk}
                                       onChange={({target: {value}}) => setResolvedAmount(value)}
                                />
                                <label htmlFor={option.pk}>
                                    <div className="tile">
                                        {option.title}
                                    </div>
                                </label>
                            </React.Fragment>
                        ))}
                        </Form.Group>
                    </React.Fragment>
                }
                {resolvedType === 'unresolved' && props.unresolvedReasonOptions &&
                <React.Fragment>
                    We're sorry to hear that. Please tell us why this wasn't resolved?
                    <br/><br/>
                    <Form.Group className="mb-3 resolve">
                        {props.unresolvedReasonOptions.map(option => (
                            <React.Fragment key={option.pk}>
                                <Radio name="unresolved_reason"
                                       id={option.pk}
                                       value={option.pk}
                                       className="nobox"
                                       checked={unresolvedReason == option.pk}
                                       onChange={({target: {value}}) => setUnresolvedReason(value)}
                                />
                                <label htmlFor={option.pk}>
                                    <div className="tile">
                                        {option.title}
                                    </div>
                                </label>
                            </React.Fragment>
                        ))}
                    </Form.Group>
                </React.Fragment>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"
                        onClick={handleResolve}>
                    Resolve
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
};

export default Resolve;
