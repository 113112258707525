import React, {Component} from "react";
import './../../progressTracker.scss';
import moment from "moment";

class ProgressTrackerVendor extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {listing, debt} = this.props;

        // letter of demand
        let letterOfDemandClassName = "hidden";
        if (debt.letter_of_demand_sent != null && listing.status === 'offer_accepted' && debt.negotiations_commenced == null) letterOfDemandClassName = "completed last";
        else if (debt.letter_of_demand_sent != null) letterOfDemandClassName = "completed";

        // negotiations
        let negotiationsClassName = "hidden";
        if (debt.negotiations_commenced != null && listing.status === 'offer_accepted' && debt.court_action_required == null) negotiationsClassName = 'active';
        else if (debt.negotiations_commenced != null && (debt.court_action_required != null || listing.status === 'resolved')) negotiationsClassName = 'completed';

        // awarded
        let awardedStepClassName = "completed";
        if (letterOfDemandClassName === 'hidden') awardedStepClassName = "completed last";

        const debtSettledStatus = listing.status === 'resolved' ? "completed" : "future";

        return (
            <div className="progress-tracker placer">

                {/* Listing awarded */}
                <div className={"step " + awardedStepClassName}>
                    {listing.awarded_date &&
                    <div className="date">{moment(listing.awarded_date).format('DD MMM YYYY')}</div>}
                    <div className="title">Collection Commenced</div>
                </div>

                {letterOfDemandClassName === 'hidden' && debt.letter_of_demand_sent == null &&
                <div className="step future">
                    <div className="title">Next step</div>
                    <p>Your Collection Partner will be sending a letter of demand to the debtor seeking
                        immediate payment. This is typically sent to the debtor within the first 48 hours of
                        engaging your Collection Partner.</p>
                </div>}

                {/* Letter of demand */}
                {letterOfDemandClassName !== 'hidden' &&
                <div className={"step " + letterOfDemandClassName}>
                    {debt.letter_of_demand_sent &&
                    <div className="date">{moment(debt.letter_of_demand_sent).format('DD MMM YYYY')}</div>}
                    <div className="title">Letter of Demand</div>
                    <p>A letter of demand has been sent to the debtor.</p>
                </div>}

                {debt.letter_of_demand_sent && debt.negotiations_commenced == null && listing.status !== 'resolved' &&
                <div className="step future">
                    <div className="title">Next step</div>
                    <p>The debtor will typically have up to 14 days to
                        respond to this letter of demand. If they do not
                        respond, your Collection Partner will start the
                        negotiation phase to recover your debt.</p>
                </div>}

                {/* Negotiations */}
                {negotiationsClassName !== 'hidden' &&
                <div className={"step " + negotiationsClassName}>
                    {debt.negotiations_commenced &&
                    <div className="date">{moment(debt.negotiations_commenced).format('DD MMM YYYY')}</div>}
                    <div className="title">Negotiations {debt.negotiations_commenced && "commenced"}</div>
                    <p>The debt negotiation phase has commenced.</p>

                    {debt.negotiations_commenced &&
                    <div className="progress-updates">Case notes go here</div>}

                </div>}

                {negotiationsClassName === 'active' && debt.negotiations_commenced != null &&
                <div className="step future">
                    <div className="title">Next step</div>
                    <p>Your Collection Partner will continue to negotiate a
                        resolution with the debtor for anywhere between a couple
                        of weeks, up to a couple of months. Depending on the
                        nature of the debt, this phase may take time to resolve
                        however we will continue to check in with your Collection
                        Partner on the status of this debt every 7 days and ensure
                        you remain abreast of the collection status.</p>
                    <p>If there is no resolution reached, your Collection Partner
                        may suggest taking this forward through the Court process.</p>
                </div>}

                {/* Debt Settled */}
                {debtSettledStatus === 'completed' &&
                <div className={"step " + debtSettledStatus + " debt-settled"}>
                    {listing.date_resolved &&
                    <div className="date">{moment(listing.date_resolved).format('DD MMM YYYY')}</div>}
                    <div className="title">Debt Settled</div>
                    {listing.status === 'resolved' &&
                    <p>Great news! Your debt has settled! To discuss next steps and arrange for the distribution of funds to your nominated bank account, please get in touch with your Collection Partner.</p>}
                </div>}

                {/* Court Action Required */}
                {debt.court_action_required != null &&
                <React.Fragment>
                <div className="step court-action">
                    {debt.court_action_required &&
                    <div className="date">{moment(debt.court_action_required).format('DD MMM YYYY')}</div>}
                    <div className="title">Court action required</div>
                    <p>Due to the difficulties in collecting this particular
                        debt, your Collection Partner has suggested the
                        only way to recover the amount owing to you is
                        through the Court process.</p>
                </div>
                <div className="step active">
                    <div className="title">Next step</div>
                    <p>Taking this forward through the Court process can be daunting for some
                        business owners and there is no obligation for you to proceed down this
                        path.</p>
                    <p>Your Collection Partner should explain this process but you can also visit
                        Debtplacer’s resources page to learn more.</p>
                </div>
                </React.Fragment>}

            </div>
        );
    }
};

export default ProgressTrackerVendor;
