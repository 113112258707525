import {React, useState} from "react";
import DebtDetail from "./DebtDetail";
import {formatCurrency} from "../common/numberFormats";
import UploadDebts from "./UploadDebts"
import {toast} from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx";
import config from "../../constants";
import DataTable from "react-data-table-component";


const columnDefinition = ['Due Date', 'Amount', 'Debtor Type',
    'Business Name', 'First Name', 'Last Name',
    'ABN', 'Address 1', 'Address 2', 'Suburb', 'Post Code', 'State',
    'Phone', 'Email', 'Contact First Name', 'Contact Last Name',
    'Date Of Birth', 'Driver License', 'Comments'
];

// when data is loaded from the backend (listing.debts), we need to map the columns
const columnMapping = [
    {name: "Due Date", selector: row => row['due_date']},
    {name: "Amount", selector: row => row['amount']},
    {name: "Debtor Type", selector: row => row['debtor_type']},
    {name: "Business Name", selector: row => row['business_name']},
    {name: "First Name", selector: row => row['first_name']},
    {name: "Last Name", selector: row => row['last_name']},
    {name: "ABN", selector: row => row['abn']},
    {name: "Address 1", selector: row => row['address_1']},
    {name: "Address 2", selector: row => row['address_2']},
    {name: "Suburb", selector: row => row['suburb']},
    {name: "Post Code", selector: row => row['zip_code']},
    {name: "State", selector: row => row['state']},
    {name: "Phone", selector: row => row['contact_phone']},
    {name: "Email", selector: row => row['contact_email']},
    {name: "Contact First Name", selector: row => row['contact_first_name']},
    {name: "Contact Last Name", selector: row => row['contact_last_name']},
    {name: "Date Of Birth", selector: row => row['date_of_birth']},
    {name: "Driver License", selector: row => row['driver_license']},
    {name: "Comments", selector: row => row['private_comments']},
]


export default function DebtDetailsStep({
                                            listing,
                                            debtValidationErrors,
                                            industries,
                                            minValue,
                                            setDebt,
                                            handleUploadDebts,
                                            handleDebtChange,
                                            handleDebtDateChange,
                                            handleDeleteDebt,
                                            handleAddDebt,
                                            handleReloadDebt,
                                            handleSaveDebt,
                                            handleValidateDebt,
                                            validateAbn,
                                            abnsValidated,
                                            debtsInEditingMode,
                                            setEditingActive,
                                            embedded
                                        }) {

    const [activeForm, setActiveForm] = useState('manual')
    const [errors, setErrors] = useState([])
    const [columns, setColumns] = useState([])
    const [data, setData] = useState([])
    const [newFile, setNewFile] = useState(false)

    const hasSavedDebts = listing.debts.find(debt => debt.id !== null) !== undefined

    function processData (dataString) {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        // validate the column names
        const headersComplete = columnDefinition.every(el => headers.includes(el))
        if (!headersComplete) {
            const errorMsg = "Please make sure that the columns in your file match our template";
            const newErrors = errors;
            newErrors.push(errorMsg);
            setErrors(newErrors)
            toast.error(errorMsg);
            return;
        }

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {

                        // re-format/process values here
                        switch(headers[j]) {
                            case "Due Date":
                            case "Date Of Birth":
                                if (!d) break;
                                let convertedDate = moment(d).format('YYYY-MM-DD');
                                obj[headers[j]] = convertedDate;
                                break;
                            case "Amount":
                                let amount =  Number(d.replace(/[^0-9.-]+/g,""));
                                obj[headers[j]] = amount;
                                break;
                            case "Debtor Type":
                                let debtorType = d.toLowerCase();
                                obj[headers[j]] = debtorType;
                                break;
                            case "ABN":
                                let abn = d.toLowerCase();
                                obj[headers[j]] = abn.replace(/\s/g, '');
                                break;
                            default:
                                obj[headers[j]] = d;
                        }

                    }
                }

                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }

        // prepare columns list from headers
        const uploadedColumns = headers.map(c => ({
            name: c,
            selector: row => row[c]
        }));

        setData(list)
        setColumns(uploadedColumns)
        setNewFile(true)
    }

    function handleFileUpload (e) {
        setNewFile(true)
        setErrors([])

        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const uploadedData = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(uploadedData);
        };
        reader.readAsBinaryString(file);
    }

    async function handleConfirmUpload (e) {
        setNewFile(false)
        await handleUploadDebts(data);
        setActiveForm('manual')
    }

    function editingDisabled() {
        return listing.listing_type === 'sale'
    }

    if (!listing.debts) return <></>
    return (
        <div className="listingFormContainer">
            <div className="debtDetailsHeader mb-2">
                <h4>Debtor Details</h4>
                {activeForm === 'manual' && !hasSavedDebts && <span> Prefer to <a href="#" onClick={() => setActiveForm('upload')}>upload a spreadsheet</a>?</span>}

                {activeForm === 'upload' &&<a href={config.MULTI_LEDGER_TEMPLATE_DOWNLOAD_URL} target="_blank"
                   className="pull-right" download>
                    Download .xlsx Template <i className="fa fa-download"></i></a>}
            </div>

            {activeForm === 'upload' && <div>
                <div className="form-group">

                    <div id="dropzone">
                        <div><i className="fa fa-cloud-upload fa-2x"></i><br/>
                            Drop a file from your computer, or click to select a file to upload
                        </div>
                        <input
                            type="file"
                            accept=".csv,.xlsx,.xls"
                            onChange={handleFileUpload}
                        />
                    </div>

                    <div className="dataTable">
                        {newFile &&
                            <DataTable
                                pagination
                                highlightOnHover
                                columns={columns}
                                data={data}
                            />}
                    </div>

                    {data.length > 0 && errors.length <= 0 && newFile &&
                        <button className="pull-right btn btn-primary btn-sm confirmUpload"
                                onClick={handleConfirmUpload}> Confirm Upload
                        </button>}

                </div>

            </div>}

            {activeForm === 'manual' && <div className="debtDetails">
                {listing.debts.map((debt, index) => {
                    return <DebtDetail key={index}
                                       debt={debt}
                                       index={index}
                                       debtValidationErrors={debtValidationErrors[index] || {}}
                                       industries={industries}
                                       handleDebtChange={(event) => handleDebtChange(event, index)}
                                       handleDebtDateChange={handleDebtDateChange}
                                       setDebt={setDebt}
                                       handleDeleteDebt={handleDeleteDebt}
                                       handleReloadDebt={handleReloadDebt}
                                       handleSaveDebt={handleSaveDebt}
                                       handleValidateDebt={handleValidateDebt}
                                       validateAbn={validateAbn}
                                       editingActive={debtsInEditingMode[index]}
                                       setEditingActive={setEditingActive}
                                       abnsValidated={abnsValidated}
                                       listing={listing}
                                       editingDisabled={editingDisabled()}
                    />
                })}

                {listing.debts.length > 0 && listing.total_value < minValue &&
                    <div className="infoContainer info">Please enter debts for a total value of at
                        least {formatCurrency(minValue)} to publish the listing.</div>}

            </div>}

            {activeForm === 'upload' && <button className="btn btn-secondary my-5" onClick={() => setActiveForm('manual')}>Add Debt Manually Instead</button>}
            {activeForm === 'manual' && !embedded && <button className="btn btn-primary newButton my-2" onClick={(event) => handleAddDebt(event)}>Add Debt</button>}
        </div>
    )
}
