import React, {Component} from "react";
import _ from "lodash";

import config from "../../constants";
import {toast} from "react-toastify";

import Dropzone from 'react-dropzone-uploader';

import 'react-dropzone-uploader/dist/styles.css';
import {Input} from "../common/forms/Input";
import {Select} from "../common/forms/Select";


class SupportingDocumentsForm extends Component {

    constructor(props) {
        super(props);
    }

    getUploadParams = ({file, meta}) => {
        const body = new FormData()
        body.append('file', file)
        return {
            url: config.API_URL + `/placer_listings/${this.props.listingId}/upload_supporting_document_file/`,
            headers: {
                'Authorization': "Bearer " + localStorage.getItem('access_token'),
                'enctype': 'multipart/form-data',
                'access-type': localStorage.getItem('access_type'),
                'placer-id': localStorage.getItem('placer_id')
            },
        }
    };

    handleChangeStatus = (file, status) => {

        const onAdd = this.props.onAdd;

        if (status === 'headers_received') {
            const request = file.xhr;
            toast(`${file.meta.name} uploaded.`, {autoClose: 3000});

            const promise = new Promise(function (resolve, reject) {

                // Setup our listener to process completed requests
                request.onreadystatechange = function () {

                    // Only run if the request is complete
                    if (request.readyState !== 4) return;

                    // Process the response
                    if (request.status >= 200 && request.status < 300) {
                        // If successful
                        resolve(request);
                        const json = JSON.parse(file.xhr.response);
                        onAdd(json);
                    } else {
                        // If failed
                        reject({
                            status: request.status,
                            statusText: request.statusText
                        });
                    }

                };

            });

            file.remove(); // remove file from dropzone
        } else if (status === 'aborted') {
            toast(`${file.meta.name}, upload failed...`);
        }
    }

    render() {

        const {documents, documentTypes, onChange, onDelete, documentTypeOtherId, status, listing} = this.props;

        return (
            <div>
                {/*max 10 MB*/}
                <Dropzone
                    getUploadParams={this.getUploadParams}
                    onChangeStatus={this.handleChangeStatus}
                    multiple={true}
                    canCancel={true}
                    maxSizeBytes={1024 * 1024 * 10}
                    inputContent={<div key={1} style={{textAlign: 'center'}}>
                        <i className="fa fa-cloud-upload fa-2x"></i><br/>Drop a file from your computer, or select a file to
                        upload</div>}
                    styles={{
                        dropzone: {maxWidth: 650, height: 200},
                        dropzoneActive: {borderColor: 'green'},
                        inputLabel: {
                            fontSize: 16,
                            fontWeight: 500,
                            color: "#5f6367"
                        }
                    }}
                />
                <br/>
                {documents?.length > 0 ?
                    <table className="table supporting-documents-table">
                        <thead>
                        <tr>
                            <th scope="col">Link</th>
                            <th scope="col">Type</th>
                            <th scope="col">Debtor</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {documents?.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <a href={item.download_link} target="_blank">{item.file}</a>
                                </td>
                                <td width={"30%"}>
                                    <Select
                                        name="document_type"
                                        value={_.get(item, "document_type") || ""}
                                        label="Document type"
                                        options={documentTypes}
                                        labelClass="sr-only"
                                        onChange={(event) => onChange(event, index)}
                                    />
                                    {item.document_type == documentTypeOtherId &&
                                    <Input
                                        type="text"
                                        name="comments"
                                        value={_.get(item, "comments") || ""}
                                        labelClass="sr-only"
                                        placeholder="Please specify"
                                        onChange={(event) => onChange(event, index)}
                                    />}
                                </td>
                                <td width={"30%"}>
                                    <Select
                                        name="debt"
                                        value={_.get(item, "debt") || ""}
                                        label="Debtor"
                                        options={listing.debts}
                                        valueProperty="id"
                                        textProperty="debtor_name"
                                        labelClass="sr-only"
                                        onChange={(event) => onChange(event, index)}
                                    />
                                </td>

                                <td> { item.id != null && (status === 'offer_accepted' || status === 'offers_pending' ) ? null :
                                            <button className="pull-right btn btn-secondary" onClick={(event) => onDelete(event, index)}>
                                                <i className="fa fa-trash"></i>
                                            </button>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table> : null
                        }
            </div>
        );
    }
}

export default SupportingDocumentsForm;
