import React, {useState, useEffect} from "react"
import moreOptions from '../../img/icons/options.svg'

export default function MoreOptionsNav({children}) {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', close);
        return () => window.removeEventListener("mousedown", close);
    }, []);

    function close(event) {
        if (event.target.nodeName === 'A') return
        setOpen(false)
    }

    function toggleOpen() {
        setOpen(!open)
    }

    return (
        <>
            <img src={moreOptions} onClick={toggleOpen}/>
            {open && <div className="moreOptionsNavContainer">{children}</div>}
        </>
    )
}