import React from "react";

import img from "../../img/completed.png";

export default function EmbeddedListingCompleted({embedded, sale}) {

    return (
        <div className="text-center mt-5 completed">
            <img src={img} alt="Checkmark" width="80" height="80"/>
            <h1 className="mt-3">Listing completed.</h1>
            <p>You can now close this window.</p>
            {/*<button className="btn btn-primary" onClick={parent.closeModal}>Exit</button>*/}
        </div>
    )

}