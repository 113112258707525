import React, {Component} from "react";
import http from "../../services/httpService";
import {getURLParamString} from "../../utils/search";

import _ from "lodash";
import {Ratings} from "../common/ratings";
import {Button, Card, Spinner} from "react-bootstrap"
import moment from 'moment'
import SidebarSearchCollectionPartner from "../search/SidebarSearchCollectionPartner";
import {BetterSelect} from "../common/forms/BetterSelect";
import {APIPagination, pageCount} from "../common/pagination";
import auth from "../../services/authService";
import {Link} from "react-router-dom";

import '../../forms.scss';
import '../../search.scss';

const apiPath = "collection_partners/";

class CollectionPartners extends Component {

    state = {
        user: null,
        listings: [],
        searchParams: {},
        totalResults: 0,
        currentPage: 1,
        pageSize: 10,
        maxDebtAmount: null,
        industries: [],
        errors: []
    };

    getDefaultSearchParams = () => {
        return {
            keywords: null,
            state: null,
            order_by: null,
        };
    }

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        const user = auth.getCurrentUser();

        let searchParams = this.getDefaultSearchParams();

        const paramsStr = this.props.location.search
        const urlParams = new URLSearchParams(paramsStr);

        for (const [key, value] of Object.entries(searchParams)) {
            if (urlParams.has(key)) {

                let newValue = urlParams.get(key)
                if (parseInt(newValue) >= 0) {
                    newValue = parseInt(newValue);
                }

                if (newValue === "true") newValue = true;
                if (newValue === "false") newValue = false;
                searchParams[key] = newValue;
            }
        }

        this.setState({user, searchParams});

        await this.loadListings();
    }

    async loadListings() {
            const {data: response} = await http.get(apiPath, {
                params: {...this.state.searchParams, page: this.state.currentPage}
            });
            this.setState({
                listings: response.results,
                totalResults: response.count
            });
    }

    handleSetPage = (page) => {
        this.setState({currentPage: page}, () => this.loadListings());

    }

    handleChange = ({currentTarget: input}) => {

        const {searchParams, errors} = this.state;

        if (input.type === 'checkbox') {
            _.set(searchParams, input.name, input.checked);
        } else {
            _.set(searchParams, input.name, input.value);
        }

        this.setState({searchParams, errors})

    };

    handleSubmit = async (e) => {
        this.setState({loading: true});
        if (e) e.preventDefault();

        // load new result based on state search params
        await this.loadListings();

        const urlParams = getURLParamString(this.state.searchParams);
        history.pushState({}, null, "collection-partners?" + urlParams);
    };

    handleSort = async ({currentTarget: {name, value}}) => {
        const {searchParams, errors} = this.state;
        _.set(searchParams, name, value);
        this.setState({searchParams, errors}, async () => {
            await this.loadListings();
        })
        // const urlParams = getURLParamString(this.state.searchParams);
        // history.pushState({}, null, "collection-partners?" + urlParams);
    }

    handleClearFilters = async (e) => {
        if (e) e.preventDefault();

        this.setState({
            searchParams: this.getDefaultSearchParams()
        }, () => {
            this.handleSubmit()
        });

    }

    render() {
        const {length: count} = this.state.listings;
        const {errors, searchParams, totalResults, pageSize, currentPage, listings} = this.state;

        return (
            <div className="container container-wide searchResult pt-0">
                <div className="container-row row">
                    <div className="col-sm-12 col-lg-3 sidebarFilters">
                        <SidebarSearchCollectionPartner
                            handleChange={this.handleChange}
                            handleSubmit={this.handleSubmit}
                            handleClearFilters={this.handleClearFilters}
                            searchParams={searchParams}
                            errors={errors}
                        />
                    </div>

                    <div className="col-sm-12 col-lg-9  searchResults">
                        <div className="searchResultsHeader">
                            <div className="row pull-right" style={{width: 500}}>
                                <div className="col-sm-12 padding-5">
                                    <BetterSelect
                                        data={searchParams}
                                        name="order_by"
                                        options={[
                                            {value: 'business_name', text: 'Business Name (A-Z)'},
                                            {value: '-business_name', text: 'Business Name (Z-A)'},
                                            {value: 'rating_avg', text: 'Rating (High to Low)'},
                                            {value: '-rating_avg', text: 'Rating (Low to High)'},
                                            {value: 'creation_date', text: 'Member Since (Newest to Oldest)'},
                                            {value: '-creation_date', text: 'Member Since (Oldest to Newest)'},
                                        ]}
                                        valueProperty="value"
                                        textProperty="text"
                                        onChange={this.handleSort}
                                        emptyLabel="Sort By"
                                    />
                                </div>

                            </div>
                            <h2>Collection Partners </h2>
                            Page {currentPage} of {pageCount(totalResults, pageSize)}
                        </div>
                        <div className="searchResultsBody">
                            {
                                count === 0 ? <Spinner className="spinner" animation="border" role="status"/>
                                    :
                                    listings.map((item, idx) => {

                                        return <React.Fragment key={idx}>
                                            <Card className={'collection-card'}>
                                                <Card.Body>
                                                    <Ratings value={item.rating_avg}/>
                                                    <Card.Title as={"h5"}
                                                                className="cardTitle">{item.business_name}</Card.Title>
                                                    <Card.Subtitle
                                                        className="mb-2 text-muted cardSubTitle">{item.suburb}, {item.state}</Card.Subtitle>
                                                    <Card.Text>
                                                        <i className="fa fa-clock-o green"></i> Member since {moment(item.creation_date).format('MMMM YYYY')}
                                                    </Card.Text>
                                                    <Link to={'/collection-partners/' + item.id}>
                                                        <Button style={{
                                                            position: "absolute",
                                                            top: 20,
                                                            right: 20
                                                        }} variant="primary" className="view">View</Button>
                                                    </Link>
                                                </Card.Body>
                                            </Card>
                                        </React.Fragment>
                                    })
                            }
                        </div>
                        <div className="searchResultsPagination">
                            {
                                totalResults === 0 ? null :
                                    <APIPagination currentPage={currentPage} itemsCount={totalResults}
                                                   onPageChange={this.handleSetPage}
                                                   pageSize={pageSize}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CollectionPartners;
