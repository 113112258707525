import React, {Component} from "react";
import Debts from "./Debts";
import DebtorContact from "./DebtorContact";
import CollectionPartnerContact from "./CollectionPartnerContact";
import VendorContact from "./VendorContact";
import SupportingDocuments from "./SupportingDocuments";
import SupportingDocumentsSummary from "./SupportingDocumentsSummary";
import InfoContainer from "../common/infoContainer";
import ListingBarChart from "./ListingBarChart";

class ListingDetailBody extends Component {

    render() {

        const {listing, debt, viewer} = this.props;
        const collectionPartner = listing.collection_partner;

        let debtor = null
        if (debt) debtor = debt.debtor

        const placer = listing.placer;

        return (
            <React.Fragment>

                <div className="listingRow">
                    <h2>Details</h2>

                    <p>{listing.comments}</p>
                    {listing.is_disputed &&
                        <InfoContainer type="info" iconClass="fa fa-info-circle">
                            This debt is being disputed. <br/> {listing.is_disputed_comments}
                        </InfoContainer>
                    }
                </div>

                {!debt && <React.Fragment>
                    <div className="listingRow">
                        <h2>Debts Overview</h2>
                        <ListingBarChart listing={listing} viewer={viewer} />
                    </div>

                    <div className="listingRow">
                        <Debts debts={listing.debts} listing={listing} />
                    </div>
                </React.Fragment>}


                <div className="listingRow">

                    {/*Supporting Documents if visible TODO might have to adjust for debts */}
                    {listing.supporting_documents.length > 0 &&
                        <React.Fragment>
                            <h2 className="float-left">Supporting Documents</h2>
                            <SupportingDocuments supportingDocuments={listing.supporting_documents}/>
                        </React.Fragment>
                    }

                    {/*Supporting Documents summary if not visible but docs exist */}
                    {listing.status === 'listed' && !listing.supporting_documents.length && listing.supporting_documents_summary && listing.supporting_documents_summary.length >= 0 &&
                        <React.Fragment>
                            <h2>Supporting Documents</h2>
                            <p>Once the client has accepted your offer, the following documents will be made available
                                to you:</p>
                            <SupportingDocumentsSummary
                                supportingDocumentsSummary={listing.supporting_documents_summary}/>
                        </React.Fragment>
                    }

                </div>

                <div className="listingRow">
                    <h2>Contact Details</h2>

                    {listing.collection_partner_status !== 'offer_accepted' && viewer === "placer" &&
                        <p>Once you have accepted an offer from a Collection Partner, their details will appear
                            here.</p>}

                    {listing.collection_partner_status !== 'offer_accepted' && viewer === "collection_partner" &&
                        <p>Once the client has accepted your offer, these details will become available.</p>}

                    <table className="listingTable table-striped">
                        <thead>
                        <tr>
                            {debtor && <th>Debtor</th>}
                            {viewer === "placer" && <th>Collection Partner</th>}
                            {viewer === "collection_partner" && <th>Client</th>}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {debtor && <td><DebtorContact debtor={debtor}/></td>}
                            <td>
                                {viewer === "placer" &&
                                    <CollectionPartnerContact collectionPartner={collectionPartner}/>}
                                {viewer === "collection_partner" && listing.collection_partner_status === 'offer_accepted' &&
                                    <VendorContact placer={placer}/>}
                            </td>
                        </tr>
                        <tr>
                            {debtor && <td className="debtorIndustry">{debtor.industry_description}</td>}
                            <td className="placerIndustry">
                                {viewer === "collection_partner" &&
                                    <React.Fragment>{placer.industry_description}</React.Fragment>}
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </React.Fragment>
        );
    }
}

export default ListingDetailBody;
