import React, {useEffect, useState} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {validate} from "../common/forms/validation";
import {Input} from "../common/forms/Input";
import moment from "moment";

const Joi = require('joi');

function PlacerAccountSettings() {

    const [data, setData] = useState()
    const [errors, setErrors] = useState()

    const schema = {
        1: {
            invites: Joi.array().items(Joi.object({
                user: {
                    email: Joi.string().email({tlds: {allow: false}}).required().label("Email")
                }
            }))
        }
    }

    useEffect(() => {
        loadData()
    }, []);


    async function loadData() {
        const {data} = await http.get(config.API_URL + "/placer_accesses/");
        setData(data);
    }

    function addRow() {
        let newData = [...data]
        newData.push({
            id: null,
            user: {
                first_name: null,
                last_name: null,
                email: ""
            },
            status: null,
            creation_date: null
        })
        setData(newData)
    }

    async function handleRemove(accessId) {
        try {
            await http.delete(config.API_URL + '/placer_accesses/' + accessId + "/");
            let newData = [...data]
            newData.splice(data.findIndex(item => item.id === accessId), 1)
            setData(newData)
            toast.success("Access removed",{autoClose: 3000})
        } catch (e) {
            toast.error("Something went wrong", {autoClose: 3000})
        }
    }

    function removeRow(index) {
        let newData = [...data]
        newData.splice(index, 1)
        setData(newData)
    }

    function handleUserChange(index, field, value) {
        let newData = [...data]
        newData[index]["user"][field] = value
        setData(newData)
        if (field === "email") setErrors(handleValidate()) // temporary hack, not great
    }

    function handleValidate() {
        return validate(schema, {invites: data})
    }

    async function handleSubmit() {
        const errors = handleValidate()
        setErrors(errors || {})
        if (errors) return

        try {
            const response = await http.post(config.API_URL + "/placer_accesses/", data);
            setData(response.data)
            toast.success("Successfully saved",{autoClose: 3000})
        } catch (e) {
            toast.error("Something went wrong",{autoClose: 3000})
        }
    }

    return (
        <div className="container">
            <h1>Manage Team Access</h1>

            <p>Add additional users to your account here.</p>

            {data && <table className="table table-striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Invited</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {data.map((access, index) => (
                    <tr key={index}>
                        <td>
                            {access.id ? <>{access.user.first_name} {access.user.last_name}</>
                                : <div className="form-row mb-2">
                                    <div className="col-6">
                                        <Input
                                            placeholder="First name"
                                            id={index + "_first_name"}
                                            type="text"
                                            name="first_name"
                                            value={access.user.first_name}
                                            error={errors && errors[`invites.${index}.user.first_name`]}
                                            onChange={(event) => handleUserChange(index, "first_name", event.target.value)}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Input
                                            placeholder="Last name"
                                            id={index + "_last_name"}
                                            type="text"
                                            name="last_name"
                                            value={access.user.last_name}
                                            error={errors && errors[`invites.${index}.user.last_name`]}
                                            onChange={(event) => handleUserChange(index, "last_name", event.target.value)}
                                        />
                                    </div>
                                </div>}
                        </td>
                        <td>
                            {access.id ? <>{access.user.email}</>
                                :
                                <div className="form-row mb-2">
                                    <Input
                                        placeholder="Email"
                                        id={index + "_email"}
                                        type="text"
                                        name="email"
                                        value={access.user.email}
                                        error={errors && errors[`invites.${index}.user.email`]}
                                        onChange={(event) => handleUserChange(index, "email", event.target.value)}
                                    />
                                </div>}
                        </td>
                        <td>{access.id && access.get_status_display}</td>
                        <td>{access.id && moment(access.creation_date).format("DD/MM/YYYY")}</td>
                        <td>
                            {access.id ? <button type="button"
                                                 title="Remove"
                                                 className="btn text-danger" onClick={() => handleRemove(access.id)}>
                                    Remove access
                                </button> :
                                <button type="button"
                                        title="Remove"
                                        className="btn text-danger" onClick={() => removeRow(index)}>
                                    Remove
                                </button>
                            }
                        </td>
                    </tr>
                ))}

                </tbody>

            </table>}

            <div className="buttonContainer teamButtonsContainer">
                <button type="button" className="btn btn-secondary"
                        onClick={addRow}><i className="fa fa-plus"></i> Add team member
                </button>

                <button type="button" className="btn btn-primary"
                        onClick={handleSubmit}>Save
                </button>
            </div>

        </div>
    );
}

export default PlacerAccountSettings;
