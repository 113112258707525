import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";
import http from "../../services/httpService";
import config from "../../constants";
import ListingDetailBody from "./ListingDetailBody";
import Offers from "./Offers";
import {Tab, Tabs} from "react-bootstrap";
import ListingSummary from "./ListingSummary";
import OfferSummary from "./OfferSummary";
import ProgressTrackerVendor from "./ProgressTrackerVendor";
import {Button} from "react-bootstrap"
import {StickyContainer, Sticky} from 'react-sticky';
import InfoContainer from "../common/infoContainer";
import {formatAbn} from '../common/numberFormats'
import {Media} from '../../AppMedia'
import {toast} from "react-toastify";

const apiPath = "/placer_listings/";

export default function VendorListingDetail(props) {

    const [listing, setListing] = useState()
    const [errors, setErrors] = useState([])
    const [loaded, setLoaded] = useState(false)
    const {listingId, debtId} = useParams();

    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {
        const resp = await http.get(config.API_URL + apiPath + listingId + "/");
        setListing(resp.data)
        setLoaded(true)
    }

    function backToPrevious() {
        let searchHistory = sessionStorage.getItem("search")
        if (searchHistory) {
            let search = JSON.parse(searchHistory)
            props.history.push(search.pathname + "/" + search.search)
        } else {
            props.history.push("/listings")
        }
    }

    async function deleteListing() {

        try {
            await http.post(config.API_URL + apiPath + listingId + "/delete/");
            var message = "Listing deleted. You can still see it under deleted listings on your dashboard.";

            // redirect with toast message
            props.history.push({
                pathname: '/dashboard',
                state: {
                    success_message: message
                }
            });

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...errors};
                setErrors({errors});
                toast.error("Error.");
            }
        }
    };

    async function restoreListing() {

        try {
            await http.post(config.API_URL + apiPath + listingId + "/restore/");
            loadData();
            toast.success("Listing restored. You can now edit and publish it by clicking Edit Listing.", {autoClose: 3000});
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...errors};
                setErrors({errors});
                toast.error("Error.");
            }
        }
    };

    let search = sessionStorage.getItem("search")
    if (!loaded) return (<div></div>);

    let debtor = null
    let debt = null
    if (listing.debts.length === 1) {
        debt = listing.debts[0]
        debtor = debt.debtor
    }
    else if (debtId) {
        debt = listing.debts.find(debt => debt.id === parseInt(debtId))
        debtor = debt.debtor
    }

    const offers = listing.offers;

    let defaultActiveTab = "listing";
    if (debt && (listing.collection_partner != null && (listing.status === 'offer_accepted' || listing.status === 'resolved')))
        defaultActiveTab = "track-progress"

    let details = (media) => <div className="container listingDetail">
        <StickyContainer>
            <div className="row">
                <div className="col-md-12 padding-20 col-sm-12">

                    {debtId &&
                        <a className="btn btn-secondary" href={`/listings/${listingId}`}>
                            <i className="fa fa-arrow-left"/> &nbsp; Back to Listing</a>}

                    {!debtId && <Button
                        onClick={backToPrevious}
                        variant="secondary">
                        <i className="fa fa-arrow-left"/> &nbsp;
                        Back to {search ? "Results" : "List"}
                    </Button>}
                    <br/>
                    <br/>
                </div>
                <div className="col-md-8 col-sm-12">

                    <div className="listingHeader">
                        {!debtId && (listing.status === 'listed' || listing.status === 'draft')
                            &&
                            <React.Fragment>
                                    <Link to={`/listings/${listing.id}/edit`}>
                                        <button className="btn btn-primary float-right">Edit Listing</button>
                                    </Link>
                                    <Button
                                        className="btn-delete danger float-right"
                                        onClick={deleteListing}
                                        style={{"marginRight": "15px"}}
                                        variant="danger">
                                        <i className="fa fa-trash"/>
                                    </Button>
                            </React.Fragment>}

                        {(listing.status === 'trash') &&
                            <React.Fragment>
                                <Button
                                    className="btn btn-primary float-right"
                                    onClick={restoreListing}
                                    style={{"marginRight": "15px"}}>
                                    <i className="fa fa-rotate-left"/> &nbsp; Restore Listing
                                </Button>
                            </React.Fragment>}

                        {/* Just one debtor */}
                        {debtor && <React.Fragment>
                            <h1 style={{"marginBottom": "15px"}}>{debtor.name}</h1>

                            {debtor.debtor_type === 'business' &&
                                <div>ABN: {formatAbn(debtor.abn)} | {debtor.industry_description} <br/>
                                    Listing ID {listing.reference}</div>}
                            {debtor.debtor_type === 'individual' &&
                                <div>Individual | Listing ID {listing.reference}</div>}

                            {debtId &&
                                <div>Part of Listing <a
                                    href={`/listings/${listingId}`}>{listing.reference}</a>
                                </div>}
                        </React.Fragment>}

                        {/* Multiple debtors */}
                        {!debtor && <React.Fragment>
                            <h1 style={{"marginBottom": "15px"}}>{listing.debtor_names_abbr || "Listing"}</h1>
                            <div>{listing.number_of_debts} Debts</div>
                        </React.Fragment>}

                    </div>

                    <div className="listingBody">

                        {listing.status === 'resolved' &&
                            <InfoContainer type="check" iconClass="fa fa-check-circle-o">
                                This debt listing has been resolved.
                            </InfoContainer>}

                        <OfferSummary offer={listing.accepted_offer}/>

                        <Tabs defaultActiveKey={defaultActiveTab} id="listing-detail-tabs">
                            <Tab eventKey="listing" title="Listing">
                                <ListingDetailBody listing={listing} debt={debt} viewer="placer"/>
                            </Tab>

                            {!debtId &&
                                <Tab eventKey="offers" title="Offers">
                                    <Offers offers={offers}/>
                                </Tab>}

                            {debtor &&
                                <Tab eventKey="track-progress" title="Track Progress"
                                     disabled={defaultActiveTab !== 'track-progress'}>
                                        <ProgressTrackerVendor listing={listing} debt={debt} />
                                </Tab>}
                        </Tabs>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12">
                    {media === "mobile" ? <ListingSummary listing={listing} viewer="placer"
                                                          reload={loadData}/>
                        :
                        <Sticky topOffset={80} disableCompensation>{({style}) => {
                            let newStyle = {...style}
                            newStyle.top = 10
                            return <div style={newStyle}>
                                <ListingSummary listing={listing} viewer="placer"
                                                reload={loadData}/></div>
                        }
                        }
                        </Sticky>}
                </div>
            </div>
        </StickyContainer>
    </div>;

    return (
        <>
            <Media at='mobile'>
                {details('mobile')}
            </Media>
            <Media at='tablet'>
                {details('tablet')}
            </Media>
            <Media greaterThan='tablet'>
                {details("others")}
            </Media>
        </>
    )

}
