import React from "react";
import _ from "lodash";

const TextArea = ({ name, label, placeholder, error, className, ...rest }) => {
    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <textarea {...rest} name={name} id={name} placeholder={placeholder} className={"form-control " + className} />
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

function renderTextArea(data, errors, onChange, path, label, placeholder, className="", rows=5) {

    return (
        <TextArea
            name={path}
            placeholder={placeholder}
            value={_.get(data, path) || ""}
            label={label}
            onChange={onChange}
            error={errors[path]}
            className={className}
            rows={rows}
        />
    );
}

export {
    TextArea,
    renderTextArea
}
