import React from "react";
import VendorProfile from "./VendorProfile";
import CollectionPartnerProfile from "./CollectionPartnerProfile";
import {useAccess} from "../../contexts/AccessContext";


export default function ManageProfile (props) {
    const {isPlacer, isCollectionPartner} = useAccess()

    if (isPlacer()) return <VendorProfile {...props}></VendorProfile>;
    if (isCollectionPartner()) return <CollectionPartnerProfile  {...props}></CollectionPartnerProfile>;

    return <></>
}

