import React from "react";
import CollectionPartnerDashboard from "./CollectionPartnerDashboard";
import AdminDashboard from "../admin/AdminDashboard";
import {toast} from "react-toastify";
import {Redirect} from "react-router-dom";
import {useAccess} from "../../contexts/AccessContext";


export default function Dashboard(props) {

    const {isPlacer, isCollectionPartner, isAdmin} = useAccess()

    if (props.location.state?.error_message) {
        toast.error(props.location.state.error_message);
    }
    if (props.location.state?.success_message) {
        toast.success(props.location.state.success_message, {autoClose: 3000});
    }

    if (isPlacer()) return <Redirect to={{pathname: "/listings"}}/>
    if (isCollectionPartner()) return <CollectionPartnerDashboard {...props}/>;
    if (isAdmin()) return <AdminDashboard {...props}/>;

    return <></>
}

