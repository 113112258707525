import React from "react";
import VendorListingDetail from "./VendorListingDetail";
import CollectionPartnerListingDetail from "./CollectionPartnerListingDetail";
import {toast} from "react-toastify";
import {useAccess} from "../../contexts/AccessContext";

export default function ListingDetail (props) {

    const {isPlacer, isCollectionPartner} = useAccess()

    if (props.location.state?.error_message) {
        toast.error(props.location.state.error_message);
    }
    if (props.location.state?.success_message) {
        toast.success(props.location.state.success_message, {autoClose: 3000});
    }

    // const listingId = props.match.params.id;
    const secret = props.match.params.secret;

    if (isPlacer()) return <VendorListingDetail {...props}></VendorListingDetail>;
    if (isCollectionPartner() || secret) return <CollectionPartnerListingDetail secret={secret} {...props}></CollectionPartnerListingDetail>;
    
    return <></>
}

