import React from "react";

class SubscriptionSinglePlan extends React.Component {

    render() {
        const {options, selectedPlan} = this.props;
        const selectedPlanDetails = options.find(({chargebee_id}) => chargebee_id === selectedPlan);

        return (

            <React.Fragment>

                <div className="row">

                    <div className="col-8 subscriptionInfo form-group">

                        <h3>Account Fees</h3>

                        <p>
                            Debtplacer provides collection agencies with integrated solutions
                            that drive business efficiencies and enhance the collection experience
                            for both agency and client.
                        </p>

                        <p>
                            Our flexible pricing structure is designed to grow with your business needs.
                        </p>

                        <h6>Ideal for:</h6>
                        <ul className="checks">
                            <li>Onboarding new clients and setting up new profiles</li>
                            <li>Creating new cases, validating debtor details and collating supporting documents</li>
                            <li>Providing collection updates to clients on the status of their debt, including
                                payments received by the collection agency
                            </li>
                        </ul>

                        <h5 className="mt-5">Benefits</h5>

                        <div className="subscriptionBenefits">
                            <ul className="checks vertical">
                                <li>Appointment to Act Integration</li>
                                <li>DebtCol Integration</li>
                                <li>Advanced Support</li>
                            </ul>
                        </div>
                    </div>

                    {selectedPlanDetails && <div className="col-4 py-5 subscribeButtonContainer text-center">
                        <span className="price-info">Starting from</span><br/>
                        <span className="price">${selectedPlanDetails.price_per_month} / month <br/>
                                               <span className="gst">(Excl. GST)</span>
                                            </span>
                        <br/>
                        <button
                            className="d-inline-block btn btn-primary nextButton my-4"
                            type="submit">
                            Pay & Complete
                        </button>
                        <p><strong><a href="https://debtplacer.com/collection-partners/" target="_blank">Learn More</a></strong></p>
                    </div>}

                </div>
            </React.Fragment>
        );
    }
}

export default SubscriptionSinglePlan;
