import React from "react";

import '../../forms.scss';
import http from "../../services/httpService";
import config from "../../constants";

const apiPath = "/saved_searches/";

class SavedSearchRedirect extends React.Component {

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        const savedSearchId = this.props.match.params.id;
        const resp = await http.get(config.API_URL + apiPath + savedSearchId + "/");
        const savedSearch = resp.data;

        // redirect to search results
        window.location = "/results?" + savedSearch.params;

    }

    render() {
        return <div></div>;
    }
}

export default SavedSearchRedirect;
