import React, {Component} from "react";
import {Link} from 'react-router-dom';
import {formatCurrency, formatPercentage} from "../common/numberFormats";
import {listedDate} from "../common/dateFormats";
import {overdueDisplay} from "../../utils/listingRenderers";
import MakeOffer from "../common/makeOffer";
import OfferFees from "../common/OfferFees";
import config from "../../constants";
import CreateCaseFromListing from "../debtcol/CreateCaseFromListing";

class ListingSummary extends Component {

    render() {

        const {listing, debt, viewer, reload, loggedInUser} = this.props;
        const offer = listing.collection_partner_status || listing.collection_partner_status === null ? listing.own_offer : listing.accepted_offer;
        const dateListed = listedDate(listing.published_date);
        const overdue = overdueDisplay(listing);

        return (
            <div className="debtInfo">
                <div className="debtInfoHeader">
                    Total Debt <br/>
                    <span className="totalValue">{formatCurrency(listing.total_value)}</span>
                </div>

                <div className="debtInfoDetails">
                    <h2>Summary</h2>

                    {overdue &&
                    <div className="summaryContainer">
                        <div className="summaryContainerIcon"><i className="calendar"/></div>
                        <div className="summaryContainerContent">
                            <strong>{overdue}</strong><br/>
                            Overdue
                        </div>
                    </div>}

                    {dateListed &&
                    <div className="summaryContainer">
                        <div className="summaryContainerIcon"><i className="clock_circle"/></div>
                        <div className="summaryContainerContent">
                            <strong>{dateListed}</strong><br/>
                            Debt listed
                        </div>
                    </div>}
                    {listing.is_personally_guaranteed &&
                    <div className="summaryContainer">
                        <div className="summaryContainerIcon"><i className="tick_square"/></div>
                        <div className="summaryContainerContent">
                            <strong>Personally guaranteed</strong>
                        </div>
                    </div>}
                    <br/>
                    {viewer === "collection_partner" && listing.own_offer.id &&
                    <React.Fragment>
                        <h2>Your Offer</h2>
                        <div className="summaryContainer">
                            <div className="summaryContainerIcon">
                                {offer.status === 'accepted' && <i className="tick_circle"/>}
                                {offer.status !== 'accepted' && <i className="tick_circle"/>}
                            </div>
                            <div className="summaryContainerContent">
                                <strong><OfferFees offer={offer}/></strong><br/>
                                {offer.status_display}
                            </div>
                        </div>
                    </React.Fragment>
                    }

                    {viewer === "collection_partner" && listing.own_offer.id &&
                    <Link
                        to={listing.chats[0] ? "/messenger/" + listing.chats[0] : "/messenger/?listing=" + listing.id}>
                        <button className="btn btn-primary btn-block messageButton">
                            Message Placer
                            <i className="envelope-white-button"/></button>
                    </Link>}

                    {viewer === "collection_partner" && ['offer_accepted', 'resolved'].includes(listing.status) && loggedInUser &&
                    <a href={`${config.API_URL}/collection_partner_listing_download/${listing.id}/download/?secret=${listing.secret}`} target="_blank">
                        <button className="btn btn-secondary btn-block mt-2">
                            Download Listing Details
                            <i className="download-button" />
                        </button>
                    </a>}

                    {viewer === "collection_partner" && ['offer_accepted', 'resolved'].includes(listing.status) && loggedInUser &&
                        <CreateCaseFromListing listing={listing} debt={debt} />}

                    {viewer === "placer" && (listing.status === 'offer_accepted' || listing.status === 'resolved') &&
                    <Link
                        to={listing.chats[0] ? "/messenger/" + listing.chats[0] : "/messenger/?listing=" + listing.id}>
                        <button className="btn btn-primary btn-block messageButton">Message Collection Partner</button>
                    </Link>}
                    {viewer === "collection_partner" && !listing.own_offer.id && loggedInUser &&
                    <MakeOffer reload={reload} listing={listing}/>}
                </div>
            </div>
        );
    }
}

export default ListingSummary;
