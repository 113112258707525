import React from "react";
import {Button, Modal} from 'react-bootstrap'

import http from "../../services/httpService";
import config from "../../constants";
import {BetterSelect} from "./forms/BetterSelect";
import {Alert} from "react-bootstrap"
import {formatAbn} from "./numberFormats";

class InviteListing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            invitation: {collection_partner: this.props.collectionPartner.id},
            success: null,
            error: null,
            listings: []
        }
    }

    async componentDidMount() {
        const {data: listings} = await http.get(config.API_URL + "/placer_listings/?status=listed");
        this.setState({listings});
    };

    handleClose = () => {
        this.setState({show: false});
    }
    handleShow = () => {
        this.setState({show: true});
    }

    handleChange = ({currentTarget: {name, value}}) => {
        let invitation = this.state.invitation
        invitation[name] = value
        this.setState({invitation, error: null})
    }
    handleInvite = async () => {
        let invitation = this.state.invitation
        let error = []
        if (!invitation.listing) {
            error.push("Please choose a listing to invite this Collection Partner to")
        }
        if (error.length > 0) {
            this.setState({error})
        } else {
            try {
                let response = await http.post(config.API_URL + '/listing_invites/', invitation);
                this.setState({
                    show: false,
                    success: "Successfully invited " + this.props.collectionPartner.business_name + " to Listing "
                })
                this.props.reload("Successfully Invited " + this.props.collectionPartner.business_name + " to Listing ")
            } catch (e) {
                Object.keys(e.data).map((key) => {
                    error = error.concat(e.data[key])
                })
                this.setState({error})
            }
        }
    }

    render() {
        let invitation = this.state.invitation
        let listings = this.state.listings

        var formatter = new Intl.NumberFormat('en-AU', {
            style: 'currency',
            currency: 'AUD',
        });
        return (
            <>
            <button
                className="btn btn-primary pull-right"
                id="inviteOpenModal"
                onClick={this.handleShow}
            >Invite to Listing
            </button>
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header closeButton className={'padding-30'}>
                    <Modal.Title className={'modalTitle'}>Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'padding-30'}>
                    {this.state.error ?.map((err, idx) => <Alert key={idx} variant='danger'>{err}</Alert>)}
                    <p>Invite {this.props.collectionPartner.business_name} to view and make an offer to your listing.</p>
                    <BetterSelect
                        data={invitation}
                        name="listing"
                        label="Select Listing"
                        options={listings.map(listing => {
                            return {
                                id: listing.id,
                                name: <>
                                    {listing.debtor_names_abbr &&
                                        <React.Fragment>
                                            {listing.debtor_names_abbr} {formatter.format(listing.total_value)}
                                        </React.Fragment>}
                                    </>
                            }
                        })}
                        valueProperty="id"
                        textProperty="name"
                        onChange={this.handleChange}
                        className="select-listing"
                        emptyLabel="Select Listing"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-block" variant="primary"
                            id="sendInvite"
                            onClick={this.handleInvite}>
                        Invite to Listing
                    </Button>
                </Modal.Footer>
            </Modal>
            </>
        );
    }

};

export default InviteListing;
