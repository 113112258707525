import React, {Component} from "react";
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {formatCurrency} from "../common/numberFormats";
import http from "../../services/httpService";
import config from "../../constants";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

class ListingBarChart extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const data = {
            datasets: [{
                label: 'Debts',
                data: this.props.listing.debt_summary,
                parsing: {
                    xAxisKey: 'days_overdue',
                    yAxisKey: 'number_of_debts'
                },
                datalabels: {
                    formatter: function(value, context) {
                        if (value.number_of_debts > 0) return `${formatCurrency(value.amount)} \n ${value.number_of_debts} Debt(s)`
                        return null
                    },
                    labels: {
                        amount: {
                            color: '#212322'
                        }
                    },
                    align: 'end'
                },
                backgroundColor: '#26D07C',
                hoverBackgroundColor: '#1ea562'
            }
            ]
        };

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            },
            scales: {
                yAxes: {
                    beginAtZero: true,
                    offset: false,
                    ticks: {
                        precision: 0
                    },
                    grid: {
                        display: false
                    }
                },
                xAxes: {
                    grid: {
                        display: false
                    }
                }
            }
        };

        return <Bar options={options} data={data} />;

    }
}

export default ListingBarChart;
