import React from "react";
import http from "../../services/httpService";
import config from "../../constants";

const apiPath = "/saved_searches/";


class SavedSearches extends React.Component {

    state = {
        savedSearches: []
    }

    async componentDidMount() {
        await this.loadData();
    };

    async loadData () {
        try {
            const { data: savedSearches } = await http.get(config.API_URL + apiPath);
            this.setState({ savedSearches });
        } catch (ex) {
        }
    }

    handleDelete = async (id) => {
        await http.delete(config.API_URL + apiPath + id + "/");
        let { savedSearches } = this.state;
        savedSearches = savedSearches.filter(s => s.id !== id);
        this.setState({ savedSearches });
    }

    render() {

        const { savedSearches } = this.state;
        if (savedSearches.length === 0) return "";

        return (
            <div className="">
                <h2>Saved Searches</h2>

                {savedSearches.map(s => (
                    <div className="savedSearch" key={s.id}>

                        <div className="pull-right">
                            <a onClick={() => this.handleDelete(s.id)}><i className="fa fa-trash"></i></a>
                        </div>

                        <h3><a href={"/results/?" + s.params}>{s.title}</a></h3>

                        { s.email_notifications && <div><i className="fa fa-check"/> Email Notifications</div>}
                        { s.sms_notifications && <div><i className="fa fa-check"/> SMS Notifications</div>}

                    </div>
                ))}

            </div>

        );
    }
}


export default SavedSearches;
