import React, {useEffect, useState} from 'react'
import {disconnect} from "../../services/intuitService";
import {Link} from "react-router-dom";


export default function IntuitDisconnect() {

    const [loading, setLoading] = useState(true)
    const [disconnected, setDisconnected] = useState(false)

    useEffect(() => {
        disconnect().then((res) => {
            if (res.status === 200) setDisconnected(true)
            setLoading(false)
        }).catch(error => {
            console.error('Error disconnecting QuickBooks:', error);
            setLoading(false)
        })

    }, []);

    return (
        <div className="container container-wide">
            {disconnected &&
                <div>
                    <p>You've successfully disconnected your QuickBooks account from Debtplacer.</p>
                    <p>You will no longer be able to import unpaid invoices directly into Debtplacer from QuickBooks.</p>
                    <p>If you’d like to re-connect Debtplacer and your QuickBooks account, <Link to={`/listings/new`}>click here to create a new listing and connect
                        your QuickBooks account</Link>.
                    </p>

                </div>}
            {!disconnected && loading && <p>Disconnecting your QuickBooks account from Debtplacer...</p>}
            {!disconnected && !loading && <p>An error occured while disconnecting your QuickBooks account from Debtplacer. Please try again or contact the Debtplacer team.</p>}
        </div>
    )
}
