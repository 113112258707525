import React from "react";
import {Link, Redirect} from "react-router-dom";
import auth from "../../services/authService";
import {toast} from "react-toastify";
import {baseHandleChange} from "../common/forms/helpers";
import {renderInput} from "../common/forms/Input";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

const Joi = require('joi');

class Login extends React.Component {
    state = {
        data: {username: "", password: ""},
        errors: {},
        // form logic & config
        formOptions: {
            nextButtonLabel: "Login",
            submitButtonLabel: "Login",
            previousButtonLabel: "Back"
        },
        token: null // ReCaptcha token
    };

    schema = {
        1: {
            username: Joi.string()
                .required()
                .label("Username"),
            password: Joi.string()
                .required()
                .label("Password")
        }
    };

    handleChange = ({currentTarget: input}) => {
        const {data, errors} = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({data, errors});
    };

    handleSubmit = async (e) => {
        if (e) e.preventDefault();
        return await this.doSubmit();
    };

    doSubmit = async () => {
        if (!this.state.token) return false;
        try {
            const {data} = this.state;
            await auth.login(data.username, data.password);
            const {state} = this.props.location;

            if (state && state.hasOwnProperty("from")) {
                window.location = state.from.pathname + state.from.search;
            } else {
                window.location = '/';
            }
        } catch (ex) {
            // console.log(ex);
            if (ex.response && ex.response.status === 401) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Invalid email address or password.")
            }
        }
    };

    onVerify = (token) => {
        this.setState({token}) // re-captcha verification
    };

    render() {

        if (auth.getCurrentUser()) {
            if (this.props.location?.from?.pathname.startsWith('/embedded/')) {
                return <Redirect to={this.props.location.from.pathname}/>
            }
            if (this.props.location?.state?.from?.pathname.startsWith('/embedded/')) {
                return <Redirect to={this.props.location.state.from.pathname + this.props.location.state.from.search}/>
            }
            return <Redirect to="/"/>
        }

        // if (!this.props.embedded && auth.getCurrentUser()) return <Redirect to="/"/>;
        return (
            <div className="login-outer">
                <div className="container login-inner">
                    <h1>Login</h1>
                    <br/>
                    <form onSubmit={this.handleSubmit}>
                        {renderInput(this.state.data, this.state.errors, this.handleChange, "username", "Username")}
                        <br/>
                        {renderInput(this.state.data, this.state.errors, this.handleChange, "password", "Password", "", "password")}
                        <br/>
                        <button className="btn btn-primary float-right" type="submit">
                            Login
                        </button>

                    </form>
                    <p><Link to={`/forgot`}> Forgot password? </Link></p>
                    <GoogleReCaptcha onVerify={this.onVerify} />
                </div>
            </div>
        );
    }
}

export default Login;
