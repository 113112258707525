import React, {Component} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {Input} from "../common/forms/Input";
import {Spinner, ListGroup, InputGroup, FormControl, Button, Row, Col} from "react-bootstrap"
import ChatItem from './ChatItem'
import ChatOffer from './ChatOffer'
import ChatTitle from './ChatTitle'
import ChatFeedItem from "./ChatFeedItem";
import {StickyContainer, Sticky} from 'react-sticky';
import auth from "../../services/authService";
import {getURLParamString} from "../../utils/search";

import {Media} from '../../AppMedia'
import {BetterSelect} from "../common/forms/BetterSelect";

let readTimeout = {}
let refreshInterval = {}

class ChatList extends Component {

    state = {
        loading: true,
        chats: [],
        chat: null,
        reply: {},
        feeds: [],
        user: {},
        showChatFeed: false,
        rejectingOffer: false
    };

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {
        const paramsStr = this.props.location.search
        const urlParams = new URLSearchParams(paramsStr);
        let listingId = urlParams.get("listing")
        let offerId = urlParams.get("offer")

        const user = await auth.getCurrentUser();
        const {data: chats} = await http.get(config.API_URL + "/chats/");

        let state = {
            rejectingOffer: false,
            user,
            loading: false,
            listings: chats.map(chat => {
                return {value: chat.listing.id, text: "Listing ID " + chat.listing.reference + " - " + chat.listing.debtor_names}
            }).filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i),
        }
        if (chats) {
            let filterChats = chats
            if (listingId) {
                filterChats = chats.filter(chat => chat.listing.id === Number(listingId))
            }
            filterChats = this.props.currentAccessType.type === 'collection_partner' ? filterChats.sort((a, b) => {
                    return a.is_read_by_collection_partner - b.is_read_by_collection_partner
                }) :
                filterChats.sort((a, b) => {
                    return a.is_read_by_placer - b.is_read_by_placer
                });
            state.chats = filterChats
        }
        let chatId = this.props.match.params.id


        if (chatId) {
            state.chatId = Number(chatId)
            let chat = chats.find(chatItem => Number(chatId) === chatItem.id)
            if (chat) {
                const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
                state.chat = chat;
                state.feeds = feeds.reverse()
            }
        } else if (listingId) {
            state.listingId = Number(listingId)
            let chat = chats.find(chatItem => Number(listingId) === chatItem.listing.id)
            if (chat) {
                const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
                state.chat = chat;
                state.feeds = feeds.reverse()
            }
        } else if (offerId) {
            state.offerId = Number(offerId)
            let chat = chats.find(chatItem => Number(offerId) === chatItem.offer.id)
            if (chat) {
                const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
                state.chat = chat;
                state.feeds = feeds.reverse()
            }
        }
        await this.reloadData();

        this.setState(state);
    }

    isIncludedInSearch = (chat, searchQuery) => {
        // add more search lookups here when needed
        return (
            chat.listing.debtor_names?.toLowerCase().includes(searchQuery.toLowerCase())
        || chat.collection_partner?.business_name?.toLowerCase().includes(searchQuery.toLowerCase())
        || chat.placer ?.toLowerCase().includes(searchQuery.toLowerCase())
    )
    }

    handleChange = ({currentTarget: {name, value}}) => {
        let reply = this.state.reply
        reply[name] = value
        this.setState({reply, error: null})
    }
    sendReply = async () => {
        let chat = this.state.chat
        let reply = this.state.reply
        await http.post(config.API_URL + "/chats/" + chat.id + "/reply/", reply);
        const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
        this.setState({feeds: feeds.reverse(), reply: {message: ""}})
    }
    handleSearch = ({target: {value}}) => {
        this.setState({searchQuery: value, selectedStatus: null, currentPage: 1});
    };
    showChatList = () => {
        this.setState({showChatFeed: false})
    }

    selectChat(chat) {
        return async () => {
            this.setState({chat, loading: true, showChatFeed: true, rejectingOffer: false})
            const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
            this.setState({feeds: feeds.reverse(), loading: false})
            if ((this.props.currentAccessType.type === 'collection_partner' && !chat.is_read_by_collection_partner) ||
                (this.props.currentAccessType.type === 'placer' && !chat.is_read_by_placer )) {
                clearTimeout(readTimeout)
                readTimeout = setTimeout(async () => {
                    await http.post(config.API_URL + "/chats/" + chat.id + "/read/");
                    this.reloadData(chat)
                }, 3000)
            } else {
                this.reloadData(chat)
            }
            history.pushState({}, null, "/messenger/" + chat.id);
        }
    }

    sortChats(chats) {
        return this.props.currentAccessType.type === 'collection_partner' ? chats.sort((a, b) => {
                return a.is_read_by_collection_partner - b.is_read_by_collection_partner
            }) :
            chats.sort((a, b) => {
                return a.is_read_by_placer - b.is_read_by_placer
            });
    }

    rejectOffer = async (rejectingOffer) => {
        this.myInp.focus()
        this.setState({
            rejectingOffer
        })
    }

    withdrawOffer = async () => {
        let chat = this.state.chat
        await http.post(config.API_URL + "/offers/" + chat.offer.id + "/withdraw/");
        await this.refresh(chat);
    }

    acceptOffer = async () => {
        let chat = this.state.chat
        await http.post(config.API_URL + "/offers/" + chat.offer.id + "/accept/");
        await this.refresh(chat);
    }

    async reloadData(currentchat) {
        clearInterval(refreshInterval)
        refreshInterval = setInterval(async (chat) => {
            let newChat = await this.refresh(chat);

            if ((this.props.currentAccessType.type === 'collection_partner' && newChat && !newChat.is_read_by_collection_partner) ||
                (this.props.currentAccessType.type === 'placer' && newChat && !newChat.is_read_by_placer )) {
                clearTimeout(readTimeout)
                readTimeout = setTimeout(async () => {
                    await http.post(config.API_URL + "/chats/" + newChat.id + "/read/");
                    this.refresh(chat)
                }, 2000)
            }

        }, 20000, currentchat)
    }

    async refresh(chat) {
        let chatId = chat ?.id || this.state.chatId
        const {data: chats} = await http.get(config.API_URL + "/chats/");

        let filterChats = chats
        if (this.state.listingId) {
            filterChats = filterChats.filter(chat => chat.listing.id === Number(this.state.listingId))
        }
        let state = {
            listings: chats.map(chat => {
                return {value: chat.listing.id, text: chat.listing.id + " - " + chat.listing.debtor_names}
            }).filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i),
            chats: this.sortChats(filterChats)
        }
        if (chatId) {
            chat = chats.find(chatItem => Number(chatId) === chatItem.id)
            const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
            state.chat = chat
            state.feeds = feeds.reverse()
        } else if (this.state.listingId) {
            chat = chats.find(chatItem => Number(this.state.listingId) === chatItem.listing.id)
            const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
            state.feeds = feeds.reverse()
        } else if (this.state.offerId) {
            chat = chats.find(chatItem => Number(this.state.offerId) === chatItem.offer.id)
            const {data: feeds} = await http.get(config.API_URL + "/chats/" + chat.id + "/feed/");
            state.chat = chat
            state.feeds = feeds.reverse()
        }
        this.setState(state);
        return chat
    }

    componentWillUnmount() {
        clearInterval(refreshInterval)
    }

    handleSubmit = async (e) => {
        if (e) e.preventDefault();
        this.setState({loading: true, chat: null, chatId: null}, async () => {
            // load new result based on state search params
            let listing = this.state.listingId
            const urlParams = getURLParamString({listing});
            await this.refresh();
            history.pushState({}, null, "/messenger?" + urlParams);
        });
    };

    render() {
        const {listingId, loading, chats, chat, feeds, user, searchQuery, rejectingOffer} = this.state;

        let chatSide = (media) => {
            let className = "col-sm-12 col-md-2 col-lg-3 bordered"
            if (media === 'tablet') {
                className = "col-sm-12 col-md-2 col-lg-2 bordered"
            }
            return <div className={className}>
                <div className="searchFormHeader">
                    <h4>Messages</h4>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <form className="sidebarChatList">
                            <div className="form-row">
                                <Input
                                    type="text"
                                    name="keywords"
                                    value={searchQuery || ""}
                                    label="Search"
                                    onChange={this.handleSearch}
                                    placeholder="Search by debtor or client name"
                                    iconClass="fa fa-search"
                                    labelClass="sr-only"
                                />
                                <BetterSelect
                                    data={this.state}
                                    name="listingId"
                                    options={this.state.listings || []}
                                    valueProperty="value"
                                    textProperty="text"
                                    onChange={({currentTarget: input}) => {
                                        let state = this.state;
                                        state.listingId = input.value
                                        this.setState(state)
                                    }}
                                    emptyLabel="Listing"
                                />
                                <button
                                    className="btn btn-outline-primary btn-block searchButton"
                                    type="button" onClick={this.handleSubmit}>
                                    Search
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-12 padding-5">
                        <ListGroup variant="flush" className={'chat-items'}>
                            {searchQuery ? chats.filter(m => this.isIncludedInSearch(m, searchQuery)).map((chatItem, index) => {
                                return <ChatItem media={media}
                                                 key={index} chatItem={chatItem} chat={chat}
                                                 onClick={this.selectChat(chatItem).bind(this)}
                                                 user={user}/>
                            }) : chats.map((chatItem, index) => {
                                return <ChatItem media={media}
                                                 key={index} chatItem={chatItem} chat={chat}
                                                 onClick={this.selectChat(chatItem).bind(this)}
                                                 user={user}/>
                            })}
                        </ListGroup>
                    </div>
                </div>
            </div>;
        };
        let chatFeed = (media) => {
            let className = "col-sm-12 col-md-10 col-lg-9 chat-searchResults"
            if (media === 'tablet') {
                className = "col-sm-12 col-md-10 col-lg-10 chat-searchResults"
            }
            return <div className={className}>
                <Sticky topOffset={0}>{({style}) => {
                    return <div style={style} className="chat-header">
                        <Row>
                            {media === "mobile" ? <Col sm={1}>
                                <i style={{cursor: "pointer", display: "inline-block", marginRight: 20}}
                                   className="chevron flip"
                                   aria-hidden="true" onClick={this.showChatList}/>
                            </Col> : null}
                            <Col sm={media === "mobile" ? 11 : 12}>
                                <ChatTitle chat={chat} user={user} accessType={this.props.currentAccessType} />
                            </Col>
                        </Row>
                    </div>
                }
                }</Sticky>
                {loading ?
                    <div className="chat-feeds"><Spinner className="spinner" animation="border" role="status"/></div> :
                    <ul className="list-unstyled chat-feeds">
                        {!!chat ?
                            <ChatOffer
                                chat={chat}
                                user={user}
                                accessType={this.props.currentAccessType}
                                rejectOffer={this.rejectOffer}
                                withdrawOffer={this.withdrawOffer}
                                acceptOffer={this.acceptOffer}
                                reload={() => this.refresh(this.state.chat)}/> : null}
                        {feeds.map((feed, index) => {
                            return <ChatFeedItem
                                accessType={this.props.currentAccessType}
                                key={index}
                                feed={feed}
                                chat={chat}
                                user={user}/>
                        })}
                    </ul>
                }
                {rejectingOffer? <span className={"text-blurb"}>Message the Collection Partner with any questions or concerns you have regarding their fee proposal:</span> : null}

                <div className="chat-feed-reply">
                    <div className="row">
                        <div className="col-sm-12 padding-5">
                            <InputGroup>
                                <FormControl ref={(ip) => this.myInp = ip}
                                             as="textarea"
                                             type="text"
                                             name="message"
                                             placeholder="Type reply..."
                                             value={this.state.reply.message}
                                             label="Type reply"
                                             onChange={this.handleChange}/>
                                &nbsp;&nbsp;
                                <Button variant="primary" onClick={this.sendReply}>
                                    Send
                                </Button>
                            </InputGroup>
                        </div>
                    </div>
                </div>
            </div>;
        };
        let chatList = (media) => {
            return media === 'mobile' ?
                <div className="container container-wide  pt-0">
                    <StickyContainer>
                        <div className="row">
                            {this.state.showChatFeed ? null : chatSide(media)}
                            {this.state.showChatFeed ? chatFeed(media) : null}
                        </div>
                    </StickyContainer>
                </div> : (
                    <div className="container container-wide  pt-0">
                        <StickyContainer>
                            <div className="row">
                                {chatSide(media)}
                                {chatFeed()}
                            </div>
                        </StickyContainer>
                    </div>
                );
        }

        return <>
        <Media at='mobile'>
            {chatList('mobile')}
        </Media>
        <Media at='tablet'>
            {chatList('tablet')}
        </Media>
        <Media greaterThan='tablet'>
            {chatList("others")}
        </Media></>
    }
}

export default ChatList;
