import React from "react";
import {Button, Modal, Form, Alert} from 'react-bootstrap'
import CurrencyInput from './forms/currencyInput'
import {Link} from "react-router-dom";

import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {Radio} from "./forms/radioGroup";
import auth from "../../services/authService";

class MakeOffer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            offer: {listing: this.props.listing.id, offer_type: 'commission'},
            success: null,
            error: null,
            userStatus: {}
        }
    }

    handleClose = () => {
        this.setState({show: false});
    }

    handleShow = async () => {
        // reload status here to make sure sub can still make an offer
        const userStatus = await auth.getUserStatus();
        this.setState({show: true, userStatus});
    }

    handleChange = ({target: {name, value}}) => {
        let offer = this.state.offer
        offer[name] = value
        this.setState({offer, error: null})
    }

    handleAmountChange = ({target: {name, value}}) => {
        let offer = this.state.offer
        offer[name] = value.replace(/[^\d.-]/g, '')
        this.setState({offer, error: null})
    }

    handleOffer = async () => {
        let offer = this.state.offer
        let error = []
        if (offer.offer_type === "commission" && !offer.commission_percentage) {
            error.push("Commission is required")
        }
        if (offer.offer_type === "fixed_fee" && !offer.fixed_fee_amount) {
            error.push("Fixed Fee Amount is required")
        }
        if (offer.offer_type === "fee_estimate" && !(offer.fee_estimate_from && offer.fee_estimate_to && offer.disbursements_estimate_from && offer.disbursements_estimate_to)) {
            error.push("Fee Estimate is required")
        }
        if (!offer.included_in_scope) {
            error.push("Included in Scope is required")
        }
        if (!offer.excluded_from_scope) {
            error.push("Excluded from Scope is required")
        }
        if (error.length > 0) {
            this.setState({error})
        } else {
            try {
                let response = await http.post(config.API_URL + '/offers/', offer);
                this.setState({show: false})
                this.props.reload()
            } catch (e) {
                Object.keys(e.data).map((key) => {
                    error = error.concat(e.data[key])
                })
                this.setState({error})
            }
        }
    }

    render() {

        const {offer, userStatus, show, error, success} = this.state

        //let offer = this.state.offer

        if (error) {
            toast.error(error);
        }
        if (success) {
            toast.success(success, {autoClose: 3000});
        }
        return (
            <>
                <button
                    className="btn btn-primary btn-block offerButton"
                    onClick={this.handleShow}
                >Make an Offer
                </button>
                <Modal show={show} onHide={this.handleClose}>
                    <Modal.Header closeButton className={'padding-30'}>
                        <Modal.Title className={'modalTitle'}>Make an offer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={'padding-30'}>

                        {!userStatus.terms_completed &&
                        <Alert variant='danger'>Please add your terms and conditions to your profile before making an offer - <Link to={`/profile?edit=terms`}>Add Terms and Conditions</Link> </Alert>}
                        {/*{userStatus.bids_left < 0 &&*/}
                        {/*<Alert variant='warning'>You have used up all your bids for this time period. Upgrade your plan*/}
                        {/*    to receive more bids. </Alert>}*/}

                        Offer Type *
                        <br/>
                        <br/>
                        <Radio name="offer_type"
                               value="commission"
                               checked={offer.offer_type === "commission"}
                               onChange={this.handleChange}
                               label={"Commission only"}
                        />
                        <Radio name="offer_type"
                               value="fixed_fee"
                               checked={offer.offer_type === "fixed_fee"}
                               onChange={this.handleChange}
                               label={"Fixed Fee only"}
                        />
                        <Radio name="offer_type"
                               value="fee_estimate"
                               checked={offer.offer_type === "fee_estimate"}
                               onChange={this.handleChange}
                               label={"Fee estimate only"}
                        />
                        <br/>
                        {offer.offer_type === "commission" ?
                            <Form.Group className="mb-3">
                                <Form.Label>Commission *</Form.Label>
                                <CurrencyInput maskOptions={{suffix: ' %', prefix:''}}
                                               className={"form-control mb-2"}
                                               placeholder="0.00 %"
                                               type="text"
                                               name="commission_percentage"
                                               value={offer.commission_percentage}
                                               onChange={this.handleAmountChange}/>
                            </Form.Group> : null}
                        {offer.offer_type === "fixed_fee" ?
                            <Form.Group className="mb-3">
                                <Form.Label>Fixed fee *</Form.Label>
                                <CurrencyInput className={"form-control mb-2"}
                                               placeholder="$ 0.00"
                                               type="text"
                                               name="fixed_fee_amount"
                                               value={offer.fixed_fee_amount}
                                               onChange={this.handleAmountChange}/>
                            </Form.Group> : null}
                        {offer.offer_type === "fee_estimate" ?

                            <Form.Group className="mb-3">
                                <Form.Label>Fee Estimate</Form.Label>
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Label>From *</Form.Label>
                                        <CurrencyInput className={"form-control mb-2"}
                                                       placeholder="$ 0.00"
                                                       type="text"
                                                       name="fee_estimate_from"
                                                       value={offer.fee_estimate_from}
                                                       onChange={this.handleAmountChange}/>
                                    </div>
                                    <div className="col-6">
                                        <Form.Label>To *</Form.Label>
                                        <CurrencyInput className={"form-control mb-2"}
                                                       placeholder="$ 0.00"
                                                       type="text"
                                                       name="fee_estimate_to"
                                                       value={offer.fee_estimate_to}
                                                       onChange={this.handleAmountChange}/>
                                    </div>
                                </div>

                                <Form.Label>Disbursements & Outlays Estimate</Form.Label>
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Label>From *</Form.Label>
                                        <CurrencyInput className={"form-control mb-2"}
                                                       placeholder="$ 0.00"
                                                       type="text"
                                                       name="disbursements_estimate_from"
                                                       value={offer.disbursements_estimate_from}
                                                       onChange={this.handleAmountChange}/>
                                    </div>
                                    <div className="col-6">
                                        <Form.Label>To *</Form.Label>
                                        <CurrencyInput className={"form-control mb-2"}
                                                       placeholder="$ 0.00"
                                                       type="text"
                                                       name="disbursements_estimate_to"
                                                       value={offer.disbursements_estimate_to}
                                                       onChange={this.handleAmountChange}/>
                                    </div>
                                </div>
                            </Form.Group> : null}

                        <h5>Scope</h5>
                        <Form.Group className="mb-3">
                            <Form.Label>Included in scope *</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Describe what is included in your proposed scope of work."
                                name="included_in_scope"
                                value={offer.included_in_scope}
                                style={{height: '100px'}}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Excluded from scope *</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Describe what is excluded from your scope..."
                                name="excluded_from_scope"
                                value={offer.excluded_from_scope}
                                style={{height: '100px'}}
                                onChange={this.handleChange}
                            />
                        </Form.Group>

                        <p>Note: This offer is subject to the above scope of works.</p>

                        {error?.map((err, idx) => <Alert key={idx} variant='danger'>{err}</Alert>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-block" variant="primary"
                                onClick={this.handleOffer}
                                disabled={!userStatus.terms_completed}>
                            Make an Offer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

};

export default MakeOffer;
