import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import _ from "lodash";
import moment from "moment";

import http from "../../services/httpService";
import config from "../../constants";
import {lookupAbn} from "../../utils/abnLookup";
import {getIndustries, getOptionSet, getSetting} from "../../utils/fetchConfiguration";
import {RouteLeavingGuard} from "../../routing/RouteLeavingGuard";

import Form from "../common/forms/Form";
import FormStep from "../common/forms/FormStep";
import FormStepNavLink from "../common/forms/FormStepNavLink";
import StartListingStep from "./StartListingStep";
import DebtDetailsStep from "./DebtDetailsStep";
import ListingDetailsStep from "./ListingDetailsStep";
import SupportingDocumentsStep from "./SupportingDocumentsStep";
import CompleteListingStep from "./CompleteListingStep";
import {validate, validatePropertyWithoutSteps, validateStep, validateWithoutSteps} from "../common/forms/validation";
import {baseHandleChange} from "../common/forms/helpers";
import AssignCollectionPartnerStep from "./AssignCollectionPartnerStep";
import {useParams} from "react-router-dom";
import {Button} from "react-bootstrap"
import {Media} from '../../AppMedia'

import * as xeroApi from '../../services/xeroService'
import * as intuitApi from '../../services/intuitService'
import xeroLogo from '../../img/xero.svg'
import quickbooksLogo from '../../img/connect-to-quickbooks.svg'
import ListingSaleOrMarketplaceStep from "./ListingSaleOrMarketplaceStep";
import {useAccess} from "../../contexts/AccessContext";

const Joi = require('joi');
const apiPath = "/placer_listings/";
const apiPathDebts = "/placer_debts/";

const services = [
    {
        serviceName: 'XERO',
        serviceLabel: 'Xero',
        api: xeroApi,
        image: xeroLogo,
        backgroundColour: '#38AAE1',
        disconnectRoute: null
    },
    {
        serviceName: 'INTUIT',
        serviceLabel: 'QuickBooks',
        api: intuitApi,
        image: quickbooksLogo,
        backgroundColour: '#ffffff',
        disconnectRoute: '/integrations/intuit/disconnect'
    },
]

const url = window.location.search
const code = new URLSearchParams(url).get('code')
const realmId = new URLSearchParams(url).get('realmId')


export default function ListingForm(props) {

    const {listingId, activeServiceName} = useParams();

    const [currentStep, setCurrentStep] = useState(1)
    const numberOfSteps = 6

    const [errors, setErrors] = useState([])
    const [isBlocking, setIsBlocking] = useState(false)

    const [token, setToken] = useState(null)
    const [activeService, setActiveService] = useState(null)

    const [listing, setListing] = useState({})
    const [industries, setIndustries] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const documentTypeOtherId = documentTypes.length > 0 ? documentTypes.find(item => item.title === "Other").pk : null
    const [minimumTotalListingValue, setMinimumTotalListingValue] = useState(0)

    const [abnsValidated, setAbnsValidated] = useState([])
    const [debtsInEditingMode, setDebtsInEditingMode] = useState([])
    const [debtValidationErrors, setDebtValidationErrors] = useState([])

    const [saleOffer, setSaleOffer] = useState()
    const {currentAccessType} = useAccess()

    const formOptions = {
        nextButtonLabel: props.embedded === true ? "Next" : "Save & Continue",
        submitButtonLabel: listing.collection_partner_id ? "Send Listing to Collection Partner" : listing.listing_type === 'sale' ? "Sell Debt" : "Post Listing",
        previousButtonLabel: "Back"
    }

    const debtSchema = {
        due_date: Joi.date().required().label("Due Date"),
        amount: Joi.string().required().label("Amount").messages({"string.base": "Please enter the amount owed"}),
        debtor: {
            id: Joi.number().optional().allow(null),
            business_name: Joi.string().label("Business Name")
                .when('debtor_type',
                    {
                        is: 'individual',
                        then: Joi.string().allow(null, ''),
                        otherwise: Joi.string().required(),
                    }
                ),
            first_name: Joi.string().label("First name")
                .when('debtor_type',
                    {
                        is: 'business',
                        then: Joi.string().allow(null, ''),
                        otherwise: Joi.string().required(),
                    }
                ),
            last_name: Joi.string().label("Last Name")
                .when('debtor_type',
                    {
                        is: 'business',
                        then: Joi.string().allow(null, ''),
                        otherwise: Joi.string().required(),
                    }
                ),
            abn: Joi.string().label("ABN")
                .when('debtor_type',
                    {
                        is: 'individual',
                        then: Joi.string().allow(null, ''),
                        otherwise: Joi.string().required().messages({"string.base": "ABN is required for business debtors"}),
                    }
                ),
            industry: Joi.number().label("Industry").allow(null, ''),
            address_1: Joi.string().label("Address Line 1").allow(null, ''),
            address_2: Joi.string().allow('', null).label("Address Line 2"),
            suburb: Joi.string().allow('', null).label("Suburb"),
            zip_code: Joi.string().allow('', null).length(4).label("Post Code"),
            state: Joi.string().allow('', null).max(4).label("State"),
            contact_first_name: Joi.any().allow('', null),
            contact_last_name: Joi.any().allow('', null),
            contact_phone: Joi.string().allow(null, '').label("Contact Phone"),
            contact_email: Joi.string().email({tlds: {allow: false}})
                .allow(null, '').label("Contact Email"),
            debtor_type: Joi.string().required(),
        }
    }

    const schema = {
        1: {},
        2: {
            total_value: minimumTotalListingValue ? Joi.number().required().min(Number(minimumTotalListingValue)) : Joi.number().required().min(0),
            debts: Joi.array().items(debtSchema)
        },
        3: {
            is_personally_guaranteed: Joi.bool(),
            comments: Joi.any(),
            is_disputed: Joi.bool(),
            is_disputed_comments: Joi.any()
        },
        4: {
            supporting_documents: Joi.array().items(
                Joi.object({
                    comments: Joi.any(),
                    document_type: Joi.number().required().label("Document Type"),
                    document_type_title: Joi.any(),
                    file: Joi.any(),
                })
            )
        },
        5: {},
        6: {
            status: Joi.string(),
        }
    }

    function hasSkipButton() {
        if (currentStep === 3 && !listing.comments) return true
        if (currentStep === 4 && listing.supporting_documents.length <= 0) return true
        if (currentStep === 5 && !listing.collection_partner_id && listing.total_value >= 2000) return true
        return false
    }

    useEffect(() => {
        loadData()

        // integrations: load token if present
        setToken(null)
        services.some((service) => {
            service.api.getToken().then((res) => {
                if (res.data === 'access_token') {
                    setToken(true)
                    setActiveService(service)
                    return true // stops loop execution
                }
            })
        })
    }, []);


    useEffect(() => {
        // exchange auth code
        if (activeServiceName) {
            const newActiveService = services.find((s) => s.serviceName.toUpperCase() === activeServiceName.toUpperCase())
            setActiveService(newActiveService)

            // exchange auth code if present
            if (code) {
                if (realmId) {
                    newActiveService.api
                        .postCode({code: code, realmId: realmId})
                        .then((res) =>
                            res.data.status === 'OK'
                                ? window.location.replace(
                                    window.location.origin + window.location.pathname,
                                )
                                : null,
                        )

                    return
                }
                newActiveService.api
                    .postCode({code: code})
                    .then((res) =>
                        res.data.status === 'OK'
                            ? window.location.replace(
                                window.location.origin + window.location.pathname,
                            )
                            : null,
                    )

                return
            }
        }

    }, [])

    useEffect(() => {
        // set the correct step based on URL params or listing
        const urlParams = new URLSearchParams(props.location.search);
        const urlStep = urlParams.get("step");
        if (urlStep) setCurrentStep(parseInt(urlStep))
        else if (listing.id && !activeServiceName) {
            if (currentStep < 2 && props.embedded !== true) setCurrentStep(2)
        }
    }, [listing]);

    useEffect(() => {
        updateDebtTotal()
        validateDebtsInitially()
        getSaleOffer()
    }, [listing.debts]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentStep])

    useEffect(() => {
        if (currentStep === 5 && listing.listing_type === 'sale') setCurrentStep(6)
    }, [currentStep])

    // TODO might have to reinstate this but make sure it works after Intuit OAuth flow
    // useEffect(() => {
    //     if (currentAccessType && currentAccessType.access !== 'OWNER' && currentStep === 1 && !listingId) {
    //         handleCreateListingManually()
    //     }
    // }, [currentAccessType])

    function handleChange({currentTarget: input}) {
        const {data: newListing, errors: newErrors} = baseHandleChange(input, listing, errors, schema, currentStep);
        setListing(newListing)
        setErrors(newErrors)
        setIsBlocking(true)
    }

    function handleChooseListingType(listingType) {
        setListing({...listing, listing_type: listingType})
        setCurrentStep(2)
    }

    async function handleCreateListingManually() {
        const newListing = {
            id: null,
            status: "draft",
            debts: [],
            supporting_documents: []
        }
        const response = await http.post(config.API_URL + apiPath, newListing);
        const newDebts = [{
            id: null,
            listing_id: listing.id,
            debtor: {
                id: null,
                debtor_type: "business",
                abn: null,
                business_name: null
            },
            amount: 0,
            due_date: null
        }]
        const createdListing = response.data
        createdListing.debts = newDebts
        setListing(createdListing)
        setEditingActive(newDebts.length - 1, true)
        setCurrentStep(2)
    }

    async function handleUploadDebts(uploadedData) {
        try {
            const response = await http.post(config.API_URL + apiPath + listing.id + "/upload_debts/", uploadedData)
            setListing(response.data)
            setIsBlocking(false)
            setCurrentStep(2)
            toast.success("Listing data uploaded successfully.", {autoClose: 3000})
            return true;
        } catch (ex) {
            if (ex.data) {
                setErrors([ex.data])
                    [ex.data].map((error, index) => toast.error(error.message))
            }
        }
    }

    async function handleLoadListingFromInvoices(newListing) {
        setListing(newListing)
        setCurrentStep(2)
        setEditingActive(0)
    }

    // debt change handlers
    async function validateAbn(index) {

        const debt = listing.debts[index]
        const newAbnsValidated = [...abnsValidated]

        if (debt.debtor.debtor_type === 'individual') return null;
        if (!debt.debtor.abn) {
            newAbnsValidated[index] = "invalid"
            setAbnsValidated(newAbnsValidated)
            return {'abn': "Please enter a valid ABN"}
        }

        const abnValid = await lookupAbn(debt.debtor.abn);

        if (abnValid) {
            newAbnsValidated[index] = "valid"
            setAbnsValidated(newAbnsValidated)
            return null;
        }

        newAbnsValidated[index] = "invalid"
        setAbnsValidated(newAbnsValidated)
        return {'abn': "Please enter a valid ABN"}
    }

    function setEditingActive(index, value = true) {
        const newDebtsInEditingMode = [...debtsInEditingMode]
        newDebtsInEditingMode[index] = value
        setDebtsInEditingMode(newDebtsInEditingMode)
    }

    function validateDebtsInitially() {
        if (!listing.debts) return

        const newDebtValidationErrors = []
        for (let i = 0; i < listing.debts.length; i++) {
            // don't validate an empty, unsaved debt just yet
            if (!listing.debts[i].id) continue
            const errors = validateWithoutSteps(debtSchema, listing.debts[i])
            newDebtValidationErrors[i] = errors
        }
        setDebtValidationErrors(newDebtValidationErrors)
    }

    async function getSaleOffer() {
        if (!listing.debts || props.embedded !== true) return
        const response = await http.get(config.API_URL + `/debt_sale/best_offer/${listing.id}`);
        setSaleOffer(response.data)
    }

    function handleValidateDebt(index) {
        const errors = validateWithoutSteps(debtSchema, listing.debts[index])

        const newDebtValidationErrors = [...debtValidationErrors]
        newDebtValidationErrors[index] = errors || {}
        setDebtValidationErrors(newDebtValidationErrors)

        return errors
    }

    function baseHandleDebtChange(index, path, newValue) {

        const newDebt = {...listing.debts[index]}

        const newDebtErrors = {...debtValidationErrors[index]}
        const errorMessage = validatePropertyWithoutSteps({name: path, value: newValue}, debtSchema, newDebt);
        if (errorMessage) newDebtErrors[path] = errorMessage;
        else delete newDebtErrors[path];

        _.set(newDebt, path, newValue);

        // update the listing
        var newDebts = [...listing.debts]
        newDebts[index] = newDebt
        setDebts(newDebts)
        setIsBlocking(true)

        // update the errors
        const newDebtValidationErrors = [...debtValidationErrors]
        newDebtValidationErrors[index] = newDebtErrors || {}
        setDebtValidationErrors(newDebtValidationErrors)

    }

    function handleDebtChange(event, index) {

        const input = event.currentTarget;

        var newValue
        if (input.name === 'amount') {
            newValue = input.value.replace(/[^\d.-]/g, '')
        } else if (input.type === 'checkbox') {
            newValue = input.checked
        } else {
            newValue = input.value;
        }

        baseHandleDebtChange(index, input.name, newValue)

    }

    function handleDebtDateChange(date, index, path) {

        let formattedDate;
        if (date) {
            try {
                formattedDate = moment(date).format("YYYY-MM-DD");
            } catch (e) {
                formattedDate = date;
            }
        }

        baseHandleDebtChange(index, path, formattedDate)

    }

    async function handleSaveDebt(event, index) {

        event.preventDefault()

        try {
            const newDebts = [...listing.debts]
            const debt = newDebts[index]
            if (!debt.listing_id) debt['listing_id'] = listing.id

            let newDebt;
            if (debt.id) {
                newDebt = await http.patch(config.API_URL + apiPathDebts + debt.id + "/", debt);
            } else {
                newDebt = await http.post(config.API_URL + apiPathDebts, debt);
            }
            newDebts[index] = newDebt.data
            setDebts(newDebts)
        } catch (ex) {
            // TODO error handling
            if ((ex.response && ex.response.status === 400) || ex.status === 400) {
                toast.error("Error.");
                return false;
            }
        }
    }

    async function handleReloadDebt(event, index) {
        try {
            const newDebts = [...listing.debts]
            const debt = newDebts[index]

            let newDebt;
            if (debt.id) {
                newDebt = await http.get(config.API_URL + apiPathDebts + debt.id + "/");
                newDebts[index] = newDebt.data
                setDebts(newDebts)
            } else {
                // if not yet saved, delete the debt entirely
                return handleDeleteDebt(event, index)
            }
            // updateDebtTotal()
        } catch (ex) {
            // TODO error handling
            if ((ex.response && ex.response.status === 400) || ex.status === 400) {
                toast.error("Error.");
                return false;
            }
        }
    }

    function handleAddDebt(event) {
        event.preventDefault();
        const newDebts = [...listing.debts, {
            id: null,
            listing_id: listing.id,
            debtor: {
                id: null,
                debtor_type: "business",
                abn: null,
                business_name: null
            },
            amount: 0,
            due_date: null
        }]
        setDebts(newDebts)

        // open the newly added debt
        setEditingActive(newDebts.length - 1, true)
    }

    async function handleDeleteDebt(event, index) {
        event.preventDefault();

        try {
            const debt = {...listing.debts[index]}
            var newDebts = [...listing.debts]
            if (debt.id) {
                await http.delete(config.API_URL + apiPathDebts + debt.id + "/");
            }
            newDebts.splice(index, 1)
            setDebts(newDebts)

            // update the other state arrays as well
            const newAbnsValidated = [...abnsValidated]
            newAbnsValidated.splice(index, 1)
            setAbnsValidated(newAbnsValidated)

            const newDebtValidationErrors = [...debtValidationErrors]
            newDebtValidationErrors.splice(index, 1)
            setDebtValidationErrors(newDebtValidationErrors)

            const newEditing = [...debtsInEditingMode]
            newEditing.splice(index, 1)
            setDebtsInEditingMode(newEditing)

        } catch (ex) {
            if ((ex.response && ex.response.status === 400) || ex.status === 400) {
                toast.error("Error.");
                return false;
            }
        }
    }

    function setDebts(newDebts) {
        setListing({...listing, "debts": newDebts});
    }

    function setDebt(debt, index) {
        let newDebts = [...listing.debts]
        newDebts[index] = debt
        setDebts(newDebts)
    }

    function setStepLink(index) {
        if (currentStep > index || listing.status === 'listed' || listing.status === 'offer_accepted')
            setCurrentStep(index)
    }

    function handleValidateStep(stepIndex) {
        return validateStep(stepIndex, schema, listing)
    }

    function handleValidate() {
        return validate(schema, listing)
    }

    async function handleSubmit(e) {
        if (e) e.preventDefault();
        // steps that don't submit
        if (currentStep <= 1) return true;

        let newErrors = {}
        if (currentStep < numberOfSteps) {
            newErrors = validateStep(currentStep, schema, listing);
        } else {
            newErrors = validate(schema, listing);
        }

        setErrors(newErrors || [])
        if (newErrors) return false;

        // save
        return await doSubmit();
    }

    async function doSubmit() {
        return await saveListing();
    }

    async function doAfterLastStep() {
        if (listing.collection_partner_id) {
            await assignAndRedirect()
        } else if (listing.listing_type === 'sale') {
            await doSellListing()
        } else {
            await publishAndRedirect()
        }
    }

    async function loadData() {

        // config data
        const {data: documentTypesResp} = await getOptionSet("SUPPORTING_DOCUMENT_TYPES");
        setDocumentTypes(documentTypesResp[0].options)

        const {data: industriesRes} = await getIndustries();
        setIndustries(industriesRes)

        const {data: minimumTotalListingValueResp} = await getSetting("MINIMUM_LISTING_VALUE");
        setMinimumTotalListingValue(Number(minimumTotalListingValueResp[0].value_number))

        // listing
        if (listingId) {
            try {
                const {data: listingResp} = await http.get(config.API_URL + apiPath + listingId + "/");
                setListing(listingResp)
                // if (currentStep < 2) setCurrentStep(2)
            } catch (e) {
                if (e.status === 404) {
                    props.history.push('/not-found')
                }
            }
        }


    }

    async function saveListing() {
        try {
            var response
            if (listing.id) {
                response = await http.patch(config.API_URL + apiPath + listing.id + "/", listing);
            } else {
                response = await http.post(config.API_URL + apiPath, listing);
            }
            setListing(response.data)
            setIsBlocking(false)
            return true;
        } catch (ex) {
            // console.log(ex)
            if ((ex.response && ex.response.status === 400) || ex.status === 400) {
                toast.error("Error.");
                return false;
            }
        }

    }

    async function publishAndRedirect() {

        try {
            const previousStatus = listing.status;
            const {data: listingResp} = await http.post(config.API_URL + apiPath + listing.id + "/publish/");

            var message = "Listing successfully updated";
            if (previousStatus === 'draft' && listingResp.status === 'listed') {
                message = "Listing successfully published";
            }

            let targetPath = '/listings/' + listing.id;
            if (props.embedded) {
                targetPath = '/embedded/completed';
            }

            // redirect with toast message
            props.history.push({
                pathname: targetPath,
                state: {success_message: message}
            });

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Error.");
            }
        }
    }

    async function assignAndRedirect() {
        try {
            const previousStatus = listing.status;
            const {data: listingResp} = await http.post(config.API_URL + apiPath + listing.id + "/assign/");

            var message = "Listing successfully updated";
            if (previousStatus === 'draft' && listingResp.status === 'offer_accepted') {
                message = "Listing successfully sent to Collection Partner";
            }

            let targetPath = '/listings/' + listing.id;
            if (props.embedded) {
                targetPath = '/embedded/completed';
            }

            // redirect with toast message
            props.history.push({
                pathname: targetPath,
                state: {success_message: message}
            });

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                // TODO proper handling
                // const errors = {...this.state.errors};
                // this.setState({errors});
                toast.error("Unexpected error.");
            }
        }
    }

    async function doSellListing() {
        try {
            const previousStatus = listing.status;
            const {data: listingResp} = await http.post(config.API_URL + apiPath + listing.id + "/sell/");

            var message = "Debt sold";
            if (previousStatus === 'draft' && listingResp.status === 'pending_sale') {
                message = "Listing successfully sold.";
            }

            let targetPath = '/listings/' + listing.id;
            if (props.embedded) {
                targetPath = '/embedded/completed-sale';
            }

            // redirect with toast message
            props.history.push({
                pathname: targetPath,
                state: {success_message: message}
            });

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                // TODO proper handling
                toast.error("Unexpected error.");
            }
        }
    }

    async function deleteListing() {

        try {
            await http.post(config.API_URL + apiPath + listing.id + "/delete/");
            var message = "Listing deleted. You can still see it under deleted listings on your dashboard.";

            // redirect with toast message
            props.history.push({
                pathname: '/listings',
                state: {
                    success_message: message
                }
            });

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Error."); // TODO better handling
            }
        }
    }

    function updateDebtTotal() {
        if (!listing || !listing.debts) return
        const newListing = {...listing}
        newListing.total_value = Math.round((listing.debts.reduce((total, debt) => total + (debt.amount ? parseFloat(debt.amount) : 0), 0) || 0) * 100) / 100;
        setListing(newListing)
    }

    function handleDocumentChange(event, index) {
        const input = event.currentTarget;

        const listingCopy = {...listing};
        const supporting_documents = listingCopy.supporting_documents;
        _.set(supporting_documents[index], input.name, input.value);

        if (input.name === "document_type" && input.value == documentTypeOtherId) {
            _.set(supporting_documents[index], "comments", null);
        }
        if (input.name === "debt" && input.value == undefined) {
            _.set(supporting_documents[index], "debt", null);
        }
        setListing(listingCopy)
    }

    function handleAddDocument(supportingDocument) {
        const listingCopy = {...listing};
        listingCopy.supporting_documents.push(supportingDocument);
        setListing(listingCopy)
    }

    function handleDeleteDocument(event, index) {
        event.preventDefault();
        const listingCopy = {...listing};
        listingCopy.supporting_documents.splice(index, 1);
        setListing(listingCopy)
    }

    function nextButtonDisabled() {
        if (hasSkipButton()) return true
        if (currentStep === 5) {
            if (listing.total_value < 2000 && !listing.collection_partner_id) {
                return true
            }
        }
        return false
    }

    if (props.location.state?.error_message) {
        toast.error(props.location.state.error_message, {autoClose: 3000});
    }
    if (props.location.state?.success_message) {
        toast.success(this.props.location.state.success_message, {autoClose: 3000});
    }

    var hideNextButtonClass = currentStep === 1 ? "hideNextButton hideBottomDivider" : ""
    var hideBackButtonClass = (currentStep === 2 && listing.id) ? "hideBackButton" : ""

    let listingForm = (media) => (
        <div className={"listingForm" + (props.embedded === true ? " embedded" : "")}>
            <RouteLeavingGuard
                when={isBlocking}
                navigate={path => props.history.push(path)}
                shouldBlockNavigation={location => {
                    return isBlocking
                }}
            />
            <Form numberOfSteps={numberOfSteps}
                  currentStep={currentStep}
                  setStep={(index) => setCurrentStep(index)}
                  onSubmit={handleSubmit}
                  hasSkipButton={hasSkipButton()}
                  afterLastStep={doAfterLastStep}
                  validateStep={handleValidateStep}
                  validate={handleValidate}
                  options={formOptions}
                  nextButtonDisabled={nextButtonDisabled()}
                  hasDotNavigation={props.embedded}
                  hideSteps={listing.listing_type === 'sale' ? [5] : []}
                  className={"mb-5 listingForm " + hideNextButtonClass + " " + hideBackButtonClass}
            >
                <div className="listingsHeader">
                    {listing.id && !props.embedded &&
                        <Button
                            className="btn-delete danger float-right"
                            onClick={deleteListing}
                            variant="danger">
                            <i className="fa fa-trash"/> &nbsp;
                            Delete listing
                        </Button>}

                    <h1>{props.embedded === true ? "Send to third-party collection" : listingId ? "Edit Listing" : "Create a new listing"}</h1>

                </div>

                <div className="container">

                    {(token === true || currentStep > 1) &&
                        <div className="FormStepNavigation">
                            <ol>
                                <FormStepNavLink
                                    currentStep={currentStep}
                                    step={1}
                                    label="Select Debtors"
                                    media={media}
                                    setStep={() => props.embedded === true ? setStepLink(2) : null} // can't go back here in app
                                />
                                <FormStepNavLink
                                    currentStep={currentStep}
                                    step={2}
                                    label="Debtor Details"
                                    media={media}
                                    setStep={() => setStepLink(2)}
                                />
                                <FormStepNavLink
                                    currentStep={currentStep}
                                    step={3}
                                    label="Listing Detail"
                                    media={media}
                                    setStep={() => setStepLink(3)}
                                />
                                <FormStepNavLink
                                    currentStep={currentStep}
                                    step={4}
                                    label="Supporting Documents"
                                    media={media}
                                    setStep={() => setStepLink(4)}
                                />
                                {listing.listing_type !== 'sale' && <FormStepNavLink
                                    currentStep={currentStep}
                                    step={5}
                                    label="Select Collection Partner"
                                    media={media}
                                    setStep={() => setStepLink(5)}
                                />}
                                <FormStepNavLink
                                    currentStep={currentStep}
                                    step={6}
                                    label="Publish"
                                    media={media}
                                    setStep={() => setStepLink(6)}
                                />
                            </ol>
                            {/*{media === 'mobile' ? stepLabel[currentStep]: null}*/}
                        </div>}

                    <div className="">
                        <FormStep stepIndex={1} currentStep={currentStep}>

                            {listing.id && props.embedded === true && <ListingSaleOrMarketplaceStep
                                listing={listing}
                                saleOffer={saleOffer}
                                errors={errors}
                                handleChange={handleChange}
                                handleChooseListingType={handleChooseListingType}
                            />}

                            {!listing.id && props.embedded !== true && <StartListingStep
                                token={token}
                                activeService={activeService}
                                services={services}
                                handleCreateListingManually={handleCreateListingManually}
                                handleLoadListingFromInvoices={handleLoadListingFromInvoices}
                            />}
                        </FormStep>

                        <FormStep stepIndex={2} currentStep={currentStep}>
                            {listing && <DebtDetailsStep listing={listing}
                                                         errors={errors}
                                                         debtValidationErrors={debtValidationErrors}
                                                         industries={industries}
                                                         minValue={minimumTotalListingValue}
                                                         handleUploadDebts={handleUploadDebts}
                                                         handleDebtChange={handleDebtChange}
                                                         handleDebtDateChange={handleDebtDateChange}
                                                         setDebt={setDebt}
                                                         handleDeleteDebt={handleDeleteDebt}
                                                         handleReloadDebt={handleReloadDebt}
                                                         handleSaveDebt={handleSaveDebt}
                                                         handleAddDebt={handleAddDebt}
                                                         handleValidateDebt={handleValidateDebt}
                                                         validateAbn={validateAbn}
                                                         debtsInEditingMode={debtsInEditingMode}
                                                         setEditingActive={setEditingActive}
                                                         abnsValidated={abnsValidated}
                                                         embedded={props.embedded}
                            />}
                        </FormStep>

                        <FormStep stepIndex={3} currentStep={currentStep}>
                            <ListingDetailsStep
                                listing={listing}
                                errors={errors}
                                handleChange={handleChange}
                                embedded={props.embedded}
                            />
                        </FormStep>

                        <FormStep stepIndex={4} currentStep={currentStep}>
                            {listing && <SupportingDocumentsStep
                                listing={listing}
                                errors={errors}
                                supportingDocuments={listing.supporting_documents}
                                documentTypes={documentTypes}
                                handleDocumentChange={handleDocumentChange}
                                handleAddDocument={handleAddDocument}
                                handleDeleteDocument={handleDeleteDocument}
                                documentTypeOtherId={documentTypeOtherId}
                                embedded={props.embedded}
                            />}
                        </FormStep>

                        {listing.listing_type !== 'sale' && <FormStep stepIndex={5} currentStep={currentStep}>
                            <AssignCollectionPartnerStep
                                errors={errors}
                                listing={listing}
                                handleChange={handleChange}
                            />
                        </FormStep>}

                        <FormStep stepIndex={6} currentStep={currentStep}>
                            <CompleteListingStep
                                errors={errors}
                                saleOffer={saleOffer}
                                listing={listing}
                            />
                        </FormStep>
                    </div>
                </div>
            </Form>
        </div>

    );

    return <>
        <Media at='mobile'>
            {listingForm("mobile")}
        </Media>
        <Media at='tablet'>
            {listingForm('tablet')}
        </Media>
        <Media greaterThan='tablet'>
            {listingForm("others")}
        </Media></>

}