import React, {useEffect, useState} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {BetterSelect} from "../common/forms/BetterSelect";
import {formatCurrency} from "../common/numberFormats";


export default function AssignCollectionPartnerStep({listing, handleChange}) {

    const [collectionPartners, setCollectionPartners] = useState([]);

    useEffect(() => {
        loadData()
    }, []);

    async function loadData() {
        const newCps = await http.get(config.API_URL + "/collection_partners/preferred/");
        setCollectionPartners(newCps.data);
    }

    const selectedCollectionPartner = collectionPartners?.find(cp => cp.id === listing.collection_partner_id);

    return (
        <div className="form-group listingFormAssignCollectionPartner listingFormContainer">
            <h4>Select Collection Partner</h4>

            <p>Select one of Debtplacer's leading Collection Partners from the list below.</p>

            {listing.total_value >= 2000 && <p>Alternatively, skip this step to list on the marketplace and start receiving offers.</p>}

            <BetterSelect
                data={listing}
                name="collection_partner_id"
                label=""
                options={collectionPartners}
                valueProperty="id"
                textProperty="business_name"
                onChange={handleChange}
                emptyLabel="Select Collection Partner"
                disabled={listing.status !== 'draft'}
            />

            {!listing.collection_partner_id && <div className="helpSection">
                <p>Not sure who to select? Visit the link below to learn more about how to choose the right Collection Partner.</p>
                <p><a href="https://debtplacer.com/blog/choosing-a-collection-partner" target="_blank">Learn more</a></p>
            </div>}

            {listing.collection_partner_id && selectedCollectionPartner &&

                <>
                    <div className="commissionRateContainer">
                        <div className="flexHeadingWithButton">

                            <h6>Schedule of Commission Rates*</h6>
                            <a className="btn btn-primary float-right"
                               href={`/collection-partners/${listing.collection_partner_id}`} target="_blank">
                                View Profile
                            </a>
                        </div>

                        <div className="listingFormCommissionRateTableContainer">

                            <table className="table listingFormCommissionRateTable">
                                <thead>
                                <tr>
                                    <th>Debt Amount</th>
                                    <th className="commissionRate">Commission Rate (%)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {selectedCollectionPartner.commission_rates.map((r, index) => (
                                    <tr key={index}>
                                        <td className="debtAmount">
                                            {r.from_amount > 0 && r.to_amount > 0 && <>{formatCurrency(r.from_amount, 0)} - {formatCurrency(r.to_amount, 0)}</>}
                                            {r.from_amount > 0 && !r.to_amount && <>Over {formatCurrency(r.from_amount, 0)}</>}
                                            {!r.from_amount && r.to_amount > 0 && <>Up
                                                to {formatCurrency(r.to_amount, 0)}</>}
                                        </td>
                                        <td className="commissionRate">{r.commission_rate}%</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <p className="viewTerms">
                        <strong>*Note</strong>: Commission is only payable on the amount recovered from the debtor.
                    </p>

                    <p className="viewTerms">View Profile to review Collection Partner’s full terms and conditions.</p>
                </>
            }
        </div>
    )
}