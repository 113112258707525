import React from "react";
import _ from "lodash";
import MaskedInput from 'react-maskedinput'

const Input = ({name, label, error, className, iconClass, labelClass, value, id, mask, ...rest}) => {
    const inputValue = value || "";
    const inputId = id || name;

    return (
        <div className={"form-group mb-2 " + (iconClass ? "has-icon" : "")}>
            <label htmlFor={name} className={labelClass}>{label}</label>
            {mask ? <MaskedInput className={"form-control " + className} mask={mask} name={name} value={value} {...rest}/> :
                <input {...rest} value={inputValue} name={name} id={inputId} className={"form-control " + className}/>}
            {iconClass && <i className={iconClass}/>}
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

const HiddenInput = ({name, label, error, ...rest}) => {
    return (
        <div>
            <input {...rest} name={name} id={name} className="form-control"/>
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
};

function renderInput(data, errors, onChange, path, label, placeholder = "", type = "text", className = "", onBlur = null, iconClass = "", mask) {

    if (type === "hidden") {
        return (
            <HiddenInput
                type={type}
                name={path}
                value={data[path]}
                error={errors[path] || null}
            />
        );
    }
    return (
        <Input
            type={type}
            name={path}
            value={_.get(data, path) || ""}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            error={errors[path] || null}
            className={className}
            placeholder={placeholder}
            iconClass={iconClass}
            mask={mask}
        />
    );
}

export {
    HiddenInput,
    Input,
    renderInput
}

