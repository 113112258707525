import React from "react";


class Close extends React.Component {

    render() {

        // if (!this.props.embedded && auth.getCurrentUser()) return <Redirect to="/"/>;
        return (
            <div className="login-outer">
                <div className="container login-inner">
                    <h1>All done.</h1>
                    <br/>
                    <p>You can now close this window.</p>
                </div>
            </div>
        );
    }
}

export default Close;
