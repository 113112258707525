import React from "react";
import Select from 'react-select';


class MultiSelect extends React.Component {

    handleSelect = (selectedOptions) => {
        const { handleChange, name} = this.props;
        return handleChange(selectedOptions, name)
    }

    render() {

        const { options, value, label, valueProperty, textProperty} = this.props;

        return (
            <div className="form-group">
            <label htmlFor={value}>{label}</label>
            <Select options={options}
                    onChange={this.handleSelect}
                    value={value}
                    getOptionValue={option => option[valueProperty]}
                    getOptionLabel={option => option[textProperty]}
                    isMulti
            />
            </div>
        );
    }
}

export default MultiSelect;

MultiSelect.defaultProps = {
    textProperty: "title",
    valueProperty: "pk"
};
