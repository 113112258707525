import axios from "axios";
import logger from "./logService";
import {toast} from "react-toastify";
import config from "../constants";

const refreshUrl = '/accounts/token/refresh/';


var axiosInstance;
try {
    axiosInstance = axios.create({
        baseURL: config.API_URL,
        timeout: 10000,
        headers: {
            'Authorization': "Bearer " + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'access-type': localStorage.getItem('access_type'),
            'placer-id': localStorage.getItem('placer_id'),
            'collection-partner-id': localStorage.getItem('collection_partner_id'),
        }
    });
} catch (ex) {
    if (ex instanceof DOMException) {
        alert("Please enable third-party cookies to use this site.");
    }
}


axiosInstance.interceptors.response.use(response => response, async error => {
    if (error.message === 'Network Error') {
        // this occurs when the API isn't online/reachable - how to handle?
        toast.error("An unexpected error occurred.");
        return Promise.reject(error);
    }
    // if unauthorized, refresh the token
    if (error.response.status == 401) {
        const originalRequest = error.config;
        if (originalRequest['url'] !== refreshUrl) {
            return await refreshToken(error, originalRequest)
        } else {
            // force logout if still not authenticated
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('access_type');
            localStorage.removeItem('placer_id');
            localStorage.removeItem('collection_partner_id');
        }
    } else {
        throw error.response
    }

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        logger.log(error);
        toast.error("An unexpected error occurred.");
    }

    return Promise.reject(error);
});

function setAccessTypeHeaders(accessType) {
    axiosInstance.defaults.headers['access-type'] = accessType.type;
    axiosInstance.defaults.headers['placer-id'] = accessType.type === 'placer' ? accessType.id : null;
    axiosInstance.defaults.headers['collection-partner-id'] = accessType.type === 'collection_partner' ? accessType.id : null;
}

function setJwt(jwt) {
    axiosInstance.defaults.headers['Authorization'] = "Bearer " + jwt;
}

function removeJwt(jwt) {
    axiosInstance.defaults.headers['Authorization'] = null;
}

export function refreshToken(error, originalRequest) {

    const refresh_token = localStorage.getItem('refresh_token');

    return axiosInstance
        .post(refreshUrl, {refresh: refresh_token})
        .then((response) => {
            // console.log('new refesh token', response.data.access)
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
            if(originalRequest){
                originalRequest.headers['Authorization'] = "Bearer " + response.data.access;
                return axiosInstance(originalRequest);
            }
        })
        .catch(err => {
            if (err.response && err.response.status == 401) {
                removeJwt();
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('access_type');
                localStorage.removeItem('placer_id');
                localStorage.removeItem('collection_partner_id');
                window.location = '/login'
            }
        });
}

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete,
    setJwt,
    removeJwt,
    setAccessTypeHeaders
}

