import jwtDecode from "jwt-decode";
import http from "./httpService";
import httpNoAuth from "./httpNoAuthService";
import config from "../constants";

const apiEndpoint = config.API_URL + "/accounts/";
const tokenKey = "token";

http.setJwt(getJwt());

export async function login(email, password) {
    const response = await httpNoAuth.post(apiEndpoint + "token/", {"username": email, "password": password});
    http.setJwt(response.data.access);
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
}

export async function loginAs(userId) {
    const response = await http.post(config.API_URL + `/users_for_admin/${userId}/token/`);
    http.setJwt(response.data.access);
    localStorage.setItem('access_token', response.data.access);
    localStorage.setItem('refresh_token', response.data.refresh);
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export async function logout() {
    try {
        await http.post(apiEndpoint + "token/logout/", {
            "refresh_token": localStorage.getItem("refresh_token")
        });
    } catch (ex) {
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('placer_id');
    localStorage.removeItem('access_type');
    localStorage.removeItem('collection_partner_id');
    http.removeJwt();
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem('access_token');
        return jwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export async function getUserStatus(redirect_to_login = false) {
    try {
        if (getCurrentUser()) {
            const {data} = await http.get(config.API_URL + "/users/status/");
            return data;
        } else if (redirect_to_login) {
            window.location = "/login"
        }
    } catch (ex) {
    }
    return null;
}


export function getJwt() {
    return localStorage.getItem('access_token');
}

export default {
    login,
    loginAs,
    loginWithJwt,
    logout,
    getCurrentUser,
    getUserStatus,
    getJwt
};
