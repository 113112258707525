import React from "react";

import {renderInput} from "../common/forms/Input";
import {renderSelect} from "../common/forms/Select";
import {loadAddressFinderScript} from "../../utils/addressFinder";
import config from "../../constants";
import {renderTextArea} from "../common/forms/TextArea";

class BusinessDetailsStep extends React.Component {

    state = {
        addressFinderWidget: null
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        loadAddressFinderScript(this.loadWidget); // address finder
    };

    loadWidget = () => {
        this.addressFinderWidget = new AddressFinder.Widget(
            document.getElementById("address_1"),
            config.ADDRESS_FINDER_API_KEY,
            'AU'
        );
        this.addressFinderWidget.on('result:select', (fullAddress, metaData) => {
            this.props.handleAddressChange(fullAddress, metaData);
        });
    }

    componentWillUnmount() {
        if (this.addressFinderWidget) {
            this.addressFinderWidget.destroy()
            this.addressFinderWidget = null
        }
    };

    render() {

        const { handleChange, errors, data, abnValidatedClass, validateAbn, registrationType } = this.props;

        return (
            <div className="form-group">
                <h2>Business Details</h2>

                {renderInput(data, errors, handleChange,"abn", "ABN", "","text", abnValidatedClass, validateAbn, null, '11 111 111 111')}

                {renderInput(data, errors, handleChange,"address_1", "Address Line 1")}
                {renderInput(data, errors, handleChange,"address_2", "Address Line 2")}

                <div className="form-row">
                    <div className="col-6">
                        {renderInput(data, errors, handleChange,"suburb", "Suburb")}
                    </div>
                    <div className="col-3">
                        {renderInput(data, errors, handleChange,"zip_code", "Post Code")}
                    </div>
                    <div className="col-3">
                        {renderInput(data, errors, handleChange,"state", "State")}
                    </div>
                </div>

                {registrationType === "placer" && renderSelect(data, errors, handleChange,"industry", "Your Industry", this.props.industries, "","id", "anzsic_division_description")}
                {renderInput(data, errors, handleChange,"website", "Website")}

                {registrationType === "collection_partner" && renderTextArea(data, errors, handleChange, "description",
                    "Your business profile description (optional)",
                    "Your business profile description"
                )}
            </div>
        )
    }

}

export default BusinessDetailsStep;
