import {Redirect, Route, Switch} from "react-router-dom";
import Login from "../components/auth_and_account/Login";
import Logout from "../components/auth_and_account/Logout";
import ForgotPassword from "../components/auth_and_account/ForgotPassword";
import ResetPassword from "../components/auth_and_account/ResetPassword";
import ProtectedRoute from "./protectedRoute";
import ChatList from "../components/messenger/ChatList";
import RegistrationRoute from "./registrationRoute";
import VendorRegistration from "../components/registration/VendorRegistration";
import VendorRegistrationComplete from "../components/registration/VendorRegistrationComplete";
import CollectionPartnerRegistration from "../components/registration/CollectionPartnerRegistration";
import CollectionPartnerRegistrationComplete from "../components/registration/CollectionPartnerRegistrationComplete";
import Register from "../components/registration/Register";
import ReviewForm from "../components/review/ReviewForm";
import ReviewThankYou from "../components/review/ReviewThankYou";
import ConfirmEmail from "../components/auth_and_account/ConfirmEmail";
import AccountSettings from "../components/auth_and_account/AccountSettings";
import ManageProfile from "../components/auth_and_account/ManageProfile";
import ManageSubscription from "../components/auth_and_account/ManageSubscription";
import Search from "../components/search/Search";
import SearchResults from "../components/search/SearchResults";
import SavedSearchRedirect from "../components/search/SavedSearchRedirect";
import ListingForm from "../components/listing_form/ListingForm";
import CollectionPartnerDetail from "../components/collection_partners/CollectionPartnerDetail";
import CollectionPartners from "../components/collection_partners/CollectionPartners";
import ListingDetail from "../components/listing_detail/ListingDetail";
import NotFound from "../components/NotFound";
import {React, useEffect, useState} from "react";
import Dashboard from "../components/dashboard/Dashboard"
import auth from "../services/authService";
import Listings from "../components/pages_placers/Listings";
import Collections from "../components/pages_placers/Collections";
import IntuitDisconnect from "../components/integrations/IntuitDisconnect"
import EmbeddedListingCreation from "../components/embedded_listing_creation/EmbeddedListingCreation";
import MainLayout from "../layout/MainLayout";
import EmbeddedLayout from "../layout/EmbeddedLayout";
import EmbeddedListingCompleted from "../components/embedded_listing_creation/EmbeddedListingCompleted";
import EmbeddedDebtSaleCompleted from "../components/embedded_listing_creation/EmbeddedDebtSaleCompleted";
import Close from "../components/auth_and_account/Close";
import PlacerAccessSettings from "../components/auth_and_account/PlacerAccessSettings";
import PlacerAccessAcceptInvite from "../components/auth_and_account/PlacerAccessAcceptInvite";
import {useAccess} from "../contexts/AccessContext";


export default function DebtplacerRouter() {

    const [user, setUser] = useState()
    const [userStatus, setUserStatus] = useState()

    const {currentAccessType} = useAccess()

    useEffect(async () => {
        const user = auth.getCurrentUser();
        const userStatus = await auth.getUserStatus();
        setUser(user)
        setUserStatus(userStatus)
    }, [])
    return (

        <Route>
            <Switch>

                <Route path='/embedded/:path?'>
                    <EmbeddedLayout user={user} userStatus={userStatus}>
                        <Switch>
                            <ProtectedRoute emailConfirmed={true} path="/embedded/listings/:listingId/edit"
                                            component={ListingForm}
                                            userStatus={userStatus} placersOnly={true} embedded={true}/>
                            <ProtectedRoute emailConfirmed={true} path="/embedded/listings/new"
                                            component={EmbeddedListingCreation}
                                            userStatus={userStatus} placersOnly={true} embedded={true}/>
                            <Route path="/embedded/login" component={Login} embedded={true}/>
                            <Route path="/embedded/logout" component={Logout} embedded={true}/>
                            <Route path="/embedded/close" component={Close} embedded={true}/>
                            <Route path="/embedded/completed" component={EmbeddedListingCompleted} embedded={true}/>
                            <Route path="/embedded/completed-sale" component={EmbeddedDebtSaleCompleted} embedded={true}/>
                        </Switch>
                    </EmbeddedLayout>
                </Route>

                <Route>
                    <MainLayout user={user} userStatus={userStatus}>
                        <Switch>

                            <ProtectedRoute path="/messenger/:id" component={ChatList} userStatus={userStatus} currentAccessType={currentAccessType}/>
                            <ProtectedRoute path="/messenger" component={ChatList} userStatus={userStatus} currentAccessType={currentAccessType}/>

                            {/*Vendor Registration*/}
                            <RegistrationRoute path="/register-client/:id" component={VendorRegistration}
                                               userStatus={userStatus}/>
                            <Route path="/register-client" component={VendorRegistration}/>
                            <Route path="/register-client-complete" component={VendorRegistrationComplete}/>

                            {/* Collection Partner Registration*/}
                            <RegistrationRoute path="/register-collection-partner/:id"
                                               component={CollectionPartnerRegistration}
                                               userStatus={userStatus}/>
                            <Route path="/register-collection-partner/" component={CollectionPartnerRegistration}/>
                            <Route path="/register-collection-partner-complete"
                                   component={CollectionPartnerRegistrationComplete}/>

                            {/* Other Auth */}
                            <Route path="/register" component={Register}/>
                            <Route path="/review/:id/:secret" component={ReviewForm}/>
                            <Route path="/review/submitted" component={ReviewThankYou}/>
                            <Route path="/login" component={Login}/>
                            <Route path="/logout" component={Logout}/>
                            <Route path="/confirm-email" component={ConfirmEmail}/>
                            <Route path="/forgot" component={ForgotPassword}/>
                            <Route path="/reset-password/:token" component={ResetPassword}/>


                            {/* Users */}
                            <ProtectedRoute path="/account" component={AccountSettings} userStatus={userStatus}/>
                            <ProtectedRoute path="/profile" component={ManageProfile} userStatus={userStatus}/>

                            {/* Integration disconnect */}
                            <ProtectedRoute emailConfirmed={true} path="/integrations/intuit/disconnect"
                                            component={IntuitDisconnect} userStatus={userStatus}/>

                            {/* Listings */}
                            <ProtectedRoute emailConfirmed={true} path="/listings/:listingId/edit"
                                            component={ListingForm}
                                            userStatus={userStatus} placersOnly={true}/>
                            <ProtectedRoute emailConfirmed={true} path="/listings/new/:activeServiceName"
                                            component={ListingForm}
                                            userStatus={userStatus}/>
                            <ProtectedRoute emailConfirmed={true} path="/listings/new" component={ListingForm}
                                            userStatus={userStatus}/>

                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true}
                                            path="/listings/:listingId/debts/:debtId" component={ListingDetail}
                                            userStatus={userStatus}/>
                            <Route path="/listings/:listingId/:secret" component={ListingDetail}/>
                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true}
                                            path="/listings/:listingId"
                                            component={ListingDetail} userStatus={userStatus}/>
                            <ProtectedRoute emailConfirmed={true} path="/listings" component={Listings}
                                            userStatus={userStatus} end={true}/>

                            {/* Collection Partners */}
                            <ProtectedRoute emailConfirmed={true} path="/subscription" component={ManageSubscription}
                                            userStatus={userStatus} currentAccessType={currentAccessType} />
                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true} path="/search"
                                            component={Search}
                                            userStatus={userStatus}/>
                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true} path="/results"
                                            component={SearchResults} userStatus={userStatus}/>
                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true}
                                            path="/saved-search/:id"
                                            component={SavedSearchRedirect} userStatus={userStatus}/>

                            {/* Placers */}
                            <ProtectedRoute emailConfirmed={true} path="/collections" component={Collections}
                                            userStatus={userStatus}/>

                            {/* Placer Access */}
                            <Route path="/team/invite/:id/:inviteCode" component={PlacerAccessAcceptInvite}/>
                            <ProtectedRoute emailConfirmed={true} path="/team" component={PlacerAccessSettings}
                                            userStatus={userStatus}/>


                            {/* Collection Partner Browse & Profiles */}
                            <ProtectedRoute emailConfirmed={true} path="/collection-partners/:id"
                                            component={CollectionPartnerDetail}
                                            userStatus={userStatus}/>
                            <ProtectedRoute emailConfirmed={true} path="/collection-partners"
                                            component={CollectionPartners}
                                            userStatus={userStatus}/>

                            {/* All */}
                            <ProtectedRoute emailConfirmed={true} collectionPartnerApproved={true} path="/dashboard"
                                            component={Dashboard} userStatus={userStatus}/>
                            <Route path="/not-found" component={NotFound}/>
                            <Redirect from="/" exact to="/dashboard"/>
                            <Redirect to="/not-found"/>

                        </Switch>

                    </MainLayout>
                </Route>


            </Switch>
        </Route>


    )
}