import React from "react";
import {formatCurrency, formatPercentage} from "./numberFormats";

const OfferFees = ({ offer }) => {
    return (
        <React.Fragment>
            {offer.offer_type === 'commission' && <React.Fragment>{formatPercentage(offer.commission_percentage)} Commission</React.Fragment>}
            {offer.offer_type === 'fixed_fee' && <React.Fragment>{formatCurrency(offer.fixed_fee_amount)} Fixed Fee</React.Fragment>}
            {offer.offer_type === 'fee_estimate' &&
                <React.Fragment>
                    Fee Estimate: {formatCurrency(offer.fee_estimate_from)} to {formatCurrency(offer.fee_estimate_to)}
                    <br/>
                    Disbursements & Outlays: {formatCurrency(offer.disbursements_estimate_from)} to {formatCurrency(offer.disbursements_estimate_to)}
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default OfferFees;
