import React, {Component} from "react";

class VendorRegistrationComplete extends Component {

    render() {

        return (
            <div className="container">

                <h3>Thanks for registering</h3>

                <p>Please use the link we just sent you to confirm your email address.</p>

                <p>We’re excited to have you join the Debtplacer movement and we look forward to facilitating
                    debt resolutions with you soon.</p>

                <p>The Debtplacer Team</p>

            </div>
        );
    }
}
export default VendorRegistrationComplete;
