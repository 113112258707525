import React from "react";
import http from "../services/httpNoAuthService";
import config from "../constants";

export async function getIndustries() {
    return await http.get(config.API_URL + "/industries/");
}

export async function getOptionSet(code) {
    return await http.get(config.API_URL + "/option_sets/?code=" + code);
}

export async function getOptionSets(codes) {
    return await http.get(config.API_URL + "/option_sets/?codes=" + codes);
}

export async function getSetting(code) {
    return await http.get(config.API_URL + "/settings/?code=" + code);
}

export async function getPlans() {
    return await http.get(config.API_URL + "/plans/");
}
