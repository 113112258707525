import React from "react";
import {NavLink} from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useAccess} from "../../contexts/AccessContext";

const AccountNavLinks = () => {

    const {isCollectionPartner, isPlacerOwner} = useAccess()

    return (
        <React.Fragment>
            {(isCollectionPartner() || isPlacerOwner()) && <NavLink className="dropdown-item" to="/profile">
                Manage Profile
            </NavLink>}
            {isCollectionPartner() && (
                <NavLink className="dropdown-item" to="/subscription">
                    Billing & Subscription
                </NavLink>
            )}
            <NavLink className="dropdown-item" to="/account">
                Account Settings
            </NavLink>
            {isPlacerOwner() && <NavLink className="dropdown-item" to="/team">
                Team
            </NavLink>}
            <NavDropdown.Divider></NavDropdown.Divider>
            <NavLink className="dropdown-item" to="/logout">
                Logout
            </NavLink>
        </React.Fragment>
    );
};

export default AccountNavLinks;
