import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

require('dotenv').config()


// CSS imports - order is important
import 'bootstrap/dist/css/bootstrap.min.css';
import "font-awesome/css/font-awesome.css";
import './index.scss';
import {createMediaStyle, MediaContextProvider} from "./AppMedia"

if (process.env.REACT_APP_STAGE !== "development") {
    Sentry.init({
        dsn: "https://b3b20a6f86074ea4877773609e8dd1c8@o320199.ingest.sentry.io/6067640",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_STAGE,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
        beforeSend(event) {
            // Modify or drop the event here
            if (['AxiosError', 'UnhandledRejection'].includes(event.exception.values[0].type)) {
                return null; // don't send this event
            }
            return event;
        },
    })
}

ReactDOM.render(
    <MediaContextProvider>
        <GoogleReCaptchaProvider reCaptchaKey="6LeV5rohAAAAAKuZfFKIB4zfwJzyIFI3eCvnh0Ks">
            <App/>
        </GoogleReCaptchaProvider>
    </MediaContextProvider>,
    document.getElementById("root"));
registerServiceWorker();
