require('dotenv').config();

const test = {
    API_URL: "https://api-dev.debtplacer.com/api",
}

const dev = {
    API_URL: "http://localhost:8000/api",
    ADDRESS_FINDER_API_KEY: "84RUJHKPG6QN9CFYMTLV",
    ABN_LOOKUP_GUID: "e7c37d9c-1946-4762-be28-aa49740fa625",
    CHARGEBEE_SITE: "debtplacer-test",
    COLLECTION_PARTNER_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/collection-partner-terms/",
    VENDOR_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/placer-terms",
    PRIVACY_POLICY_URL: "https://debtplacer.com/privacy-policy/",
    MULTI_LEDGER_TEMPLATE_DOWNLOAD_URL: "https://debtplacer-staging.s3.ap-southeast-2.amazonaws.com/debtplacer_multiledger_template.xlsx",
};

const jointoit = {
    API_URL: "https://api-jointoit.debtplacer.com/api",
    ADDRESS_FINDER_API_KEY: "84RUJHKPG6QN9CFYMTLV",
    ABN_LOOKUP_GUID: "e7c37d9c-1946-4762-be28-aa49740fa625",
    CHARGEBEE_SITE: "debtplacer-test",
    COLLECTION_PARTNER_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/collection-partner-terms/",
    VENDOR_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/placer-terms",
    PRIVACY_POLICY_URL: "https://debtplacer.com/privacy-policy/",
    MULTI_LEDGER_TEMPLATE_DOWNLOAD_URL: "https://debtplacer-staging.s3.ap-southeast-2.amazonaws.com/debtplacer_multiledger_template.xlsx",
};

const staging = {
    API_URL: "https://api-staging.debtplacer.com/api",
    ADDRESS_FINDER_API_KEY: "84RUJHKPG6QN9CFYMTLV",
    ABN_LOOKUP_GUID: "e7c37d9c-1946-4762-be28-aa49740fa625",
    CHARGEBEE_SITE: "debtplacer-test",
    COLLECTION_PARTNER_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/collection-partner-terms/",
    VENDOR_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/placer-terms",
    PRIVACY_POLICY_URL: "https://debtplacer.com/privacy-policy/",
    MULTI_LEDGER_TEMPLATE_DOWNLOAD_URL: "https://debtplacer-staging.s3.ap-southeast-2.amazonaws.com/debtplacer_multiledger_template.xlsx",
};

const production = {
    API_URL: "https://api.debtplacer.com/api",
    ADDRESS_FINDER_API_KEY: "84RUJHKPG6QN9CFYMTLV",
    ABN_LOOKUP_GUID: "e7c37d9c-1946-4762-be28-aa49740fa625",
    CHARGEBEE_SITE: "debtplacer",
    COLLECTION_PARTNER_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/collection-partner-terms/",
    VENDOR_TERMS_AND_CONDITIONS_URL: "https://debtplacer.com/placer-terms",
    PRIVACY_POLICY_URL: "https://debtplacer.com/privacy-policy/",
    MULTI_LEDGER_TEMPLATE_DOWNLOAD_URL: "https://debtplacer-prod.s3.ap-southeast-2.amazonaws.com/debtplacer_multiledger_template.xlsx",
};

let config;
switch (process.env.REACT_APP_STAGE) {
    case "production":
        config = production;
        break;
    case "staging":
        config = staging;
        break;
    case "jointoit":
        config = jointoit;
        break;
    default:
        config = dev;
}

export default config;
