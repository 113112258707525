import React from "react";
import {Redirect, Route} from "react-router-dom";
import auth from "../services/authService";
import {useAccess} from "../contexts/AccessContext";

const ProtectedRoute = ({ path, component: Component, render, userStatus, emailConfirmed=false,
                            collectionPartnerApproved=false,
                            placersOnly=false,
                            placersOwnerOnly=false,
                            embedded=false,
                            ...rest }) => {

    const user = auth.getCurrentUser()
    const {currentAccessType, isPlacerOwner, isPlacer, isCollectionPartner} = useAccess()

    return (
        <Route
            {...rest}
            render={props => {

                if (!user) {
                    return (
                        <Redirect
                            to={{
                                pathname: embedded ? `/embedded/login` : "/login",
                                state: {from: props.location},
                                search: props.location.search
                            }}
                        />
                    );
                }

                // if registration not complete, always redirect to registration
                if (userStatus && !userStatus.is_registration_complete && currentAccessType?.type !== "admin") {
                    if (isPlacer()) {
                        const placer_id = currentAccessType.id
                        return (
                            <Redirect
                                to={{
                                    pathname: "/register-client/" + placer_id,
                                    state: {from: props.location, requestEmailConfirmation: true, placer_id: placer_id}
                                }}
                            />
                        );
                    } else if (isCollectionPartner()) {
                        const collection_partner_id = currentAccessType.id
                        return (
                            <Redirect
                                to={{
                                    pathname: "/register-collection-partner/" + collection_partner_id,
                                    state: {from: props.location, requestEmailConfirmation: true, collection_partner_id: collection_partner_id}
                                }}
                            />
                        );
                    }
                };

                // if the email address needs to be approved to view this route, check and redirect accordingly
                if (emailConfirmed) {
                    if (userStatus && currentAccessType && !userStatus.is_email_confirmed) {
                        const userId = user.user_id
                        return (
                            <Redirect
                                to={{
                                    pathname: "/confirm-email",
                                    state: {from: props.location, requestEmailConfirmation: true, userId: userId}
                                }}
                            />
                        );
                    };
                }

                // if the collection partner needs to be approved to view this route, check and redirect accordingly
                if (collectionPartnerApproved && isCollectionPartner()) {
                    if (userStatus && userStatus.collection_partner_status !== 'approved') {
                        const userId = user.user_id
                        return (
                            <Redirect
                                to={{
                                    pathname: "/register-collection-partner-complete",
                                    state: {from: props.location, requestEmailConfirmation: true, userId: userId}
                                }}
                            />
                        )
                    }
                }

                // if the collection partner needs to be approved to view this route, check and redirect accordingly
                if (placersOnly) {
                    if (userStatus && currentAccessType?.type !== 'placer') {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/dashboard",
                                }}
                            />
                        )
                    }
                }

                if (placersOwnerOnly) {
                    if (userStatus && isPlacerOwner()) {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/dashboard",
                                }}
                            />
                        )
                    }
                }

                return Component ? <Component {...props} userStatus={userStatus} embedded={embedded} {...rest} /> : render(props);
            }}
        />
  );
};

export default ProtectedRoute;
