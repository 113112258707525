import React from "react";
import {Card, Col, Row, Accordion, Button} from "react-bootstrap"

const MobileTableCollectionPartner = ({columns, data}) => {
    let findColumn = (field) => {
        let found = columns.find(column => {
            return column.path ? column.path === field : column.key === field
        })
        return found;
    }
    return <Accordion defaultActiveKey={"accordion-0"}> {data.map((item, index) => {
        return <Card key={index} className={'collection-card listings'}>
            <Card.Header>
                <Row>
                    <Col style={{padding:5}}>
                        <Accordion.Toggle className="text-left" as={Button} variant="link" eventKey={"accordion-"+index}>
                            {findColumn("reference").content(item)} - {item.debtor_names}
                        </Accordion.Toggle>
                    </Col>
                    <Col style={{padding:10}} xs={1}>{findColumn("messenger").content(item)}</Col>
                    <Col style={{padding:10}} xs={1}>{findColumn("link").content(item)}</Col>
                </Row>
            </Card.Header>
            <Accordion.Collapse eventKey={"accordion-"+index}>
                <Card.Body>
                    <Card.Text>
                        Total Value: {findColumn("total_value").content(item)}
                    </Card.Text>
                    <Card.Text>
                        Overdue: {findColumn("min_overdue_days").content(item)}
                    </Card.Text>
                    <Card.Text>
                        Status: {findColumn("collection_partner_status_display").content(item)}
                    </Card.Text>
                    <Card.Text>
                        Client: {item.placer ?.business_name}
                    </Card.Text>
                    {/*{findColumn("resolve").content(item)}*/}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    })
    }</Accordion>
};

export default MobileTableCollectionPartner;
