import React from "react";
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'

import {Link} from 'react-router-dom'
import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {BetterSelect} from "../common/forms/BetterSelect";
import _ from "lodash";
import {getSetting} from "../../utils/fetchConfiguration";
import {Checkbox} from "../common/forms/Checkbox";

class CreateCaseFromListing extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false,
            loading: false,
            debtColButtonDisabled: false,
            debtColIntegrationEnabled: false,
            debtColCollectionPartner: {},
            debtColCaseNumber: props.debt?.debtcol_case_number,
            selectedClient: {
                name: null,
                code: null
            },
            clients: [],
            createNewClient: false,
            success: null,
            error: null
        }
    }

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        const {data: debtColIntegrationEnabled} = await getSetting("DEBTCOL_INTEGRATION_ENABLED");
        const {data: debtColCollectionPartner} = await http.get(config.API_URL + `/debtcol_clients/`);
        this.setState({debtColCollectionPartner: debtColCollectionPartner[0],
            debtColIntegrationEnabled: debtColIntegrationEnabled[0].value_boolean})
    }

    handleClose = () => {
        this.setState({show: false});
    }

    handleShow = async () => {
        this.setState({show: true});

        // load clients if not already loaded
        if (this.state.clients.length <= 0) {
            this.setState({loading: true});
            const {data: clients} = await http.get(config.API_URL + `/debtcol_clients/clients/`);
            this.setState({clients, loading: false});
        }
    }

    handleRefreshClients = async () => {
        this.setState({loading: true});
        const {data: clients} = await http.get(config.API_URL + `/debtcol_clients/refresh/`);
        this.setState({clients, loading: false});
    }

    handleClientChange = ({currentTarget: input}) => {
        let selectedClient = this.state.selectedClient;
        _.set(selectedClient, input.name, input.value);
        this.setState({selectedClient: selectedClient})
    };

    handleCheckNewClient = () => {
        let createNewClient = this.state.createNewClient
        let selectedClient = !createNewClient ? {  name: null, code: null } : { ...this.state.selectedClient}
        this.setState({createNewClient: !createNewClient, selectedClient})
    }

    handleSendListing = async () => {

        let error = []
        if (error.length > 0) {
            this.setState({error})
        } else {
            this.setState({loading: true, debtColButtonDisabled: true});
            try {
                const data = {"client_code": this.state.selectedClient.code}
                const { data: newClient} = await http.post(config.API_URL + `/debtcol_listings/${this.props.listing.id}/send/`, data);

                if (newClient.ClientCode) {
                    toast.success(`New client created in DebtCol with client code ${newClient.ClientCode}`)
                }

                toast.success("To confirm once this listing has been sent to Debtcol, please refresh this page and you will see the case number here once it's complete.")
                this.setState({show: false});
            } catch (e) {
                toast.error("An unexpected error occurred. Please contact the Debtplacer team", {autoClose: 3000});
                this.setState({loading: false})
            }
        }

    }

    render() {

        if (!this.state.debtColIntegrationEnabled) return <></>;

        const {clients, selectedClient, debtColCollectionPartner,
            debtColCaseNumber, show, loading, error, debtColButtonDisabled, createNewClient} = this.state
        const {listing} = this.props;

        return (
            <>
                {!debtColCollectionPartner.has_debtcol_api_credentials && <p className="info mt-2">
                    <Link to={`/profile`}>Connect your DebtCol account</Link> to send listing data directly to DebtCol.
                </p>}

                {debtColCaseNumber &&
                    <button className="btn btn-secondary btn-block mt-2"
                        disabled>DebtCol Case Number {debtColCaseNumber}
                    </button>}

                {!debtColCaseNumber && listing.debtcol_case_numbers &&
                    <button className="btn btn-secondary btn-block mt-2"
                            disabled>DebtCol Case Numbers {listing.debtcol_case_numbers}
                    </button>}

                {debtColCollectionPartner.has_debtcol_api_credentials && !listing.all_sent_to_debtcol && <button
                    className="btn btn-secondary btn-block mt-2"
                    onClick={this.handleShow}
                    disabled={debtColButtonDisabled}
                >Send to DebtCol <i className="fa fa-plug"/>
                </button>}

                <Modal show={show} onHide={this.handleClose}>

                    {loading &&
                        <div className="spinner-wrapper">
                            <Spinner className="spinner" animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>}

                    <Modal.Header closeButton className={'padding-30'}>
                        <Modal.Title className={'modalTitle'}>Send Listing to DebtCol</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={`padding-30`}>

                        <div className="form-row">
                            <div className="clientList">
                                Select a client in DebtCol:

                                <BetterSelect
                                    data={selectedClient}
                                    name="code"
                                    options={clients || []}
                                    valueProperty="ClientCode"
                                    textProperty="ClientName"
                                    onChange={this.handleClientChange}
                                    emptyLabel="Start typing to search..."
                                    disabled={createNewClient}
                                />

                                {error?.map((err, idx) => <Alert key={idx} variant='danger'>{err}</Alert>)}
                            </div>

                            <div className="refreshButtonWrapper">
                                    <button className="btn btn-tertiary refreshButton"
                                        onClick={this.handleRefreshClients}
                                        title="Refresh Profile List">
                                    <i className="fa fa-rotate-right"/></button>
                            </div>

                        </div>

                        <div className="form-row">
                            <div className="mx-auto">or</div>
                            <Checkbox name="read"
                                         value="read"
                                         checked={createNewClient}
                                         onChange={this.handleCheckNewClient}
                                         label={"Create as new client in Debtcol"}
                            />
                        </div>


                    </Modal.Body>
                    <Modal.Footer>
                        {!listing.sent_to_debtcol && <Button className="btn btn-block" variant="primary"
                                onClick={this.handleSendListing}
                                disabled={loading}>
                            Send to DebtCol
                        </Button>}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

};

export default CreateCaseFromListing;
