import axios from "axios";
import logger from "./logService";
import {toast} from "react-toastify";
import config from "../constants";

const axiosInstance = axios.create({
    baseURL: config.API_URL,
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(null, error => {

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (error.response.status == 423) {
        toast.error("Your account has been locked due to too many failed login attempts. Please try again later or contact our support.");
    } else if (!expectedError) {
        logger.log(error);
        toast.error("An unexpected error occurrred.");
    }

    return Promise.reject(error);
});

export default {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete
};
