
import axios from "axios";

import config from "../constants";


export async function lookupAbn(abn) {
    const lookupUrl = `https://abr.business.gov.au/json/AbnDetails.aspx?abn=${abn}&callback=callback&guid=${config.ABN_LOOKUP_GUID}`;
    const {data} = await axios.get(lookupUrl);
    const jsonString = data.substring(9, data.length - 1);
    const json = JSON.parse(jsonString);
    return abn === json.Abn;
}

export async function lookupBusinessName(name) {
    const lookupUrl = `https://abr.business.gov.au/json/MatchingNames.aspx?name=${encodeURI(name.trim())}&callback=callback&guid=${config.ABN_LOOKUP_GUID}`;
    const {data} = await axios.get(lookupUrl);
    const jsonString = data.substring(9, data.length - 1);
    const json = JSON.parse(jsonString);
    return json.Names;
}
