import React from "react";
import {Redirect} from "react-router-dom";
import auth from "../../services/authService";
import http from "../../services/httpNoAuthService";
import config from "../../constants";
import {toast} from "react-toastify";
import {baseHandleChange} from "../common/forms/helpers";
import {validate} from "../common/forms/validation";
import Form from "../common/forms/Form";
import {renderInput} from "../common/forms/Input";

const Joi = require('joi');

class ForgotPassword extends React.Component {

    state = {
        data: { username: "" },
        submitted: false,
        errors: {},
        formOptions: {
            submitButtonLabel: "Request password reset"
        }
    };

    schema = {
        1: {
            username: Joi.string().email({ tlds: {allow: false} }).required().label("Email")
        }
    };

    handleChange = ({ currentTarget: input }) => {
        const { data, errors } = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({ data, errors });
    };

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        const errors = validate(this.schema, this.state.data);
        this.setState({ errors: errors || {} });
        if (errors) return false;

        return await this.doSubmit();

    };

    doSubmit = async () => {

        try {
            const { data } = this.state;
            await http.post(config.API_URL + "/password_reset/", {
                email: data.username
            });
        } catch (ex) {
            // ignore errors to not give away whether the email address exists
            if (ex.response && ex.response.status === 400) {
                const errors = { ...this.state.errors };
                this.setState({ errors });
            }
        }
        this.setState({ submitted: true });
        // toast.success("Sent successfully if email exists.", {autoClose: 3000});
    };

    render() {
        if (auth.getCurrentUser()) return <Redirect to="/" />;

        const { state } = this.props.location;

        if (state) {
            const { errorMessage, successMessage } = state;

            if (errorMessage) {
                toast.error(errorMessage);
            }
            if (successMessage) {
                toast.success(successMessage, {autoClose: 3000});
            }
        }

        return (
            <div className="container-narrow pt-5">
                <h1>Reset your password</h1>
                {!this.state.submitted &&
                <Form onSubmit={this.handleSubmit}
                      validate={this.handleValidate}
                      options={this.state.formOptions} >
                      {renderInput(this.state.data, this.state.errors, this.handleChange, "username", "Email")}
                </Form>}

                {this.state.submitted &&
                <p>Please check your email for the password reset link.</p>
                }

            </div>
        );
    }
}

export default ForgotPassword;
