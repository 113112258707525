import React from "react";


const Radio = ({type = 'radio', name, id, checked = false, value, onChange, className = "", label, ...rest}) => {
    return className === 'nobox'? (
        <input {...rest} type={type} name={name} id={id}
               defaultChecked={checked} value={value}
           onChange={onChange} className={className} />):(
        <label className={"radio container"}>{label}
            <input {...rest} type={type} name={name} id={id}
                   onChange={onChange} value={value}
                   className={className} defaultChecked={checked} />
            <span className="checkmark"></span>
        </label>
    );
}

class RadioGroup extends React.Component {

    render() {

        const {items, textProperty, valueProperty, onChange, selectedItem, className, ...rest} = this.props;

        return (
            <div className="radio-group">
                {items.map(item => (
                    <div key={item[valueProperty]}>
                        <Radio {...rest}
                               name={item[valueProperty]}
                               id={item[valueProperty]}
                               checked={selectedItem[valueProperty] == item[valueProperty]}
                               onChange={onChange}
                               label={item[textProperty]}
                               className={className}/>
                    </div>
                ))}
            </div>
        );
    }
};

RadioGroup.defaultProps = {
    textProperty: "label",
    valueProperty: "value"
};

export {
    RadioGroup, Radio
};
