import React, { Component } from "react";
import _ from "lodash";

class TableFooter extends Component {

    renderCell = (item, column) => {
        if (column.content) return column.content(item);

        return _.get(item, column.path);
    };

    createKey = (item, column) => {
        return item.id + (column.path || column.key);
    };

    render() {
        const { footerData, columns } = this.props;
        return (
            <tfoot>
            {footerData.map((item, index) => (
                <tr key={"footer-" + index} id={"row-" + index}>
                    {columns.map(column => (
                        <td key={this.createKey(item, column)} className={column.hide ? "d-none" : ""}>
                            {this.renderCell(item, column)}
                        </td>
                    ))}
                </tr>
            ))}
            </tfoot>
        );
    }
}

export default TableFooter;
