import React from "react";
import {Col, ListGroup, OverlayTrigger, Popover, Row} from "react-bootstrap"
import {getInitials} from "../../utils/getInitials";
import {formatCurrency} from "../common/numberFormats";
import {useAccess} from "../../contexts/AccessContext";

const ChatItem = ({chatItem, chat, user, onClick, media}) => {

    const {currentAccessType} = useAccess()

    // let isRead = user.user_type === 'collection_partner' ? chatItem.is_read_by_collection_partner : chatItem.is_read_by_placer
    let isRead = currentAccessType.type === 'collection_partner' ? chatItem.is_read_by_collection_partner : chatItem.is_read_by_placer

    let chatInformation = <>
    {currentAccessType.type === 'collection_partner' ?
        (chatItem.is_read_by_collection_partner ?
            <span className="chat-item-name">{chatItem.placer}</span> :
            <h6>{chatItem.placer}</h6> ) :
        (chatItem.is_read_by_placer ?
            <span className="chat-item-name">{chatItem.collection_partner.business_name}</span> :
            <h6>{chatItem.collection_partner.business_name}</h6> )
    }
    <div className="debtor">
        {formatCurrency(chatItem.listing.total_value) + " | " + chatItem.listing.debtor_names + " | Listing ID " + chatItem.listing.reference}
    </div>
    </>
    return (
        <ListGroup.Item className="padding-5"
                        active={chat ?.id === chatItem.id}
                        onClick={onClick}>
            <Row>
                <Col xs={3} sm={3} xl={2} md={media === 'tablet' ? 12 : 3}
                     style={{textAlign: 'center'}}>
                    <OverlayTrigger placement="right" overlay={
                        <Popover id="popover-basic">
                            <Popover.Content>
                                {chatInformation}
                            </Popover.Content>
                        </Popover>
                    }>
                        <div className={"avatar" + ( !isRead ? " avatar-read" : "")}>
                            <div className="avatar__letters">
                                {getInitials(currentAccessType.type === 'collection_partner' ? chatItem.placer : chatItem.collection_partner.business_name)}
                            </div>
                        </div>
                    </OverlayTrigger>

                </Col>
                {media === 'tablet' ? null : <Col xs={9} sm={9} md={9} xl={10} className={"chat-summary"}>
                    {chatInformation}
                </Col>}
            </Row>
        </ListGroup.Item>
    );
};

export default ChatItem;
