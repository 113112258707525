import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap"

const DPTooltip = ({ content }) => (
    <OverlayTrigger
        delay={{ hide: 0, show: 0 }}
        overlay={(props) => (
            <Tooltip {...props}>{content}</Tooltip>
        )}
        placement="bottom"
    ><i className="info_circle"></i>
    </OverlayTrigger>
);

export {
    DPTooltip
};
