import React from "react";

import config from "../../constants";
import {renderInput} from "../common/forms/Input";
import {renderSelect} from "../common/forms/Select";
import {Checkbox} from "../common/forms/Checkbox";
import _ from "lodash";
import {lookupBusinessName} from "../../utils/abnLookup";
import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'

class UserDetailsStep extends React.Component {

    state = {
        isLoading: false
    };

    constructor(props) {
        super(props);
    }

    render() {

        const {handleChange, errors, data, contactRoles,
            singleSelections, setSingleSelections,
            setSingleInput, registrationType} = this.props;


        return (
            <div className="form-group">
                <h2>Primary Contact</h2>

                <div className={"form-group mb-2 businessName"}>
                    <label htmlFor={name} className={""}>Business Name</label>
                    <AsyncTypeahead
                        id="businessNameLookup"
                        delay={1000}
                        filterBy={() => true}
                        labelKey="text"
                        minLength={3}
                        isLoading={this.state.isLoading}
                        onSearch={async (query) => {
                            this.setState({isLoading: true})
                            let items = await lookupBusinessName(query);
                            const options = items.map((i) => ({
                                text: i.Name + " - " + i.State + " " + i.Postcode,
                                textValue: i.Name,
                                value: i.Abn,
                            }));
                            this.setState({options, isLoading: false})
                            setSingleInput(query)
                        }}
                        options={this.state.options || singleSelections}
                        placeholder="Business Name"
                        onChange={setSingleSelections}
                        selected={singleSelections}
                    />
                </div>

                <div className="form-row">
                    <div className="col-6">
                        {renderInput(data, errors, handleChange, "user.first_name", "First Name")}
                    </div>
                    <div className="col-6">
                        {renderInput(data, errors, handleChange, "user.last_name", "Last Name")}
                    </div>
                </div>

                {renderSelect(data, errors, handleChange, "contact_role", "Your Role", contactRoles)}

                {renderInput(data, errors, handleChange, "phone", "Phone")}
                {renderInput(data, errors, handleChange, "user.username", "Email")}

                {renderInput(data, errors, handleChange, "user.password", "Password", "", "password")}
                {renderInput(data, errors, handleChange, "user.confirm_password", "Confirm Password", "", "password")}

                {registrationType === "placer" &&

                <div className="row">
                    <div className="col-12">
                        <Checkbox
                            name="user.opt_in_to_marketing_emails"
                            id="user.opt_in_to_marketing_emails"
                            checked={_.get(data, "user.opt_in_to_marketing_emails") === true}
                            onChange={handleChange}
                            label={'Receive updates from Debtplacer'}
                            error={errors["opt_in_to_marketing_emails"]}
                        />
                    </div>
                </div>}

                {registrationType === "collection_partner" &&
                <div className="row">
                    <div className="col-12">
                        <Checkbox
                            label={<>I have read and agree to the <a
                                href={config.COLLECTION_PARTNER_TERMS_AND_CONDITIONS_URL} target="_blank">Terms of Use</a>.</>}
                            name="has_accepted_terms"
                            id="has_accepted_terms"
                            checked={_.get(data, "has_accepted_terms") === true}
                            onChange={handleChange}
                            error={errors["has_accepted_terms"]}
                        />
                        <Checkbox
                            label={<>I have read and agree to the <a href={config.PRIVACY_POLICY_URL} target="_blank">Privacy
                                Policy</a>.</>}
                            name="has_accepted_privacy_policy"
                            id="has_accepted_privacy_policy"
                            checked={_.get(data, "has_accepted_privacy_policy") === true}
                            onChange={handleChange}
                            error={errors["has_accepted_privacy_policy"]}
                        />
                    </div>
                </div>}
            </div>
        )
    }

}

export default UserDetailsStep;
