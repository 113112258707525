import React, {Component} from "react";
import {Link} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import OfferFees from "../common/OfferFees";

class Offers extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { offers } = this.props;

        return (
            <div>

                <table className="listingTable table-striped">
                    <thead>
                    <tr>
                        <th>Collection Partner</th>
                        <th>Status</th>
                        <th>Terms</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {offers.map(offer => (
                        <tr key={offer.id} className={offer.status === 'accepted' ? "acceptedOffer" : undefined }>
                            <td><Link to={"/collection-partners/" + offer.collection_partner_id}>{offer.collection_partner_business_name}</Link></td>
                            <td>{offer.status_display}</td>
                            <td><OfferFees offer={offer} /></td>
                            <td>
                                <Link
                                    to={ "/messenger/" + offer.chat}>
                                    <i className="fa fa-envelope-o"/>
                                </Link>
                                </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            </div>
        );
    }
}

export default Offers;
