import React from "react";
import {NavLink} from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AccountNavLinks from "./accountNavLinks";

import logo from "../../img/logo.png";
import logoDark from "../../img/logo-dark.png";
import {collectionPartnerNavItems, placerNavItems} from "./navItems";
import {useAccess} from "../../contexts/AccessContext";


const TopBar = ({user, userStatus, embedded}) => {

    const {switchAccessType, currentAccessType, availableAccessTypes, isPlacer, isCollectionPartner} = useAccess()

    async function handleSwitchAccessType(accessType) {
        switchAccessType(accessType)
        // force page reload
        // window.location.reload()
    }

    return (
        <div className={"topBarContainer" + (embedded ? " embedded" : "")}>
            <Navbar className="navbar-dark" expand="lg">
                <Navbar.Brand href="/">
                    {embedded ? <img src={logoDark} alt="Debt Placer" width="110" height="24"/> :
                    <img src={logo} alt="Debt Placer" width="110" height="24"/>}
                </Navbar.Brand>

                {availableAccessTypes && <div>
                    {availableAccessTypes.length > 1 &&
                        <NavDropdown title={currentAccessType?.name} id="basic-nav-dropdown">
                            {availableAccessTypes.map((accessType, index) => (
                                <NavDropdown.Item key={index}
                                                  onClick={() => handleSwitchAccessType(accessType)}>
                                    {accessType.name}</NavDropdown.Item>
                            ))}
                        </NavDropdown>
                    }
                    {availableAccessTypes.length === 1 && <>
                        {/*{currentAccessType.name}*/}
                    </>}

                </div>}

                {!embedded && <>
                    <Navbar.Toggle className="navbar-toggler"
                                   type="button"
                                   data-toggle="collapse"
                                   data-target="#topNav"
                                   aria-expanded="false"
                                   aria-label="Toggle navigation"
                                   aria-controls="topNav"/>
                    <Navbar.Collapse id="topNav">

                        <Nav className="d-lg-none">

                            {user && isCollectionPartner() && (
                                collectionPartnerNavItems.map((item, index) => (
                                    <NavLink key={index} className="nav-item nav-link" to={item.to}>
                                        {item.label}
                                    </NavLink>
                                ))
                            )}

                            {user && isPlacer() && (
                                placerNavItems.map((item, index) => (
                                    <NavLink key={index} className="nav-item nav-link" to={item.to}>
                                        {item.label}
                                    </NavLink>
                                ))
                            )}

                        </Nav>

                        <Nav className="accountNav">
                            {/* Logged out users */}
                            {!user && (
                                <React.Fragment>
                                    <NavLink className="nav-item nav-link" to="/login">
                                        Login
                                    </NavLink>
                                    <NavLink className="nav-item nav-link" to="/register">
                                        Sign up
                                    </NavLink>
                                </React.Fragment>
                            )}

                            {/* Logged in users */}
                            {user && userStatus && (
                                <React.Fragment>
                                    <NavLink
                                        className="padding-wide d-none d-lg-block nav-item nav-link messenger-link lessPadding"
                                        to="/messenger">
                                        {userStatus.unread_chats === 0 ? <i style={{cursor: "pointer"}}
                                                                            className="envelope-white"
                                                                            aria-hidden="true"/> :
                                            <span className="fa-stack-dark fa-5x has-badge"
                                                  data-count={userStatus.unread_chats}>
                                        <i style={{cursor: "pointer"}}
                                           className="envelope-white"
                                           aria-hidden="true"/>
                                    </span>}
                                    </NavLink>
                                    <NavDropdown title={<i style={{cursor: "pointer"}}
                                                           className="profile-white"
                                                           aria-hidden="true"/>} id="account-dropdown"
                                                 className="padding-wide d-none d-lg-block mx-0 px-0 lessPadding">

                                        <AccountNavLinks></AccountNavLinks>
                                    </NavDropdown>

                                    <div className="d-lg-none">
                                        <NavLink className="dropdown-item" to="/messenger">
                                            Messenger
                                            <span className="fa-stack-dark fa-5x has-badge"
                                                  data-count={userStatus.unread_chats}></span>
                                        </NavLink>
                                    </div>
                                    <div className="d-lg-none">
                                        <AccountNavLinks></AccountNavLinks>
                                    </div>
                                </React.Fragment>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </>}

                {embedded &&
                    <Nav className="embedded">
                    {/* Logged out users */}
                    {!user && (
                            <NavLink className="nav-item nav-link"
                                     to={"/register-client" + window.location.search}
                                     target={"_blank"}>
                                Sign up
                            </NavLink>
                    )}

                    {/* Logged in users */}
                    {user && userStatus && (
                            <NavLink className="nav-item nav-link" to="/embedded/logout">
                                Logout
                            </NavLink>
                    )}
                </Nav>}
            </Navbar>
        </div>
    );
};

export default TopBar;
