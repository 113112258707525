import Result from "../search/Result";
import React from "react";
import {formatCurrency} from "../common/numberFormats";


export default function CompleteListingStep({listing, saleOffer}) {

    return (
        <div className="form-group listingFormFinalise listingFormContainer">
            <h4>Listing Summary</h4>

            {listing.collection_partner_id &&
                <p>You're sending this listing directly to {listing.collection_partner.business_name}.</p>
            }

            <Result key={listing.id} listing={listing} previewMode={true} saleOffer={saleOffer} />

        </div>
    )
}