import React from "react";
import MultiSelect from "../common/forms/MultiSelect";


class CollectionPartnerPreferencesStep extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { handleChange, data,
            industries,
            debtAmountPreferenceOptions,
            commercialTermsPreferenceOptions } = this.props;

        return (
            <div className="form-group">
                <h2>Preferences</h2>

                <MultiSelect
                    options={industries}
                    textProperty="anzsic_division_description"
                    valueProperty="id"
                    value={data.industry_preference}
                    name="industry_preference"
                    label="Industry Preference (Select Multiple)"
                    handleChange={handleChange} />

                <MultiSelect
                    options={debtAmountPreferenceOptions}
                    value={data.debt_amount_preference}
                    name="debt_amount_preference"
                    label="Debt Amount Preference (Select Multiple)"
                    handleChange={handleChange} />

                <MultiSelect
                    options={commercialTermsPreferenceOptions}
                    value={data.commercial_terms_preference}
                    name="commercial_terms_preference"
                    label="Commercial Terms Preference"
                    handleChange={handleChange} />

            </div>
        )
    }

}

export default CollectionPartnerPreferencesStep;
