import React, {Component} from "react";
import FormStepNavLink from "../common/forms/FormStepNavLink";

class CollectionPartnerRegistrationComplete extends Component {

    render() {

        return (
            <div className="container">

                <h1>Become a Collection Partner</h1>

                <div className="FormStepNavigation">
                    <ol>
                        <FormStepNavLink
                            currentStep={5}
                            step={1}
                            label="Primary Contact"
                            setStep={() => {}}
                        />
                        <FormStepNavLink
                            currentStep={5}
                            step={2}
                            label="Business Details"
                            setStep={() => {}}
                        />
                        <FormStepNavLink
                            currentStep={5}
                            step={3}
                            label="Preferences"
                            setStep={() => {}}
                        />
                        <FormStepNavLink
                            currentStep={5}
                            step={4}
                            label="Subscription"
                            setStep={() => {}}
                        />
                        <FormStepNavLink
                            currentStep={5}
                            step={5}
                            noLink={true}
                            label="Complete"
                            setStep={() => {}}
                        />
                    </ol>
                </div>


                <div className="w-50 mx-auto">
                    <p><strong>Thanks for registering</strong></p>

                    <p>Thanks for registering to become a Collection Partner with Debtplacer.</p>

                    <p> Your details are now being verified and you will receive an email confirmation from us
                        within the next 24 hours.</p>

                    <p>We’re excited to have you join the Debtplacer movement and we look forward to facilitating
                        debt resolutions with you soon.</p>

                    <p>The Debtplacer Team</p>
                </div>

            </div>
        );
    }
}

export default CollectionPartnerRegistrationComplete;
