import React from "react";
import {ToastContainer} from "react-toastify";
import TopBar from "../components/layout/TopBar";



export default function EmbeddedLayout({children, user, userStatus}) {

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={false}
            />
            <TopBar user={user} userStatus={userStatus} embedded={true} />

            <div className="mainAndNavContainer container-full">
                <main className="mainContent">
                    {children}
                </main>
            </div>

        </>
    );
}
