import React, {Component} from "react";
import http from "./../../services/httpService";
import config from "../../constants";
import {APIPagination, paginate} from "./../common/pagination";
import _ from "lodash";
import Table from "./../common/table";
import {Input} from "../common/forms/Input";
import {Alert, Spinner} from "react-bootstrap"
import moment from "moment";
import {getCurrentUser, loginAs} from "../../services/authService";

class AdminDashboard extends Component {

    columns = [
        {
            path: "id",
            label: "ID",
            key: "id",
            sort: true
        },
        {
            path: "first_name",
            label: "First Name",
            key: "first_name",
            sort: true
        },
        {
            path: "last_name",
            label: "Last Name",
            key: "last_name",
            sort: true
        },
        {
            path: "username",
            label: "Username",
            key: "username",
            sort: true
        },
        {
            label: "Registered",
            key: "date_joined",
            sort: true,
            path: "date_joined",
            content: user => (
                <span>{moment(user.date_joined).format("DD/MM/YYYY")}</span>
            )
        },
        {
            key: "loginas",
            sort: false,
            content: user => <a href="#" onClick={() => this.handleLoginAs(user)}>
                <i style={{cursor: "pointer"}}
                   className="fa fa-user"
                   aria-hidden="true"/> Login
            </a>
        }
    ];

    state = {
        loading: true,
        users: [],
        currentPage: 1,
        pageSize: 20,
        searchQuery: "",
        sortColumn: {path: "user.username", order: "asc"}
    };

    async componentDidMount() {
        sessionStorage.removeItem('search')
        const {data: users} = await http.get(config.API_URL + "/users_for_admin/");
        this.setState({users, loading: false});
    };

    handlePageChange = page => {
        this.setState({currentPage: page});
    };

    handleSearch = ({target: {value}}) => {
        this.setState({searchQuery: value, selectedStatus: null, currentPage: 1});
    };

    clearFilters = (e) => {
        e.preventDefault();
        this.setState({searchQuery: "", selectedStatuses: [], currentPage: 1});
    };

    handleSort = sortColumn => {
        this.setState({sortColumn});
    };

    isIncludedInSearch = (user, searchQuery) => {
        return (
            (user.username?.toLowerCase().includes(searchQuery.toLowerCase()))
            || user.first_name?.toLowerCase().includes(searchQuery.toLowerCase())
            || user.last_name?.toLowerCase().includes(searchQuery.toLowerCase())
        )
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            users: allUsers
        } = this.state;

        let filtered = allUsers
        if (searchQuery) {
            filtered = allUsers.filter(m => this.isIncludedInSearch(m, searchQuery));
        }

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const users = paginate(sorted, currentPage, pageSize);
        return {totalCount: filtered.length, data: users};
    };

    handleLoginAs = async (user) => {
        await loginAs(user.id);
        window.location = '/'
    }

    render() {
        const {pageSize, currentPage, sortColumn, searchQuery, loading} = this.state;
        const {totalCount, data: users} = this.getPagedData();

        return (
            <div className="container container-wide searchResult pt-0">
                <div className="container-row row">

                    <div className="col-sm-12 col-lg-3 sidebarFilters">
                        <div className="searchFormHeader">
                            <span className="pull-right"><a onClick={this.clearFilters} href="">Clear All</a></span>
                            <h3>Filter</h3>
                        </div>
                        <form className="sidebarSearchForm">
                            <div className="dashboardSearch">
                                <h5>Search</h5>
                                <br/>
                                <Input
                                    type="text"
                                    name="keywords"
                                    value={searchQuery || ""}
                                    label="Enter username, first or last name"
                                    onChange={this.handleSearch}
                                    placeholder="Enter username, first or last name"
                                    iconClass="fa fa-search"
                                    labelClass="sr-only"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-sm-12 col-lg-9 searchResults">
                        <div className="searchResultsHeader">
                            <h2>Admin Dashboard</h2>
                        </div>
                        <div className="searchResultsBody">
                            {loading ? <Spinner className="spinner" animation="border" role="status"/>
                                : totalCount === 0 ? <Alert variant="light">
                                    <div className="emptyStateButtonContainer">
                                        <p>No users found. Adjust your filters.</p>
                                    </div>

                                </Alert> :
                                    <Table
                                        columns={this.columns}
                                        data={users}
                                        sortColumn={sortColumn}
                                        onSort={this.handleSort}
                                    />
                            }
                        </div>
                        <div className="searchResultsPagination">
                            {
                                totalCount === 0 || loading ? null :
                                    <APIPagination currentPage={currentPage} itemsCount={totalCount}
                                                   onPageChange={this.handlePageChange}
                                                   pageSize={pageSize}/>
                            }
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default AdminDashboard;
