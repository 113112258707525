import { createMedia } from "@artsy/fresnel"

const AppMedia = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1025,
        largeScreen: 1920,
    },
    interactions: {
        hover: "(hover: hover)",
        notHover: "(hover: none)",
        landscape: "not all and (orientation: landscape)",
        portrait: "not all and (orientation: portrait)",
    },
})

export const { Media, MediaContextProvider, createMediaStyle } = AppMedia