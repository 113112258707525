import React from "react";
import {Redirect, Route} from "react-router-dom";
import auth from "../services/authService";
import {useAccess} from "../contexts/AccessContext";

const RegistrationRoute = ({ path, component: Component, render, userStatus, emailConfirmed=false, collectionPartnerApproved=false, ...rest }) => {

    const user = auth.getCurrentUser();
    const {currentAccessType} = useAccess()

    return (
            <Route
                {...rest}
                render={props => {
                    if (!user) {
                        return (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: {from: props.location}
                                }}
                            />
                        );
                    }

                    // if the email address needs to be approved to view this route, check and redirect accordingly
                    if (emailConfirmed) {
                        if (userStatus && !userStatus.is_email_confirmed) {
                            const userId = user.user_id
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/confirm-email",
                                        state: {from: props.location, requestEmailConfirmation: true, userId: userId}
                                    }}
                                />
                            );
                        };
                    }

                    // if the collection partner needs to be approved to view this route, check and redirect accordingly
                    if (collectionPartnerApproved && currentAccessType.type === 'collection_partner') {
                        if (userStatus && userStatus.collection_partner_status !== 'approved') {
                            const userId = user.user_id
                            return (
                                <Redirect
                                    to={{
                                        pathname: "/register-collection-partner-complete",
                                        state: {from: props.location, requestEmailConfirmation: true, userId: userId}
                                    }}
                                />
                            );
                        };
                    }

                    return Component ? <Component {...props} /> : render(props);
                }}
            />
  );
};

export default RegistrationRoute;
