import {React, useEffect, useState} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {APIPagination, paginate} from "../common/pagination";
import _ from "lodash";
import {filterWithArray} from "../../utils/filtering";
import Table from "../common/table";
import {MobileTablePlacerListings} from "../common/mobileTablePlacer";
import {Link} from "react-router-dom";
import {overdueDisplay} from "../../utils/listingRenderers";
import {Input} from "../common/forms/Input";
import {Alert, Badge, Button, Spinner} from "react-bootstrap"
import {getOptionSet} from "../../utils/fetchConfiguration";
import {Media} from '../../AppMedia'
import MoreOptionsNav from "../common/moreOptionsNav";
import {toast} from "react-toastify";

import '../../listings.scss'
import {useAccess} from "../../contexts/AccessContext";

var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});


const statusTabs = [
    {
        status: null,
        label: "All Listings"
    },
    {
        status: "listed",
        label: "Active"
    },
    {
        status: "resolved",
        label: "Resolved"
    },
    {
        status: "expired",
        label: "Expired"
    },
    {
        status: "trash",
        label: "Trash"
    },
]

export default function Listings({userStatus}) {

    const [loading, setLoading] = useState(true);
    const [listings, setListings] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [resolvedAmountOptions, setResolvedAmountOptions] = useState([]);
    const [unresolvedReasonOptions, setUnresolvedReasonOptions] = useState([]);
    const [sortColumn, setSortColumn] = useState({path: "debtor.name", order: "asc"})

    const {isPlacerOwner, getCurrentPlacerId} = useAccess()
    const placerId = getCurrentPlacerId()

    const columns = [
        {
            path: "reference",
            label: "Listing ID",
            key: "reference",
            sort: true,
            content: listing => (<Link to={`/listings/${listing.id}`}> {listing.reference} </Link>)
        },
        {
            path: "debtor_names_abbr",
            label: "Debtor",
            key: "debtor_names",
            sort: true
        },
        {
            // for search only, will be hidden
            path: "debtor_names",
            label: "Debtor",
            key: "debtor_names_search",
            sort: true,
            hide: true
        },
        {
            label: "Total Value",
            key: "total_value",
            sort: true,
            path: "total_value",
            content: listing => (
                <span>{formatter.format(listing.total_value)}</span>
            )
        },
        {
            label: "Overdue",
            key: "overdue",
            sort: true,
            path: "min_overdue_days",
            content: listing => (
                overdueDisplay(listing)
            )
        },
        {
            path: "status_display",
            label: "Status",
            key: "status_display",
            sort: true,
            content: listing => {
                let status_display = listing.status_display;
                if (listing.status === 'listed') {
                    if (listing.pending_offers_count === 1) status_display += ` (${listing.pending_offers_count} Offer)`;
                    else status_display += ` (${listing.pending_offers_count} Offers)`;
                }
                return <Badge bsPrefix={"status"} variant={listing.status}> {status_display}</Badge>;

            }
        },
        {
            key: "actions",
            sort: false,
            content: listing => {
                return (
                    <div className="listingsActions">
                        <Link
                            to={`/messenger${listing.status === 'offer_accepted' && listing.chats.length >= 1 ? "/" + listing.chats[0] : '?listing=' + listing.id}`}>
                            {listing.has_unread_chats ?
                                <span className="fa-stack fa-5x has-badge">
                                    <i style={{cursor: "pointer"}}
                                       className="envelope"
                                       aria-hidden="true"/>
                                                </span>

                                :
                                <i style={{cursor: "pointer"}}
                                   className="envelope"
                                   aria-hidden="true"/>

                            }
                        </Link>
                        <MoreOptionsNav>
                            <Link to={`/listings/${listing.id}/edit`}>Edit listing</Link>
                            <Link to={`/listings/${listing.id}/edit?step=2`}>Add missing details</Link>
                            <Link to={`/listings/${listing.id}/edit?step=4`}>Add a document</Link>
                        </MoreOptionsNav>
                    </div>
                )
            }
        },
        {
            key: "link",
            sort: false,
            content: listing => <Link className="listingDetail" to={`/listings/${listing.id}`}>
                <i style={{cursor: "pointer"}} className="chevron"/> </Link>
        },
        {
            path: "days_since_published",
            label: "Published",
            key: "days_since_published",
            sort: true,
            hide: true
        }
    ];

    useEffect(async () => {
        sessionStorage.removeItem('search')
        await loadData()
    }, [placerId]);

    async function loadData() {
        if (!placerId) return
        setLoading(true)

        const {data: listings} = await http.get(config.API_URL + "/placer_listings/active_listings/");
        setListings(listings)

        const {data: resolvedAmountOptions} = await getOptionSet("RESOLVED_AMOUNT");
        setResolvedAmountOptions(resolvedAmountOptions[0].options)

        const {data: unresolvedReasonOptions} = await getOptionSet("UNRESOLVED_REASON");
        setUnresolvedReasonOptions(unresolvedReasonOptions[0].options)
        setLoading(false)
    }

    function handlePageChange(page) {
        setCurrentPage(page)
        // potentially in the future also paginate the API request
    }

    function handleSearch({target: {value}}) {
        setSearchQuery(value)
        setSelectedStatus(null)
        setCurrentPage(1)
    }

    async function handleEmptyTrash(e) {
        try {
            await http.post(config.API_URL + '/placer_listings/empty_trash/');
            toast.success("Trash emptied.", {autoClose: 3000});
            const {data: newListings} = await http.get(config.API_URL + "/placer_listings/active_listings/");
            setListings(newListings)
        } catch (ex) {
            // console.log(ex)
            if (ex.response && ex.response.status === 400) {
                toast.error("Error.");
            }
        }
    }

    function handleSort(sortColumn) {
        setSortColumn(sortColumn)
    }

    function isIncludedInSearch(listing, searchQuery) {
        // add more search lookups here when needed
        return (
            (listing.debtor_names?.toLowerCase().includes(searchQuery.toLowerCase()))
            || listing.collection_partner?.business_name?.toLowerCase().includes(searchQuery.toLowerCase())
            || listing.debtor_abns.toLowerCase().includes(searchQuery.toLowerCase())
            || listing.debtor_industries.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }

    function getPagedData() {

        const allListings = listings

        // let filtered = allListings.map(listing => {
        //     listing.total_value = Number(listing.total_value)
        //     return listing
        // });

        let filtered
        if (searchQuery) {
            filtered = allListings.filter(m => isIncludedInSearch(m, searchQuery));
        } else if (selectedStatus)
            filtered = filterWithArray(allListings, [selectedStatus], "status");
        else
            filtered = filterWithArray(allListings, ['listed', 'resolved', 'expired', 'draft'], "status");

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const pagedListings = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, pagedListings};
    }

    const {totalCount, pagedListings} = getPagedData()

    return (
        <div className="listings pt-0">

            <div className="listingsResult">

                {isPlacerOwner() && !userStatus?.bank_details_completed &&
                    <Alert variant='danger'>Please add your payout details to your profile before creating a debt
                        listing - <Link to={`/profile?edit=bankDetails`}>Add bank details</Link> </Alert>}

                <div className="listingsHeader">
                    <div className="pull-right">
                        {selectedStatus === "trash" &&
                            <Button
                                className="btn-delete danger"
                                style={{"marginRight": "15px"}}
                                onClick={handleEmptyTrash}
                                variant="danger">
                                <i className="fa fa-trash"/> &nbsp;
                                Empty trash
                            </Button>}

                        {userStatus?.bank_details_completed && <Link to={`/listings/new`}>
                            <button className="btn btn-primary newButton"> New listing</button>
                        </Link>}
                    </div>
                    <h2>Active Listings ({listings.length} total) </h2>
                </div>

                <div className="filterTabs">
                    <ul>
                        {statusTabs.map((item, index) => {
                            return <li key={index}
                                       className={item.status === selectedStatus ? "active" : ""}
                                       onClick={() => setSelectedStatus(item.status)}>{item.label}</li>
                        })}
                    </ul>
                </div>

                <div className="listingsSearchAndFilters">
                    <form>
                        <div className="form-row dashboardSearch">
                            <Input
                                type="text"
                                name="keywords"
                                value={searchQuery || ""}
                                label="Search by debtor or collection partner"
                                onChange={handleSearch}
                                placeholder="Search by debtor name, industry, or ABN"
                                iconClass="fa fa-search"
                                labelClass="sr-only"
                            />
                        </div>
                    </form>
                </div>

                <div className="listingsBody">

                    {loading ? <Spinner className="spinner" animation="border" role="status"/>
                        : totalCount === 0 ? <Alert variant="light">
                            <div className="emptyStateButtonContainer">

                                <p>No listings found. Adjust your filters or</p>

                                <Link to={`/listings/new`}>
                                    <button className="btn btn-secondary newButton"> Create your first listing
                                    </button>
                                </Link>
                            </div>

                        </Alert> : <>
                            <Media at='mobile'>
                                <MobileTablePlacerListings
                                    columns={columns}
                                    data={pagedListings}
                                    sortColumn={sortColumn}
                                    onSort={handleSort}
                                />
                            </Media>
                            <Media greaterThan='mobile'>
                                <Table
                                    columns={columns}
                                    data={pagedListings}
                                    sortColumn={sortColumn}
                                    onSort={handleSort}
                                />
                            </Media></>
                    }
                </div>
                <div className="listingsPagination">
                    {
                        totalCount === 0 || loading ? null :
                            <APIPagination currentPage={currentPage} itemsCount={totalCount}
                                           onPageChange={handlePageChange}
                                           pageSize={pageSize}/>
                    }
                </div>

            </div>

        </div>

    )
}

