import _ from "lodash";
import {validateProperty, validatePropertyWithoutSteps} from "./validation";


function baseHandleChange (input, dataIn, errorsIn, schema, currentStep=1) {
    const errors = { ...errorsIn };
    const errorMessage = validateProperty(input, currentStep, schema, dataIn);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...dataIn };

    if (input.type === 'checkbox') {
        _.set(data, input.name, input.checked);
    } else {
        _.set(data, input.name, input.value);
    }
    return { data, errors }

}

function baseHandleAddressChange (dataIn, metaData, addressPathBase="") {
    const data = { ...dataIn };
    _.set(data, addressPathBase + "address_1", metaData.address_line_1);
    _.set(data, addressPathBase + "address_2", metaData.address_line_2);
    _.set(data, addressPathBase + "suburb", metaData.locality_name);
    _.set(data, addressPathBase + "state", metaData.state_territory);
    _.set(data, addressPathBase + "zip_code", metaData.postcode);
    return { data }
}


export {
    baseHandleChange,
    baseHandleAddressChange
}
