import React from "react";
import {Input} from "../common/forms/Input";
import {renderCheckbox} from "../common/forms/Checkbox";
import {Modal} from "react-bootstrap";
import {toast} from "react-toastify";

import http from "../../services/httpService";
import _ from "lodash";
import {filterSearchParams} from "../../utils/search";
import {formatCurrency} from "../common/numberFormats";
import {validatePropertyWithoutSteps, validateWithoutSteps} from "../common/forms/validation";

const Joi = require('joi');
import config from "../../constants";
const apiPath = "/saved_searches/";

import '../../forms.scss';


class SaveSearch extends React.Component {

    state = {
        showModal: false,
        data: {
            title: "",
            params: null,
            email_notifications: true,
            sms_notifications: true
        },
        errors: []
    }

    schema = {
        title: Joi.string().max(500)
    };

    componentDidMount() {};

    getDefaultTitle() {
        return "Debts up to " + formatCurrency(this.props.searchParams.amount_max, 0);
    }

    handleChange = ({ currentTarget: input }) => {

        const { data, errors } = this.state;
        const errorMessage = validatePropertyWithoutSteps(input, this.schema, data);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        if (input.type === 'checkbox') {
            _.set(data, input.name, input.checked);
        } else {
            _.set(data, input.name, input.value);
        }

        this.setState({ data })
    };

    handleValidate = () => {
        return validateWithoutSteps(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {
        try {
            const savedSearch = await http.post(config.API_URL + apiPath, this.state.data);
            toast.success("Search saved.", {autoClose: 3000});
            this.handleClose();
        } catch (ex) {
            toast.error("Unexpected error");
        }
    }

    handleOpen = (e) => {

        // set state (might not be available on componentDidMount)
        const { data } = this.state;
        const filteredParams = filterSearchParams(this.props.searchParams);
        data.title = this.getDefaultTitle();
        data.params = data.params = new URLSearchParams(filteredParams).toString();

        this.setState({ showModal: true, data });
    }

    handleClose = (e) => {
        this.setState({ showModal: false });
    }

    render() {

        const { data, errors, showModal } = this.state;

        return (
            <React.Fragment>

                <button className="btn btn-secondary" onClick={this.handleOpen} >Save Search <i className="fa fa-star-o" /></button>

                <form className="saveSearch" onSubmit={this.handleSubmit}>
                    <Modal show={showModal} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Save Your Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                                <div className="form-row">
                                    <Input
                                        type="text"
                                        name="title"
                                        value={data.title}
                                        label="Name your saved search"
                                        onChange={this.handleChange}
                                        error={errors.title}
                                    />
                                </div>

                                <div className="form-row">
                                    Get notified of new results
                                    <div className="checkbox">
                                        {renderCheckbox(data, errors, this.handleChange, "email_notifications", "Email")} <br/>
                                    </div>
                                    <div className="checkbox">
                                        {renderCheckbox(data, errors, this.handleChange, "sms_notifications", "SMS")}
                                    </div>
                                </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                disabled={this.handleValidate()}
                                className="btn btn-primary btn-block"
                                type="button" onClick={this.handleSubmit}>
                                Save
                            </button>
                        </Modal.Footer>
                    </Modal>
                </form>

            </React.Fragment>
        );
    }
}

export default SaveSearch;
