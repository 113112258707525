import React from "react";

const FormStepNavLink = props => {

    const { step, currentStep, label, setStep, noLink, media } = props;

    let classes = "";
    if (step === currentStep) classes = "current";
    if (step < currentStep) classes += " previous";

    return (
        <li key={step}
            onClick={() => {if (!noLink) setStep(step)}}
            className={classes}>
            <span>{media ==='mobile' || media ==='tablet' ?"":label}</span></li>
    );
};

export default FormStepNavLink;
