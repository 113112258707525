import React from "react";
import {toast} from "react-toastify";
import _ from "lodash";
import {GoogleReCaptcha} from 'react-google-recaptcha-v3';

import http from "../../services/httpService";
import httpNoAuth from "../../services/httpNoAuthService";
import {lookupAbn} from "../../utils/abnLookup";
import {getIndustries, getOptionSets, getPlans} from "../../utils/fetchConfiguration";
import {passwordErrorMessage, passwordRegEx, urlErrorMessage, urlRegEx} from "../../utils/regularExpressions";
import {baseHandleAddressChange, baseHandleChange} from "../common/forms/helpers";
import {validate, validateStep} from "../common/forms/validation";
import Form from "../common/forms/Form";
import FormStepNavLink from "../common/forms/FormStepNavLink";
import FormStep from "../common/forms/FormStep";

import '../../forms.scss';
import UserDetailsStep from "./UserDetailsStep";
import BusinessDetailsStep from "./BusinessDetailsStep";
import auth, {getCurrentUser} from "../../services/authService";
import SubscriptionStep from "./SubscriptionStep";
import CollectionPartnerPreferencesStep from "./CollectionPartnerPreferencesStep";

const Joi = require('joi');
import config from "../../constants";

const apiPath = "/collection_partner_registration/";
import {RouteLeavingGuard} from "../../routing/RouteLeavingGuard";
import {Media} from '../../AppMedia'

class CollectionPartnerRegistration extends React.Component {

    state = {
        // Collection Partner
        data: {
            id: null,
            user: {
                first_name: "",
                last_name: "",
                username: "",
                is_registration_complete: false,
                password: "",
                confirm_password: ""
            },
            business_name: "",
            abn: null,
            address_1: "",
            address_2: "",
            suburb: "",
            zip_code: "",
            state: "",
            contact_role: null,
            phone: "",
            website: "",
            industry_preference: [],
            debt_amount_preference: [],
            commercial_terms_preference: [],
            has_accepted_terms: false,
            has_accepted_privacy_policy: false
        },
        subscription: null,
        contactRoles: [],
        industries: [],
        plans: [],
        debtAmountPreferenceOptions: [],
        commercialTermsPreferenceOptions: [],
        abnValidatedClass: "",
        addressFinderWidget: null,
        addressPathBase: "",
        errors: {},
        // Plan selection (will get preset based on URL in loadData)
        selectedPlan: "Collection-Partner-Fees-AUD-Monthly",
        // form logic & config
        currentStep: 1,
        numberOfSteps: 4,
        formOptions: {
            nextButtonLabel: "Next",
            submitButtonLabel: "Pay & Complete",
            previousButtonLabel: "Back"
        },
        // Chargebee
        cbInstance: null,
        cbError: "",
        cbLoading: "",
        isBlocking: false,
        token: null // ReCaptcha token
    };

    schema = {
        1: {
            user: {
                first_name: Joi.string(),
                last_name: Joi.string(),
                username: Joi.string()
                    .required()
                    .email({ tlds: {allow: false} })
                    .label("Email"),
                password: Joi.string().regex(passwordRegEx).allow(null, '').label("Password")
                    .messages({ "string.pattern.base": passwordErrorMessage }),
                confirm_password: Joi.any()
                    .valid(Joi.ref('password')).label("Confirm Password")
                    .messages({ "any.only" : "Passwords must match" })
            },
            phone: Joi.string().allow(null).label("Phone"),
            business_name: Joi.string().required(),
            has_accepted_terms: Joi.bool().invalid(false),
            has_accepted_privacy_policy: Joi.bool().invalid(false)
        },
        2: {
            abn: Joi.string().required().label("ABN"),
            address_1: Joi.string(),
            address_2: Joi.string().optional().allow(null, ''),
            suburb: Joi.string().required().label("Suburb"),
            zip_code: Joi.string().length(4).label("Post Code"),
            state: Joi.string().max(4),
            website: Joi.string().regex(urlRegEx, {}).allow(null, '')
                .messages({
                    "object.regex": urlErrorMessage,
                    "string.pattern.base": urlErrorMessage
                })
                .label("Website")
        },
        3: {},
        4: {}
    };

    async componentDidMount() {

        // initialise Chargebee instance
        this.setState({
            cbInstance: window.Chargebee.init({
                site: config.CHARGEBEE_SITE
            })
        })

        await this.loadData();
    };

    onVerify = (token) => {
        // re-captcha verification
        this.setState({token})
    };

    handleChange = ({currentTarget: input}) => {
        const {data, errors} = baseHandleChange(input, this.state.data, this.state.errors, this.schema, this.state.currentStep);
        this.setState({data, errors, isBlocking: true});
    };

    handleAddressChange = (fullAddress, metaData) => {
        const {data} = baseHandleAddressChange(this.state.data, metaData);
        this.setState({data, isBlocking: true});
    }

    handleSelect = (selectedOptions, name) => {
        const data = this.state.data;
        _.set(data, name, selectedOptions);
        this.setState({data});
    }

    handleCheckout = async (event) => {

        const doAfterLastStep = this.doAfterLastStep

        const {selectedPlan, subscription} = this.state;
        const selectedPlanDetails = this.getSelectedPlanDetails();

        // create free subscription in backend
        if (selectedPlanDetails.price_per_month <= 0) {
            await this.createSubscription(null);
        }

        // skip checkout if free plan selected or subscription already set up
        if (selectedPlanDetails.price_per_month <= 0 || subscription) {
            return await doAfterLastStep();
        }

        this.setState({cbLoading: true});
        this.state.cbInstance.openCheckout({
            hostedPage: () => {
                var data = {
                    collection_partner_id: this.state.data.id,
                    plan_id: selectedPlan
                };
                return http.post(config.API_URL + "/subscriptions/generate_checkout_new_url/",
                    data).then((response) => response.data)
            },
            success: async (hostedPageId) => {
                await this.createSubscription(hostedPageId);
                await doAfterLastStep(); // complete
            },
            close: () => {
                this.setState({cbLoading: false});
            },
            step(step) {
            }
        });
    }

    createSubscription = async (hostedPageId) => {
        let data = {
            hosted_page_id: hostedPageId,
            chargebee_plan_id: this.state.selectedPlan
        }
        const subscription = await http.post(config.API_URL + "/subscriptions/", data);
        // set state if needed later on
    }

    getSelectedPlanDetails = () => {
        const {selectedPlan, plans} = this.state
        return plans.find(({chargebee_id}) => chargebee_id === selectedPlan);
    }

    setStep = (index) => {
        this.setState({currentStep: index});
    };

    setStepLink = (index) => {
        if (this.state.currentStep > index)
            this.setState({currentStep: index});
    };

    handleValidateStep = (stepIndex) => {
        const errors = validateStep(stepIndex, this.schema, this.state.data)
        return errors;
    }

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        let errors = {}
        if (this.state.currentStep < this.state.numberOfSteps) {
            errors = validateStep(this.state.currentStep, this.schema, this.state.data);
        } else {
            // last step
            errors = validate(this.schema, this.state.data);
        }

        this.setState({errors: errors || {}});
        if (errors) return false;

        // on last step, start Chargebee checkout
        if (this.state.currentStep >= this.state.numberOfSteps) {
            await this.handleCheckout(e)
        }

        return await this.doSubmit();
    };

    doSubmit = async () => {
        if (this.state.data.id) return await this.updateCollectionPartner()
        return await this.createCollectionPartner()
    };

    doAfterLastStep = async () => {
        try {
            // finalise registration
            const {data: collectionPartner} = await http.post(config.API_URL + apiPath + this.state.data.id + "/complete/");
            this.setState({ data: collectionPartner });

            // redirect
            window.location = "/register-collection-partner-complete";

        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                toast.error("Unexpected error");
                this.setState({errors});
                return false;
            }
        }
    }

    async loadData() {

        // collection partner & subscription
        let collectionPartnerId = this.props.match.params.id;
        if (!collectionPartnerId) {
            collectionPartnerId = localStorage.getItem("collection_partner_id");
        }

        let collectionPartner = this.state.data;
        let subscription = null;
        let {selectedPlan, selectedTerm, currentStep} = this.state;

        if (collectionPartnerId) {
            const resp = await http.get(config.API_URL + apiPath + collectionPartnerId + "/");
            collectionPartner = resp.data;

            // subscription
            try {
                const subResp = await http.get(config.API_URL + "/subscriptions/latest");
                if (subResp.data) {
                    subscription = subResp.data;
                    selectedPlan = subscription.chargebee_plan_id;
                    selectedTerm = subscription.term;
                }
            } catch (ex) {
                if (ex.response && ex.response.status !== 404) {
                    const errors = {...this.state.errors};
                    toast.error("Unexpected error");
                    this.setState({errors});
                    return false;
                }
            }

            currentStep = 2; // go straight to step 2 if collection partner already created
        }

        // pre-select plan based on URL
        if (!subscription) {
            const plan = new URLSearchParams(this.props.location.search).get("plan");
            if (plan) {
                selectedPlan = plan;
                if (plan.toLowerCase().includes("yearly")) selectedTerm = "ANNUAL";
            }
        }

        // config data
        const {data: optionSets} = await getOptionSets("CONTACT_ROLES,DEBT_AMOUNT_PREFERENCE,COMMERICAL_TERMS_PREFERENCE");

        let contactRoles;
        let debtAmountPreferenceOptions;
        let commercialTermsPreferenceOptions;

        for (let i = 0; i < optionSets.length; i++) {
            const optionSet = optionSets[i];
            switch (optionSet.code) {
                case "CONTACT_ROLES":
                    contactRoles = optionSet.options;
                    break;
                case "DEBT_AMOUNT_PREFERENCE":
                    debtAmountPreferenceOptions = optionSet.options;
                    break;
                case "COMMERICAL_TERMS_PREFERENCE":
                    commercialTermsPreferenceOptions = optionSet.options;
                    break;
            }
        }

        const {data: industries} = await getIndustries();
        const {data: plans} = await getPlans();

        this.setState({
            data: collectionPartner,
            subscription: subscription,
            selectedPlan: selectedPlan,
            selectedTerm: selectedTerm,
            contactRoles: contactRoles,
            industries: industries,
            plans: plans,
            debtAmountPreferenceOptions: debtAmountPreferenceOptions,
            commercialTermsPreferenceOptions: commercialTermsPreferenceOptions,
            currentStep: currentStep
        });
    }

    createCollectionPartner = async () => {

        if (!this.state.token) return false;

        try {

            const {data} = this.state;

            delete data.user.confirm_password // does not need to be sent to API

            const {data: collectionPartner} = await httpNoAuth.post(config.API_URL + apiPath, data);

            this.setState({data: collectionPartner, isBlocking: false});

            // login the new user
            await auth.login(data.user.username, data.user.password);

            return true;

        } catch (ex) {

            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};

                if (ex.response.data.user && ex.response.data.user.password) {
                    toast.error(ex.response.data.user.password[0]);
                }
                else if (ex.response.data.message && ex.response.data.message.includes("duplicate key value violates unique constraint")) {
                    errors["username"] = "This email address is already registered. Please try logging in instead.";
                    toast.error(errors["username"]);
                } else {
                    toast.error("Unexpected error");
                }

                this.setState({errors});
                return false;
            }
        }
    }

    updateCollectionPartner = async () => {
        try {
            const {data} = this.state

            if (!data.user.password) delete data.user.password; // password will be empty on subsequent loads
            delete data.user.confirm_password // does not need to be sent to API

            const {data: collectionPartner} = await http.patch(config.API_URL + apiPath + data.id + "/", data);
            this.setState({data: collectionPartner, isBlocking: false});

            return true;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Error.");
                return false;
            }
        }
    }

    validateOnSubmit = async () => {
        return await this.validateAbn();
    };

    validateAbn = async () => {

        // ABN validation
        const abn = this.state.data.abn;
        const abnValid = await lookupAbn(abn);

        if (abnValid) {
            this.setState({abnValidatedClass: "valid"})
            return null;
        }

        this.setState({abnValidatedClass: "invalid"})
        return {'abn': "Please enter a valid ABN"};
    };

    setSingleSelections(singleSelections) {

        let data = this.state.data
        if (singleSelections[0]) {
            data.abn = singleSelections[0].value
            data.business_name = singleSelections[0].textValue
        }
        // replace text with textValue in single selections
        singleSelections.forEach(option => {
            option.text = option.textValue
        });
        this.setState({data, singleSelections})
    }

    setSingleInput(singleInput) {
        let data = this.state.data
        if (singleInput) {
            data.business_name = singleInput
        }
        let singleSelections = [{value: data.abn, text: singleInput}]
        this.setState({data, singleSelections})
    }

    render() {

        const user = auth.getCurrentUser();

        let registrationForm = (media) => (
            <div className="container">
                <RouteLeavingGuard
                    when={this.state.isBlocking}
                    navigate={path => this.props.history.push(path)}
                    shouldBlockNavigation={location => {
                        return this.state.isBlocking
                    }}
                />
                <Form className="registrationForm"
                      numberOfSteps={this.state.numberOfSteps}
                      currentStep={this.state.currentStep}
                      setStep={this.setStep}
                      onSubmit={this.handleSubmit}
                      validateStep={this.handleValidateStep}
                      validate={this.handleValidate}
                      options={this.state.formOptions}
                      hideNextButtonOnLastStep={true}
                >
                    <h1>Become a Collection Partner</h1>

                    <div className="FormStepNavigation">
                        <ol>
                            <FormStepNavLink
                                currentStep={this.state.currentStep}
                                step={1}
                                label="Primary Contact"
                                media={media}
                                setStep={this.setStepLink}
                            />
                            <FormStepNavLink
                                currentStep={this.state.currentStep}
                                step={2}
                                label="Business Details"
                                media={media}
                                setStep={this.setStepLink}
                            />
                            <FormStepNavLink
                                currentStep={this.state.currentStep}
                                step={3}
                                label="Preferences"
                                media={media}
                                setStep={this.setStepLink}
                            />
                            <FormStepNavLink
                                currentStep={this.state.currentStep}
                                step={4}
                                label="Fees"
                                media={media}
                                setStep={this.setStepLink}
                            />
                            <FormStepNavLink
                                currentStep={this.state.currentStep}
                                step={5}
                                noLink={true}
                                label="Complete"
                                media={media}
                                setStep={this.setStepLink}
                            />
                        </ol>
                    </div>
                    <FormStep stepIndex={1} currentStep={this.state.currentStep}>
                        <UserDetailsStep
                            registrationType={"collection_partner"}
                            singleSelections={this.state.singleSelections}
                            setSingleSelections={this.setSingleSelections.bind(this)}
                            setSingleInput={this.setSingleInput.bind(this)}
                            data={this.state.data}
                            errors={this.state.errors}
                            contactRoles={this.state.contactRoles}
                            handleChange={this.handleChange}
                        />
                        {!user && <GoogleReCaptcha onVerify={this.onVerify} />}
                    </FormStep>
                    <FormStep stepIndex={2} currentStep={this.state.currentStep}>
                        <BusinessDetailsStep
                            registrationType={"collection_partner"}
                            data={this.state.data}
                            errors={this.state.errors}
                            abnValidatedClass={this.state.abnValidatedClass}
                            validateAbn={this.validateAbn}
                            handleChange={this.handleChange}
                            handleAddressChange={this.handleAddressChange}
                        />
                    </FormStep>
                    <FormStep stepIndex={3} currentStep={this.state.currentStep}>
                        <CollectionPartnerPreferencesStep
                            data={this.state.data}
                            errors={this.state.errors}
                            industries={this.state.industries}
                            debtAmountPreferenceOptions={this.state.debtAmountPreferenceOptions}
                            commercialTermsPreferenceOptions={this.state.commercialTermsPreferenceOptions}
                            handleChange={this.handleSelect}
                        />
                    </FormStep>
                    <FormStep stepIndex={4} currentStep={this.state.currentStep}>
                        <h2 className="sr-only">Subscription</h2>
                        <SubscriptionStep
                            data={this.state.data}
                            selectedPlan={this.state.selectedPlan}
                            plans={this.state.plans}
                            errors={this.state.errors}
                        />
                    </FormStep>
                </Form>
            </div>
        );
        return <>
        <Media at='mobile'>
            {registrationForm("mobile")}
        </Media>
        <Media at='tablet'>
            {registrationForm('tablet')}
        </Media>
        <Media greaterThan='tablet'>
            {registrationForm("others")}
        </Media></>
    }
}

export default CollectionPartnerRegistration;
