import React from "react";
import Pluralize from 'react-pluralize'
import {listedDate} from "../common/dateFormats";
import {formatAbn, formatCurrency} from "../common/numberFormats";

import calendarIcon from "../../img/calendar.png";
import checkIcon from "../../img/check-square.png";
import warningIcon from "../../img/warning.png";
import moment from "moment";


const Result = ({listing, findOutMore, previewMode, saleOffer}) => {

    var dateListed = listedDate(listing.published_date);
    var buttonLabel = "Find out more >";

    if (listing.collection_partner_status === 'offer_pending')
        buttonLabel = "Offer Pending >";

    if (listing.collection_partner_status === 'offer_accepted')
        buttonLabel = "Offer Accepted >";

    if (listing.status === 'draft') {
        dateListed = listedDate(moment());
    }

    let debt = null
    if (listing.number_of_debts === 1) {
        debt = listing.debts[0]
    }

    return (
        <div className="result">

            <div className="row">
                <div className="col-sm-9">
                    <div className="resultHeader">
                        {previewMode || <span className="pull-right">{dateListed}</span>}

                        <h2>{listing.debtor_names_abbr}</h2>

                        {listing.number_of_debts === 1 &&
                            <React.Fragment>
                                {debt.debtor.debtor_type === 'business' &&
                                    <div className="debtor">
                                        ABN: {formatAbn(debt.debtor.abn)} | {debt.debtor.industry}
                                    </div>}
                                {debt.debtor.debtor_type === 'individual' &&
                                    <div className="debtor">
                                        Individual
                                    </div>}
                            </React.Fragment>}

                        {listing.number_of_debts > 1 &&
                            <div>{listing.placer.industry}</div>}

                    </div>
                    <div className="padding-10-left-right row resultBody">
                        <div className="padding-5-left-right col-md-6 col-lg-4 summaryContainer">
                            <div className="summaryContainerIcon">
                                <img src={calendarIcon} width="23" height="23"/>
                            </div>
                            <div className="summaryContainerContent">


                                <strong>
                                    {listing.min_overdue_days != listing.max_overdue_days
                                        ? <>{listing.min_overdue_days} - {listing.max_overdue_days} days</>
                                        : <>{listing.min_overdue_days} days</>
                                    }
                                </strong><br/>
                                Overdue date range
                            </div>

                        </div>

                        {listing.is_personally_guaranteed &&
                            <div className="padding-5-left-right col-md-6 col-lg-4 summaryContainer">
                                <div className="summaryContainerIcon">
                                    <img src={checkIcon} width="23" height="23"/>
                                </div>
                                <div className="summaryContainerContent">
                                    <strong>Personally guaranteed</strong><br/>
                                    Debt type
                                </div>
                            </div>}

                        {listing.is_disputed &&
                            <div className="padding-5-left-right col-md-6 col-lg-4 summaryContainer">
                                <div className="summaryContainerIcon">
                                    <img src={warningIcon} width="23" height="23"/>
                                </div>
                                <div className="summaryContainerContent">
                                    <strong>Disputed</strong><br/>
                                    This debt is being disputed
                                </div>
                            </div>}

                    </div>
                </div>

                <div className="resultSummary col-sm-3 padding-10">

                    <span className="totalValue">{formatCurrency(listing.total_value)}</span><br/>
                    Total Debt (<span><Pluralize singular={'Debt'} plural={'Debts'} count={listing.debts.length}/></span>
                    ) <br/>

                    {listing.listing_type === "sale" && saleOffer && saleOffer.buyer &&
                        <div className="saleValue text-right" >Sell for {formatCurrency(saleOffer.best_offer)}</div>}

                    {previewMode ||
                        <button className="btn btn-primary btn-block my-1" onClick={findOutMore}>
                            {buttonLabel}
                        </button>}
                </div>
            </div>
        </div>
    );
};

export default Result;
