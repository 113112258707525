
export function overdueDisplay(listing) {
    if (listing.min_overdue_days !== listing.max_overdue_days) {
        return listing.min_overdue_days + " - " + listing.max_overdue_days + " days";
    }
    if (!listing.min_overdue_days) return "";
    return listing.min_overdue_days + " days";
}

export function isMissingInformation(debt) {
    if (!debt || !debt.debtor) return true
    return debt.debtor.debtor_type === 'individual' ?
        (!debt.debtor.first_name || !debt.debtor.last_name || !debt.debtor.contact_email || !debt.debtor.contact_phone
            || !debt.debtor.address_1 || !debt.debtor.suburb || !debt.debtor.zip_code || !debt.debtor.state
        ) :
        (!debt.debtor.abn || !debt.debtor.contact_first_name || !debt.debtor.contact_last_name || !debt.debtor.contact_email || !debt.debtor.contact_phone
            || !debt.debtor.address_1 || !debt.debtor.suburb || !debt.debtor.zip_code || !debt.debtor.state
            || !debt.debtor.industry)
}