
function filterSearchParams(searchParams) {

    var filteredParams = Object.keys(searchParams).reduce(function (filtered, key) {
        // console.log('param ', key, searchParams[key] );
        if (searchParams[key] !== null && searchParams[key] !== "") filtered[key] = searchParams[key];
        return filtered;
    }, {});

    return filteredParams;

}

function getURLParamString(searchParams) {
    const filteredParams = filterSearchParams(searchParams);
    const urlParams = new URLSearchParams(filteredParams).toString();
    return urlParams;
}

export {
    filterSearchParams,
    getURLParamString
}
