import React, {Component} from "react";

import '../../../forms.scss';
import dotGray from "../../../img/carousel-dot-gray.png";
import dotGreen from "../../../img/carousel-dot-green.png";

class Form extends Component {

    // form step navigation
    _next = async () => {
        const submitted = await this.props.onSubmit();
        if (!submitted) return;

        // last step: complete redirect
        if (this.props.currentStep >= this.props.numberOfSteps) {
            if (this.props.afterLastStep) return await this.props.afterLastStep()
        } else {
            // go to next step
            let currentStep = this.props.currentStep
            currentStep = currentStep <= this.props.numberOfSteps ? currentStep + 1 : currentStep
            this.props.setStep(currentStep)
        }
    }

    _prev = () => {
        let currentStep = this.props.currentStep
        currentStep = currentStep <= 1 ? 1: currentStep - 1
        this.props.setStep(currentStep)
    }

    /*
    * the functions for our button
    */
    previousButton() {
        const { currentStep, options } = this.props;

        if (currentStep !== 1){
            return (
                <button
                    className="btn btn-secondary prevButton"
                    type="button" onClick={this._prev}>
                    {options.previousButtonLabel}
                </button>
            )
        }
        return null;
    }

    nextButton ()  {

        const { currentStep, numberOfSteps, options, validateStep, validate, hideNextButtonOnLastStep, nextButtonDisabled } = this.props;

        if (currentStep < numberOfSteps) {
            return (
                <button
                    disabled={validateStep(currentStep) || nextButtonDisabled}
                    className="btn btn-primary float-right nextButton mb-5"
                    type="button" onClick={this._next} >
                    {options.nextButtonLabel}
                </button>
            )
        }

        if (hideNextButtonOnLastStep) return;
        return (
            <button
                disabled={validate()}
                className="btn btn-primary float-right nextButton"
                type="button" onClick={this._next}>
                {options.submitButtonLabel}
            </button>
        )
    }

    skipButton ()  {
        const { currentStep, numberOfSteps, validateStep, nextButtonDisabled, hasSkipButton } = this.props;
        if (!hasSkipButton) return
        if (currentStep < numberOfSteps) {
            return (
                <button
                    className="btn float-right mb-5 skipButton"
                    type="button" onClick={this._next} >
                    Skip
                </button>
            )
        }

    }

    dotNavigation ()  {

        const { currentStep, numberOfSteps, setStep, hideSteps } = this.props;

        let dots = [];
        for (let i = 1; i <= numberOfSteps; i++) {
            if (hideSteps && hideSteps.includes(i)) continue;
            dots.push(<li key={i} className={currentStep === i ? "dot active" : "dot"} onClick={() => setStep(i)}>
                <img src={currentStep === i ? dotGreen : dotGray} alt="dot" width="8" height="8" />
            </li>)
        }

        return (
            <div className="dotNavigationContainer">
                <ol>{dots}</ol>
            </div>
        )

    }

    render() {

        return (
            <div>
                <form onSubmit={this.props.onSubmit} className={this.props.className}>
                    {this.props.children}
                    <hr className="formButtonContainerDivider" />

                    {this.props.hasDotNavigation ? <div className="buttonContainer">
                        {this.dotNavigation()}
                        {this.nextButton()}
                        {this.skipButton()}
                    </div> : <div className="buttonContainer">
                        {this.previousButton()}
                        {this.nextButton()}
                        {this.skipButton()}
                    </div>}

                </form>
            </div>
        );
    }
}

export default Form;


Form.defaultProps = {
    currentStep: 1,
    numberOfSteps: 1,
    options: {
        previousButtonLabel: "Back",
        nextButtonLabel: "Save & Continue",
        submitButtonLabel: "Save"
    }
};
