import React from "react";
import SubscriptionSinglePlan from "./subscriptionSinglePlan";


class SubscriptionStep extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { selectedPlan, plans } = this.props;

        return (
            <div className="form-group">
                <SubscriptionSinglePlan
                    selectedPlan={selectedPlan}
                    options={plans}
                />

            </div>
        )
    }

}

export default SubscriptionStep;
