import React from "react";

import auth from "../../services/authService";

import {getIndustries} from "../../utils/fetchConfiguration";
import {filterSearchParams} from "../../utils/search";
import {Input} from "../common/forms/Input";
import {BetterSelect} from "../common/forms/BetterSelect";

import _ from "lodash";
import {renderCheckbox} from "../common/forms/Checkbox";
import {formatCurrency} from "../common/numberFormats";
import SavedSearches from "./SavedSearches";

import '../../forms.scss';
import CurrencyInput from '../common/forms/currencyInput'
import {Alert, Form} from 'react-bootstrap'
import {Link} from "react-router-dom";

class Search extends React.Component {

    state = {
        user: null,
        userStatus: {},
        searchParams: {
            keywords: null,
            debtor_industry: null,
            placer_industry: null,
            days_overdue: null,
            amount_min: null,
            amount_max: null,
            exclude_disputed: false,
            only_personally_guaranteed: false,
        },
        maxDebtAmount: null,
        industries: [],
        daysOverdueOptions: [
            {
                label: "30+ days",
                value: 30,
            },
            {
                label: "60+ days",
                value: 60,
            },
            {
                label: "90+ days",
                value: 90,
            },
            {
                label: "120+ days",
                value: 120,
            }
        ],
        errors: []
    };

    async componentDidMount() {
        await this.loadData();
    };

    async loadData() {

        const userStatus = await auth.getUserStatus();
        const {data: industries} = await getIndustries();

        // set max amount based on user's plan
        let {searchParams, maxDebtAmount} = this.state;

        if (userStatus.maximum_listing_value !== null) {
            searchParams.amount_max = userStatus.maximum_listing_value;
            maxDebtAmount = userStatus.maximum_listing_value;
        }

        this.setState({
            userStatus: userStatus,
            industries: industries,
            searchParams: searchParams,
            maxDebtAmount: maxDebtAmount
        });
    }

    handleAmountChange = ({currentTarget: input}) => {
        const {searchParams, errors, maxDebtAmount} = this.state;

        let value = Number(input.value.replace(/\D/g,''))

        _.set(searchParams, input.name, value);

        if (input.name === "amount_max") {
            if (value > maxDebtAmount && maxDebtAmount) {
                errors["amount_max"] = "Upgrade your subscription to access debts over " + formatCurrency(maxDebtAmount);
                _.set(searchParams, input.name, maxDebtAmount);
            }
        }

        this.setState({searchParams, errors})

    };

    handleChange = ({currentTarget: input}) => {
        const {searchParams, errors} = this.state;

        if (input.type === 'checkbox') {
            _.set(searchParams, input.name, input.checked);
        } else {
            _.set(searchParams, input.name, input.value);
        }

        this.setState({searchParams, errors})

    };

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        const filteredParams = filterSearchParams(this.state.searchParams);
        const params = new URLSearchParams(filteredParams).toString();

        // redirect to search results
        window.location = "/results?" + params;

    };

    render() {

        const {searchParams, errors, industries, daysOverdueOptions, userStatus} = this.state;

        return (
            <React.Fragment>
                <div className="container">

                    <h1>Search Debt</h1>
                    <hr/>

                    <form onSubmit={this.handleSubmit}>

                        {userStatus.maximum_listing_value === 0 &&
                            <Alert className="mt-3" variant='warning'><Link to="/subscription">Upgrade your plan</Link> to find new listings.</Alert>}

                        <div className="form-row">
                            <div className="col-sm-6">
                                <Input
                                    type="text"
                                    name="keywords"
                                    value={searchParams.keywords}
                                    label="Enter name, industry or ABN"
                                    onChange={this.handleChange}
                                    error={errors.keywords}
                                    placeholder="Enter name, industry or ABN"
                                />
                            </div>
                            <div className="col-sm-6">
                                <BetterSelect
                                    data={searchParams}
                                    name="debtor_industry"
                                    label="Debtor Industry"
                                    options={industries}
                                    valueProperty="id"
                                    textProperty="anzsic_division_description"
                                    onChange={this.handleChange}
                                    emptyLabel="All Industries (recommended for Beta)"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6 clientIndustry">
                                <BetterSelect
                                    data={searchParams}
                                    id="clientIndustry"
                                    name="placer_industry"
                                    label="Client Industry"
                                    options={industries}
                                    valueProperty="id"
                                    textProperty="anzsic_division_description"
                                    onChange={this.handleChange}
                                    emptyLabel="All Industries (recommended for Beta)"
                                />
                            </div>
                            <div className="col-sm-6">
                                <BetterSelect
                                    data={searchParams}
                                    name="days_overdue"
                                    label="Debt Overdue"
                                    options={daysOverdueOptions}
                                    valueProperty="value"
                                    textProperty="label"
                                    onChange={this.handleChange}
                                    emptyLabel="Any"
                                />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-sm-6">
                                <Form.Group className="mb-3">
                                    <Form.Label>Min</Form.Label>
                                    <CurrencyInput maskOptions={{allowDecimal: false}}
                                                   className={"form-control mb-2"}
                                                   placeholder="$ 0.00"
                                                   type="text"
                                                   name="amount_min"
                                                   value={searchParams.amount_min}
                                                   onChange={this.handleAmountChange}/>
                                </Form.Group>
                            </div>
                            <div className="col-sm-6">
                                <Form.Group className="mb-3">
                                    <Form.Label>Max</Form.Label>
                                    <CurrencyInput maskOptions={{allowDecimal: false}}
                                                   className={"form-control mb-2"}
                                                   placeholder={this.state.maxDebtAmount ? "$ " + this.state.maxDebtAmount : "Any"}
                                                   type="text"
                                                   name="amount_max"
                                                   value={this.state.maxDebtAmount && searchParams.amount_max > this.state.maxDebtAmount ? this.state.maxDebtAmount : searchParams.amount_max}
                                                   onChange={this.handleAmountChange}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="form-row">
                            <h5>Advanced</h5>
                            <div className="checkbox">
                                {renderCheckbox(searchParams, errors, this.handleChange, "exclude_disputed", "Exclude debts which the debtor is disputing")}
                                {renderCheckbox(searchParams, errors, this.handleChange, "only_personally_guaranteed", "Only include debts which are personally guaranteed")}
                            </div>
                        </div>
                        <hr/>
                        <button
                            className="searchButton btn btn-primary float-right"
                            type="button" onClick={this.handleSubmit}>
                            Search
                        </button>

                    </form>


                </div>

                <div className="container">
                    <SavedSearches/>
                </div>

            </React.Fragment>

        );
    }
}

export default Search;
