import React from "react";

class Ratings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.value
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({value: nextProps.value})
    }

    render() {

        const {value} = this.state;

        let getClassName = (rating) => {
            return rating <= value ? "fa fa-star checked " + (this.props.size || "") : "fa fa-star " + (this.props.size || "")
        }
        if (value == null) return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )

        let getStarRating = (star) => {
            return this.props.handleRating ? <i onClick={() => {
                    this.props.handleRating(star)
                }} className={getClassName(star)}></i> :
                <i className={getClassName(star)}></i>

        }
        return (
            <React.Fragment>
                {getStarRating(1)}
                {getStarRating(2)}
                {getStarRating(3)}
                {getStarRating(4)}
                {getStarRating(5)}
                {this.props.children}
            </React.Fragment>
        );
    }
};

export {
    Ratings
};
