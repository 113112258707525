import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";

class VendorContact extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { placer } = this.props;

        if (!placer) return <div></div>

        return (
            <div className="placerDetails">
                {placer.business_name} <br/>
                {placer.address_1 && <div>{placer.address_1} <br/></div>}
                {placer.address_2 && <div>{placer.address_2} <br/></div> }
                {placer.suburb} {placer.state} {placer.zip_code}<br/>
                <br/>
                {placer.contact_name} {placer.contact_role_title && <React.Fragment>({placer.contact_role_title}) </React.Fragment>} <br/>
                {placer.email} <br/>
                {placer.phone} <br/>
            </div>
        );
    }
}

export default VendorContact;
