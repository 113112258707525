import React, {useContext, useEffect, useState} from "react"
import http from "./../services/httpService";
import config from "../constants";
import {getCurrentUser} from "../services/authService";

const AccessContext = React.createContext()

export function useAccess() {
    return useContext(AccessContext)
}

export function AccessProvider({children}) {

    const [currentAccessType, setCurrentAccessType] = useState({
        type: 'none'
    })
    const [availableAccessTypes, setAvailableAccessTypes] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        initAccessTypes()
    }, []);

    function setAccessTypeSessionStorage(accessType) {
        if (accessType) {
            localStorage.setItem('access_type', accessType.type);
            localStorage.setItem('placer_id', accessType.type === 'placer' ? accessType.id : "");
            localStorage.setItem('collection_partner_id', accessType.type === 'collection_partner' ? accessType.id : "");
            http.setAccessTypeHeaders(accessType)
        }
    }

    async function initAccessTypes() {

        // load
        const newAccessTypes = await loadAccessTypes()
        if (!newAccessTypes) return

        // set default
        let newAccessType

        // if already set in session storage, use that
        const accessTypeSession = localStorage.getItem('access_type')
        const placerIdSession = localStorage.getItem('placer_id')
        const collectionPartnerIdSession = localStorage.getItem('collection_partner_id')

        if (accessTypeSession) {
            newAccessType = newAccessTypes.find(t => t.type === accessTypeSession && `${t.id}` === (accessTypeSession === 'placer' ? placerIdSession : collectionPartnerIdSession))
            if (newAccessType) {
                setCurrentAccessType(newAccessType)
                setAccessTypeSessionStorage(newAccessType)
                return
            }
        }

        // if there's only one, set that one
        if (newAccessTypes.length === 1) {
            newAccessType = newAccessTypes[0]
        }
        else {
            // otherwise, if one of them has is_last_used = true, set that one
            const lastUsed = newAccessTypes.find(a => a.is_last_used)
            let foundLastUsed = false
            if (lastUsed) {
                foundLastUsed = true
                newAccessType = lastUsed
            }
            if (!foundLastUsed) {
                newAccessType = newAccessTypes[0]
            }
        }
        // http.setAccessTypeHeaders(newAccessType)
        setAccessTypeSessionStorage(newAccessType)
        setCurrentAccessType(newAccessType)
    }

    async function loadAccessTypes() {
        try {
            if (getCurrentUser()) {
                setIsLoading(true)
                const {data} = await http.get(config.API_URL + "/accounts/access_types/");
                setAvailableAccessTypes(data);
                setIsLoading(false)
                return data
            }
        } catch (ex) {}
    }

    function switchAccessType(accessType) {
        setAccessTypeSessionStorage(accessType)
        setCurrentAccessType(accessType)
        // TODO potentially also save on backend as last used access type
    }

    function getCurrentPlacerId() {
        if (currentAccessType.type === 'placer') {
            return currentAccessType.id || null
        }
        return null
    }

    function getCurrentCollectionPartnerId() {
        if (currentAccessType.type === 'collection_partner') {
            return currentAccessType.id || null
        }
        return null
    }

    function isPlacer() {
        return currentAccessType?.type === 'placer'
    }

    function isPlacerOwner() {
        return (currentAccessType?.type === 'placer' && currentAccessType.access === 'OWNER')
    }

    function isCollectionPartner() {
        return currentAccessType?.type === 'collection_partner'
    }

    function isAdmin() {
        return currentAccessType?.type === 'admin'
    }

    const value = {
        currentAccessType,
        availableAccessTypes,
        switchAccessType,
        getCurrentPlacerId,
        getCurrentCollectionPartnerId,
        isPlacer,
        isPlacerOwner,
        isCollectionPartner,
        isAdmin
    }

    return (
        <AccessContext.Provider value={value}>
            {!isLoading && children}
        </AccessContext.Provider>
    )
}
