import React from "react";
import {BrowserRouter} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import DebtplacerRouter from "./routing/router";
import {AccessProvider} from "./contexts/AccessContext";
import {refreshToken} from "./services/httpService";
import {getCurrentUser} from "./services/authService";

const ACTIVITY_CHECKER_INTERVAL = 1000 * 60 // 10 seconds

const events = [
    "mousedown",
    "mousemove",
    "wheel",
    "keydown",
    "touchstart",
    "scroll"
];

const addListeners = (events, cb) => {
    events.forEach((event) =>
        window.addEventListener(event, cb, {passive: true})
    );

    return () => {
        events.forEach((event) =>
            window.removeEventListener(event, cb, {passive: true})
        );
    };
};

const useRefreshTokenInterval = () => {
    const intervalRef = React.useRef();
    const unlistenRef = React.useRef();
    const wasActiveRef = React.useRef(false);

    React.useEffect(() => {

        if (!getCurrentUser()) return

        const refreshTokenInterval = (initial) => {
            if (!initial && !wasActiveRef.current) {
                return; // ignore - don't refresh the token
            }
            try {
                if (getCurrentUser()) {
                    refreshToken();
                }
            } catch (error) {
                console.log(error);
            } finally {
                wasActiveRef.current = false;
            }
        };
        refreshTokenInterval(true);

        intervalRef.current = setInterval(refreshTokenInterval, ACTIVITY_CHECKER_INTERVAL);
        unlistenRef.current = addListeners(events, () => {
            // console.log("activity");
            wasActiveRef.current = true;
        });

        return () => {
            clearInterval(intervalRef.current);
            if (unlistenRef.current) {
                unlistenRef.current();
            }
        };
    }, []);
};

export default function App() {
    useRefreshTokenInterval();
    return (
        <AccessProvider>
            <BrowserRouter>
                <DebtplacerRouter/>
            </BrowserRouter>
        </AccessProvider>
    );
}

