import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableFooter from "./tableFooter";

const Table = ({ columns, sortColumn, onSort, data, isSelected, footerData }) => {
  return (
    <table className="table table-striped">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} isSelected={isSelected} />
        {footerData && <TableFooter footerData={footerData} columns={columns} />}
    </table>
  );
};

export default Table;
