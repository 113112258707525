import React, {useEffect, useState} from "react";
import Iframe from 'react-iframe'

import http from "../../services/httpService";
import auth from "../../services/authService";

import config from "../../constants";
import SubscriptionStep from "../registration/SubscriptionStep";
import {getPlans} from "../../utils/fetchConfiguration";
import {useAccess} from "../../contexts/AccessContext";

const apiPath = "/subscriptions/get_portal_session/";


const ManageSubscription = () => {
    const [user, setUser] = useState(null);
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState("Collection-Partner-Fees-AUD-Monthly");
    const [selectedTerm, setSelectedTerm] = useState("MONTHLY");
    const [cbPortalURL, setCbPortalURL] = useState(null);
    const [cbInstance, setCbInstance] = useState(null);
    const [cbError, setCbError] = useState("");
    const [cbLoading, setCbLoading] = useState("");

    const {getCurrentCollectionPartnerId} = useAccess()

    useEffect(() => {
        const initChargebee = () => {
            setCbInstance(window.Chargebee.init({
                site: config.CHARGEBEE_SITE
            }));
            setCbLoading(true);
        };
        const loadUserData = async () => {
            const currentUser = auth.getCurrentUser();
            const postData = { user_id: currentUser.user_id };
            const { data: portalUrl } = await http.post(config.API_URL + apiPath, postData);
            if (portalUrl) {
                setUser(currentUser);
                setCbPortalURL(portalUrl);
                setCbLoading(false);
            } else {
                const { data: plansData } = await getPlans();
                setUser(currentUser);
                setPlans(plansData);
                setCbLoading(false);
            }
        };

        initChargebee();
        loadUserData();
    }, []);

    const handleCheckout = async (event) => {
        event.preventDefault();
        const selectedPlanDetails = plans.find(({ chargebee_id }) => chargebee_id === selectedPlan);

        if (selectedPlanDetails.price_per_month <= 0) {
            await createSubscription(null);
        }

        if (selectedPlanDetails.price_per_month <= 0) {
            return;
        }

        setCbLoading(true);
        cbInstance.openCheckout({
            hostedPage: () => {
                var data = {
                    collection_partner_id: getCurrentCollectionPartnerId(),
                    plan_id: selectedPlan
                };
                return http.post(config.API_URL + "/subscriptions/generate_checkout_new_url/",
                    data).then((response) => response.data);
            },
            success: async (hostedPageId) => {
                await createSubscription(hostedPageId);
            },
            close:() => {
                setCbLoading(false);
                loadData();
            },
            step(step) {}
        });
    };

    const createSubscription = async (hostedPageId) => {
        let data = {
            hosted_page_id: hostedPageId,
            chargebee_plan_id: selectedPlan
        };
        const subscription = await http.post(config.API_URL + "/subscriptions/", data);
    };

    return (
        <div className="container">
            <h1>Manage Your Subscription</h1>

            { !cbPortalURL && !cbLoading &&
                <form onSubmit={handleCheckout}>
                    <SubscriptionStep
                        selectedPlan={selectedPlan}
                        plans={plans}
                    />
                </form>}

            { cbPortalURL && !cbLoading && <div className="loading">
                {cbPortalURL &&
                    <Iframe url={cbPortalURL}
                            width="100%"
                            height="550px"
                            id="chargebeePortal"
                            className="myClassname"
                            display="initial"
                            scrolling="no"
                            position="relative" />}
            </div>}

        </div>
    );
};

export default ManageSubscription;
//
// class ManageSubscription extends React.Component {
//
//     state = {
//         user: null,
//         plans: [],
//         // Plan selection (will get preset based on URL in loadData)
//         selectedPlan: "Collection-Partner-Fees-AUD-Monthly",
//         selectedTerm: "MONTHLY",
//         // Chargebee
//         cbPortalURL: null,
//         cbInstance: null,
//         cbError: "",
//         cbLoading: ""
//     };
//
//     async componentDidMount() {
//
//         // initialise Chargebee instance
//         this.setState({
//             cbInstance: window.Chargebee.init({
//                 site: config.CHARGEBEE_SITE
//             }),
//             cbLoading: true
//         })
//
//         await this.loadData();
//     };
//
//     async loadData() {
//
//         const user = auth.getCurrentUser();
//
//         const postData = { user_id: user.user_id}
//         const { data: portalUrl } = await http.post(config.API_URL + apiPath, postData)
//
//         if (portalUrl) {
//
//             this.setState({
//                 user: user,
//                 cbPortalURL: portalUrl,
//                 cbLoading: false
//             });
//
//         } else {
//             const {data: plans} = await getPlans();
//
//             this.setState({
//                 user: user,
//                 plans: plans,
//                 cbLoading: false
//             });
//         }
//
//     }
//
//     handleCheckout = async (event) => {
//
//         event.preventDefault();
//
//         const { selectedPlan, plans } = this.state;
//         const selectedPlanDetails = plans.find(({chargebee_id}) => chargebee_id === selectedPlan);
//         // const selectedPlanDetails = this.getSelectedPlanDetails();
//
//         // create free subscription in backend
//         if (selectedPlanDetails.price_per_month <= 0) {
//             await this.createSubscription(null);
//         }
//
//         // skip checkout if free plan selected or subscription already set up
//         if (selectedPlanDetails.price_per_month <= 0) {
//             return;
//         }
//
//         this.setState({cbLoading: true});
//         this.state.cbInstance.openCheckout({
//             hostedPage: () => {
//                 var data = {
//                     collection_partner_id: this.state.user.collection_partner_id,
//                     plan_id: selectedPlan
//                 };
//                 return http.post(config.API_URL + "/subscriptions/generate_checkout_new_url/",
//                     data).then((response) => response.data)
//             },
//             success: async (hostedPageId) => {
//                 await this.createSubscription(hostedPageId);
//             },
//             close:() => {
//                 this.setState({cbLoading: false});
//                 this.loadData();
//             },
//             step(step) {}
//         });
//     }
//
//     createSubscription = async (hostedPageId) => {
//         let data = {
//             hosted_page_id: hostedPageId,
//             chargebee_plan_id: this.state.selectedPlan
//         }
//         const subscription = await http.post(config.API_URL + "/subscriptions/", data);
//         // set state if needed later on
//     }
//
//     render() {
//
//         const { cbPortalURL, selectedPlan, cbLoading } = this.state;
//
//         return (
//             <div className="container">
//
//                 <h1>Manage Your Subscription</h1>
//
//                 { !cbPortalURL && !cbLoading &&
//                 <form onSubmit={this.handleCheckout}>
//                     <SubscriptionStep
//                         data={this.state.data}
//                         selectedPlan={selectedPlan}
//                         plans={this.state.plans}
//                     />
//                 </form>}
//
//                 { cbPortalURL && !cbLoading && <div className="loading">
//                     {cbPortalURL &&
//                     <Iframe url={cbPortalURL}
//                             width="100%"
//                             height="550px"
//                             id="chargebeePortal"
//                             className="myClassname"
//                             display="initial"
//                             scrolling="no"
//                             position="relative" />}
//                 </div>}
//
//             </div>
//         );
//     }
// }
//
// export default ManageSubscription;
