import React from "react";
import {Link} from 'react-router-dom'

class ReviewThankYou extends React.Component {
    render() {
        return (
            <div className="login-outer">
                <div className="container">
                    <h1>Thank You for reviewing!</h1>
                    <br/>
                    <br/>
                    <p>We really appreciate your feedback</p>
                    <br/>
                    <Link to={'/'}>
                        <button className="btn btn-primary" type="submit">
                            Continue
                        </button>
                    </Link>
                </div>
            </div>
        );
    }
}

export default ReviewThankYou;
