import React from "react";


export function formatCurrency(number, decimalPlaces = 2) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: decimalPlaces
    });
    return formatter.format(number)
}

export function formatAbn(string) {
    if (!string) return ""
    return string.substring(0, 2) + " " + string.substring(2, 5) + " " + string.substring(5, 8) + " " + string.substring(8, 11)
}

export function formatPercentage(number) {
    return number + "%";
}
