import React, {Component} from "react";

class FormStep extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.currentStep !== this.props.stepIndex) {
            return null
        }

        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default FormStep;
