import React from "react";
import {Link} from "react-router-dom";
import {Ratings} from "../common/ratings";
import {formatCurrency} from "../common/numberFormats";

const ChatTitle = ({chat, user, accessType}) => {
    return <div style={{display: "inline"}}>
        {!!chat ? <div className="chat-title-container">

            <span className="chat-title">{accessType.type === 'collection_partner' ? chat.placer : <> {chat.collection_partner.business_name}
            &nbsp;<Ratings value={chat.collection_partner.rating}/></>}</span>

            <div className="chat-title-debtor debtor">
                {formatCurrency(chat.listing.total_value)} | {chat.listing.debtor_names} | Listing
                ID {chat.listing.reference}
            </div>

            <div className="chat-title-buttons">
                {!!chat && accessType.type === 'placer' ?
                    <Link to={`/collection-partners/` + chat.collection_partner.id}>
                        <button className="btn btn-primary">View Profile</button>
                    </Link> : null}
                &nbsp;
                {!!chat ? <Link to={`/listings/` + chat.listing.id}>
                    <button className="btn btn-primary"> View listing</button>
                </Link> : null}
            </div>


        </div> : null}
    </div>
};

export default ChatTitle;
