import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";

class CollectionPartnerContact extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { collectionPartner } = this.props;

        if (!collectionPartner) return <div></div>

        return (
            <div className="collectionPartnerDetails">
                {collectionPartner.business_name && <div>{collectionPartner.business_name} <br/></div>}
                {collectionPartner.address_1 && <div>{collectionPartner.address_1} <br/></div>}
                {collectionPartner.address_2 && <div>{collectionPartner.address_2} <br/></div> }
                {collectionPartner.suburb} {collectionPartner.state} {collectionPartner.zip_code}

                {collectionPartner.contact_name} {collectionPartner.contact_role_title && <React.Fragment>({collectionPartner.contact_role_title})</React.Fragment> }<br/>

                {collectionPartner.email && <div>{collectionPartner.email} <br/></div>}
                {collectionPartner.phone && <div>{collectionPartner.phone} <br/></div>}

            </div>
        );
    }
}

export default CollectionPartnerContact;
