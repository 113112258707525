import React from "react";
import http from "../services/httpService";
import config from "../constants";

const apiPath = "/users/";


export default async function doRequestEmailConfirmation(userId) {
    const url = config.API_URL + apiPath + userId + "/request_email_confirmation/";
    const resp = await http.post(url);
}
