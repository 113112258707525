import React, {Component} from "react";
import InfoContainer from "../common/infoContainer";
import OfferFees from "../common/OfferFees";

class OfferSummary extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { offer } = this.props;

        return (
            <React.Fragment>
            {offer.status === "accepted" &&
                <InfoContainer type="check" iconClass="fa fa-check-circle-o">
                    <strong>Offer Accepted</strong><br/>
                    <OfferFees offer={offer} />
                </InfoContainer>
            }
            </React.Fragment>
        );
    }
};

export default OfferSummary;
