
import homeIcon from "../../img/icons/home.svg";
import homeIconActive from "../../img/icons/home-active.svg";
import bagIcon from "../../img/icons/bag.svg";
import bagIconActive from "../../img/icons/bag-active.svg";
import collectionsIcon from "../../img/icons/collections.svg";
import collectionsIconActive from "../../img/icons/collections-active.svg";
import plusIcon from "../../img/icons/plus.svg";
import plusIconActive from "../../img/icons/plus-active.svg";
import listingsIcon from "../../img/icons/listings.svg";
import listingsIconActive from "../../img/icons/listings-active.svg";

export const collectionPartnerNavItems = [
    {
        to: "/dashboard",
        label: "Dashboard",
        icon: homeIcon,
        activeIcon: homeIconActive
    },
    {
        to: "/search",
        label: "Search Debt",
        icon: bagIcon,
        activeIcon: bagIconActive
    }
]


export const placerNavItems = [
    {
        to: "/listings",
        label: "Listings",
        icon: listingsIcon,
        activeIcon: listingsIconActive,
        exact: true
    },
    {
        to: "/collections",
        label: "Collections",
        icon: collectionsIcon,
        activeIcon: collectionsIconActive
    },
    {
        to: "/collection-partners",
        label: "Collection Partners",
        icon: collectionsIcon,
        activeIcon: collectionsIconActive
    },
    {
        to: "/listings/new",
        label: "List debt",
        icon: plusIcon,
        activeIcon: plusIconActive,
        exact: false
    }
]

