import React from "react";
import {Media} from "react-bootstrap"
import moment from 'moment'
import {getInitials} from "../../utils/getInitials";
import {useAccess} from "../../contexts/AccessContext";

const ChatFeedItem = ({chat, user, feed}) => {
    const {isCollectionPartner} = useAccess()
    return (
        <Media as="li"
               className={'chat-feed ' + (feed.created_by === user.user_id ? "chat-mine" : "")}>
            {feed.created_by === user.user_id ? null :
                <div className="avatar avatar-sm">
                    <div className="avatar__letters">
                        {getInitials(isCollectionPartner() ? chat.placer : chat.collection_partner.business_name)}
                    </div>
                </div>
            }
            <Media.Body className={'chat-feed-body'}>
                <div className={'chat-feed-message'}>
                    {feed.message}
                </div>
                <span
                    className="chat-time">{moment(feed.creation_date).fromNow()}</span>
            </Media.Body>
        </Media>
    );
};

export default ChatFeedItem;