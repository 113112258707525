// Validation for forms
import _ from "lodash";

const Joi = require('joi');
const options = {abortEarly: false, allowUnknown: true};

function validateWithoutSteps(schema, data) {
    const {error} = Joi.object(schema).validate(data, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) {
        const path = item.path.join(".");
        errors[path] = item.message;
    }
    if (errors) return errors;
    return null;
}

function validatePropertyWithoutSteps(input, schema, data) {

    const {name, value} = input
    const obj = {[name]: value};

    let baseSchema;
    let propertySchema;

    baseSchema = schema;
    propertySchema = _.get(schema, name);

    // if no schema for that key, ignore
    if (!propertySchema) return null;

    const schemaTmp = {[name]: propertySchema};

    // special logic for fields with references - could probably make this generic using the actual ref
    if (name === "confirm_password") {
        obj["password"] = data["password"];
        schemaTmp["password"] = baseSchema["password"];
    }

    const {error} = Joi.object(schemaTmp).validate(obj);
    return error ? error.details[0].message : null;
}


function validateStep(stepIndex, schema, data) {
    const schemaStep = schema[stepIndex];
    const {error} = Joi.object(schemaStep).validate(data, options);
    if (!error) return null;
    const errors = {};
    // for (let item of error.details) errors[item.path[0]] = item.message;
    for (let item of error.details) {
        const path = item.path.join(".");
        errors[path] = item.message;
    }
    return errors;
}

function validate(schema, data) {
    for (var step in schema) {
        if (schema.hasOwnProperty(step)) {
            const errors = validateStep(step, schema, data)
            if (errors) return errors;
        }
    }
    return null
}

function validateProperty(input, currentStep, schema, data) {

    const {name, value} = input
    const obj = {[name]: value};

    let baseSchema;
    let propertySchema;

    baseSchema = schema[currentStep];
    propertySchema = _.get(schema[currentStep], name);

    // if no schema for that key, ignore
    if (!propertySchema) return null;

    let schemaTmp = {[name]: propertySchema};

    // special logic for fields with references - could probably make this generic using the actual ref
    if (name === "confirm_password") {
        obj["password"] = data["password"];
        schemaTmp["password"] = baseSchema["password"];
    }
    if (name === "user.confirm_password") {
        obj["password"] = data['user']["password"];
        schemaTmp["password"] = baseSchema.user.password
    }

    const {error} = Joi.object(schemaTmp).validate(obj);
    return error ? error.details[0].message : null;
}

export {
    validate,
    validateStep,
    validateProperty,
    validateWithoutSteps,
    validatePropertyWithoutSteps
}
