import React from "react";
import {Input} from "../common/forms/Input";
import {BetterSelect} from "../common/forms/BetterSelect";
import {renderCheckbox} from "../common/forms/Checkbox";

import '../../forms.scss';

import CurrencyInput from '../common/forms/currencyInput'
import {Form} from 'react-bootstrap'

class SidebarSearchForm extends React.Component {

    render() {

        const {
            searchParams, errors, handleChange, handleSubmit, handleClearFilters,
            industries, daysOverdueOptions, maxDebtAmount, handleAmountChange
        } = this.props;

        return (
            <div className="">

                <div className="searchFormHeader">
                    <span className="pull-right"><a onClick={handleClearFilters} href="">Clear All</a></span>
                    <h3>Filter</h3>
                </div>

                <form className="sidebarSearchForm" onSubmit={handleSubmit}>

                    <div className="form-row">
                        <h5>Search</h5>
                        <Input
                            type="text"
                            name="keywords"
                            value={searchParams.keywords}
                            label="Enter name, industry or ABN"
                            onChange={handleChange}
                            error={errors.keywords}
                            placeholder="Enter name, industry or ABN"
                            iconClass="fa fa-search"
                            labelClass="sr-only"
                        />
                    </div>

                    <div className="form-row">
                        <h5>Debt Details</h5>

                        <BetterSelect
                            data={searchParams}
                            name="debtor_industry"
                            label="Debtor Industry"
                            options={industries}
                            valueProperty="id"
                            textProperty="anzsic_division_description"
                            onChange={handleChange}
                            emptyLabel="All Industries (recommended for Beta)"
                        />

                        <BetterSelect
                            data={searchParams}
                            name="placer_industry"
                            label="Client Industry"
                            options={industries}
                            valueProperty="id"
                            textProperty="anzsic_division_description"
                            onChange={handleChange}
                            emptyLabel="All Industries (recommended for Beta)"
                        />

                        <BetterSelect
                            data={searchParams}
                            name="days_overdue"
                            label="Debt Overdue"
                            options={daysOverdueOptions}
                            valueProperty="value"
                            textProperty="label"
                            onChange={handleChange}
                            emptyLabel="Any"
                        />
                    </div>

                    <div className="form-row">
                        <h5>Amount Owed</h5>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Min</Form.Label>
                                <CurrencyInput maskOptions={{allowDecimal: false}}
                                               className={"form-control mb-2"}
                                               placeholder="$ 0.00"
                                               type="text"
                                               name="amount_min"
                                               value={searchParams.amount_min}
                                               onChange={handleAmountChange}/>
                            </Form.Group>
                        </div>
                        <div className="col-sm-6">
                            <Form.Group className="mb-3">
                                <Form.Label>Max</Form.Label>
                                <CurrencyInput maskOptions={{allowDecimal: false}}
                                               className={"form-control mb-2"}
                                               placeholder={maxDebtAmount ? "$ " + maxDebtAmount : "Any"}
                                               type="text"
                                               name="amount_max"
                                               value={maxDebtAmount && searchParams.amount_max > maxDebtAmount ? maxDebtAmount : searchParams.amount_max}
                                               onChange={handleAmountChange}/>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="form-row">
                        <h5>Advanced</h5>
                        {renderCheckbox(searchParams, errors, handleChange, "exclude_disputed", "Exclude debts which the debtor is disputing")}
                        <br/>
                        {renderCheckbox(searchParams, errors, handleChange, "only_personally_guaranteed", "Only include debts which are personally guaranteed")}
                    </div>

                    <button
                        className="searchButton btn btn-outline-primary btn-block mb-5"
                        type="button" onClick={handleSubmit}>
                        Search
                    </button>

                </form>

            </div>
        );
    }
}

export default SidebarSearchForm;
