import {React, useEffect, useState} from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {APIPagination, paginate} from "../common/pagination";
import _ from "lodash";
import Table from "../common/table";
import {MobileTablePlacerCollections} from "../common/mobileTablePlacer";
import Resolve from "../common/resolve";
import {Link} from "react-router-dom";
import {isMissingInformation} from "../../utils/listingRenderers";
import {Input} from "../common/forms/Input";
import {Alert, Spinner} from "react-bootstrap"
import {getOptionSet} from "../../utils/fetchConfiguration";
import {Media} from '../../AppMedia'
import '../../listings.scss'
import MoreOptionsNav from "../common/moreOptionsNav";
import warningIcon from "../../img/icons/warning.svg";
import {toast} from "react-toastify";

var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});


const statusTabs = [
    {
        status: null,
        label: "All Collections"
    },
    {
        status: "unresolved",
        label: "Active"
    },
    {
        status: "resolved",
        label: "Resolved"
    }
]

export default function Collections() {

    const [loading, setLoading] = useState(true);
    const [debts, setDebts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [resolvedAmountOptions, setResolvedAmountOptions] = useState([]);
    const [unresolvedReasonOptions, setUnresolvedReasonOptions] = useState([]);
    const [sortColumn, setSortColumn] = useState({path: "debtor.name", order: "asc"})

    const columns = [
        {
            path: "listing.reference",
            label: "Listing ID",
            key: "reference",
            sort: true,
            content: debt => (
                <>
                    {isMissingInformation(debt) && <img src={warningIcon} width="15" height="14" className="missingDebtorInfo" title="Some debtor information missing" />}
                    <Link to={`/listings/${debt.listing.id}/debts/${debt.id}`}> {debt.listing.reference} </Link>
                </>
)
        },
        {
            path: "debtor.name",
            label: "Debtor",
            key: "debtor",
            sort: true
        },
        {
            label: "Amount",
            key: "amount",
            sort: true,
            path: "amount",
            content: debt => (
                <span>{formatter.format(debt.amount)}</span>
            )
        },
        {
            path: "listing.collection_partner.business_name",
            label: "Collection Partner",
            key: "collection_partner",
            sort: true
        },
        {
            key: "actions",
            sort: false,
            content: debt =>

                <div className="listingsActions">
                    <Resolve status={debt.status}
                             onClick={handleResolve(debt)}
                             userType="placer"
                             resolvedAmountOptions={resolvedAmountOptions}
                             unresolvedReasonOptions={unresolvedReasonOptions}
                    />

                    <Link
                    to={`/messenger${debt.listing.status === 'offer_accepted' && debt.listing.chats.length >= 1 ? "/" + debt.listing.chats[0] : '?listing=' + debt.listing.id}`}>
                    {debt.listing.has_unread_chats ?
                        <span className="fa-stack fa-5x has-badge">
                            <i style={{cursor: "pointer"}}
                               className="envelope"
                               aria-hidden="true"/>
                                        </span>

                        :
                        <i style={{cursor: "pointer"}}
                           className="envelope"
                           aria-hidden="true"/>

                    }
                    </Link>

                    <MoreOptionsNav>
                        <Link to={`/listings/${debt.listing.id}/edit`}>Edit listing</Link>
                        <Link to={`/listings/${debt.listing.id}/edit?step=2`}>Add missing details</Link>
                        <Link to={`/listings/${debt.listing.id}/edit?step=4`}>Add a document</Link>
                    </MoreOptionsNav>

                </div>
        },
        {
            key: "link",
            sort: false,
            content: debt => <Link className="listingDetail" to={`/listings/${debt.listing.id}/debts/${debt.id}`}>
                <i style={{cursor: "pointer"}} className="chevron"/> </Link>
        }
    ];

    useEffect(async () => {
        sessionStorage.removeItem('search')
        await loadData()
    }, []);

    async function loadData() {
        setLoading(true)

        const {data: debts} = await http.get(config.API_URL + "/placer_debts/");
        setDebts(debts)

        const {data: resolvedAmountOptions} = await getOptionSet("RESOLVED_AMOUNT");
        setResolvedAmountOptions(resolvedAmountOptions[0].options)

        const {data: unresolvedReasonOptions} = await getOptionSet("UNRESOLVED_REASON");
        setUnresolvedReasonOptions(unresolvedReasonOptions[0].options)
        setLoading(false)
    }

    function handleResolve(debt) {
        return async (resolved_type, resolved_amount, unresolved_reason) => {
            const newDebts = [...debts]
            const index = newDebts.indexOf(debt);
            newDebts[index] = {...newDebts[index]};
            // use debt id instead of listing id // check if works
            const {data: updatedDebt} = await http.post(config.API_URL + "/placer_debts/" + debt.id + '/resolve/', {
                resolved_type,
                resolved_amount,
                unresolved_reason
            });
            newDebts[index] = updatedDebt;
            setDebts(newDebts)
            toast.success("This debt has been resolved.", {autoClose: 3000});
        }
    }

    function handlePageChange(page) {
        setCurrentPage(page)
    }

    function handleSearch({target: {value}}) {
        setSearchQuery(value)
        setSelectedStatus(null)
        setCurrentPage(1)
    }

    function handleSort(sortColumn) {
        setSortColumn(sortColumn)
    }

    function isIncludedInSearch(debt, searchQuery) {
        // add more search lookups here when needed
        return (
            (debt.debtor && debt.debtor.name?.toLowerCase().includes(searchQuery.toLowerCase()))
            || debt.listing.collection_partner?.business_name?.toLowerCase().includes(searchQuery.toLowerCase())
            || debt.debtor?.contact_first_name?.toLowerCase().includes(searchQuery.toLowerCase())
            || debt.debtor?.contact_last_name?.toLowerCase().includes(searchQuery.toLowerCase())
            || debt.debtor?.abn?.toLowerCase().includes(searchQuery.toLowerCase())
            || debt.debtor?.industry_description?.toLowerCase().includes(searchQuery.toLowerCase())
        )
    }

    function getPagedData() {

        const allDebts = debts

        let filtered = allDebts.map(listing => {
            listing.total_value = Number(listing.total_value)
            return listing
        });

        if (searchQuery) {
            filtered = allDebts.filter(m => isIncludedInSearch(m, searchQuery));
        } else if (selectedStatus)
            filtered = allDebts.filter(debt => debt.status === selectedStatus);

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
        const pagedListings = paginate(sorted, currentPage, pageSize);

        return {totalCount: filtered.length, pagedListings};
    }

    const {totalCount, pagedListings} = getPagedData()

    return (
        <div className="listings pt-0">

            <div className="listingsResult">

                <div className="listingsHeader">
                    <h2>Active Collections ({debts.length} total) </h2>
                </div>

                <div className="filterTabs">
                    <ul>
                        {statusTabs.map((item, index) => {
                            return <li key={index}
                                       className={item.status === selectedStatus ? "active" : ""}
                                       onClick={() => setSelectedStatus(item.status)}>{item.label}</li>
                        })}
                    </ul>
                </div>

                <div className="listingsSearchAndFilters">
                    <form>
                        <div className="dashboardSearch">
                            <Input
                                type="text"
                                name="keywords"
                                value={searchQuery || ""}
                                label="Enter name, industry or ABN"
                                onChange={handleSearch}
                                placeholder="Search by debtor name, ABN, industry or collection partner name"
                                iconClass="fa fa-search"
                                labelClass="sr-only"
                            />
                        </div>
                    </form>
                </div>

                <div className="listingsBody">

                    {loading ? <Spinner className="spinner" animation="border" role="status"/>
                        : totalCount === 0 ? <Alert variant="light">
                            <div className="emptyStateButtonContainer">
                                <p>No collections found. Try adjusting your filters.</p>
                            </div>

                        </Alert> : <>
                            <Media at='mobile'>
                                <MobileTablePlacerCollections
                                    columns={columns}
                                    data={pagedListings}
                                    sortColumn={sortColumn}
                                    onSort={handleSort}
                                />
                            </Media>
                            <Media greaterThan='mobile'>
                                <Table
                                    columns={columns}
                                    data={pagedListings}
                                    sortColumn={sortColumn}
                                    onSort={handleSort}
                                />
                            </Media></>
                    }
                </div>
                <div className="listingsPagination">
                    {
                        totalCount === 0 || loading ? null :
                            <APIPagination currentPage={currentPage} itemsCount={totalCount}
                                           onPageChange={handlePageChange}
                                           pageSize={pageSize}/>
                    }
                </div>

            </div>

        </div>

    )
}

