import React, {useEffect, useState} from "react";
import {Alert, Button, Jumbotron, Modal, Spinner} from 'react-bootstrap'

import {toast} from "react-toastify";
import {Checkbox} from "../common/forms/Checkbox";
import auth from "../../services/authService";
import {Link} from "react-router-dom";
import {useAccess} from "../../contexts/AccessContext";

const ReadTermsCondition = (props) => {
    const [show, setShow] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [read, setRead] = useState(false);
    const [userStatus, setUserStatus] = useState({});

    const {isPlacerOwner} = useAccess()

    useEffect(() => {
        const fetchUserStatus = async () => {
            const userStatus = await auth.getUserStatus();
            setUserStatus(userStatus);
        }
        fetchUserStatus();
    }, []);

    const handleClose = () => {
        props.hideReject();
        setShow(false);
    }

    const handleShow = async () => {
        // reload status here to make sure sub can still make an offer
        const userStatus = await auth.getUserStatus();
        props.hideReject();
        setShow(true);
        setUserStatus(userStatus);
    }

    const handleRead = () => {
        setRead(!read);
        setError(null);
    }

    if (error) {
        toast.error(error);
    }
    if (success) {
        toast.success(success, {autoClose: 3000});
    }

    return (
        <>
            {isPlacerOwner() && !userStatus.bank_details_completed &&
                <Alert variant='danger'>Please add your payout details to your profile before accepting an offer - <Link to={`/profile?edit=bankDetails`}>Add bank details</Link> </Alert>}

            {userStatus && <Button variant="primary" className='offer-button'
                                   onClick={handleShow}
                                   disabled={!userStatus.bank_details_completed}
            >{show ? <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            /> : null} &nbsp; Accept Offer
            </Button>}
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton className={'padding-30'}>
                    <Modal.Title className={'modalTitle'}>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body className={'padding-30'}>
                    <Jumbotron className={"terms-conditions"}>
                        {props.offer.terms ?
                            <div
                                dangerouslySetInnerHTML={{__html: props.offer.terms.content}}></div> : "No Terms and Conditions"}
                    </Jumbotron>
                    <Checkbox name="read"
                              value="read"
                              checked={read}
                              onChange={handleRead}
                              label={"I have read and accept these terms & conditions"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn" variant="secondary"
                            onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="btn" variant="primary"
                            onClick={props.acceptOffer}
                            disabled={!read}>
                        Accept Offer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReadTermsCondition;


// class ReadTermsCondition extends React.Component {
//
//     constructor(props) {
//         super(props)
//         this.state = {
//             show: false,
//             success: null,
//             error: null,
//             read: false,
//             userStatus: {}
//         }
//     }
//
//     componentDidMount = async () => {
//         const userStatus = await auth.getUserStatus();
//         this.setState({userStatus});
//     }
//
//     handleClose = () => {
//         this.props.hideReject()
//         this.setState({show: false});
//     }
//
//     handleShow = async () => {
//         // reload status here to make sure sub can still make an offer
//         const userStatus = await auth.getUserStatus();
//         this.props.hideReject()
//         this.setState({show: true, userStatus});
//     }
//
//     handleRead = () => {
//         let read = this.state.read
//         this.setState({read: !read, error: null})
//     }
//
//     render() {
//
//         const {show, error, success, read, userStatus} = this.state
//
//         if (error) {
//             toast.error(error);
//         }
//         if (success) {
//             toast.success(success, {autoClose: 3000});
//         }
//
//         return (
//             <>
//                 {isPlacerOwner() && !userStatus.bank_details_completed &&
//                     <Alert variant='danger'>Please add your payout details to your profile before accepting an offer - <Link to={`/profile?edit=bankDetails`}>Add bank details</Link> </Alert>}
//
//                 {userStatus && <Button variant="primary" className='offer-button'
//                     onClick={this.handleShow}
//                     disabled={!userStatus.bank_details_completed}
//             >{show ? <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//             /> : null} &nbsp; Accept Offer
//             </Button>}
//             <Modal show={show} onHide={this.handleClose} size="lg">
//                 <Modal.Header closeButton className={'padding-30'}>
//                     <Modal.Title className={'modalTitle'}>Terms and Conditions</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body className={'padding-30'}>
//                     <Jumbotron className={"terms-conditions"}>
//                         {this.props.offer.terms ?
//                             <div
//                                 dangerouslySetInnerHTML={{__html: this.props.offer.terms.content}}></div> : "No Terms and Conditions"}
//                     </Jumbotron>
//                     <Checkbox name="read"
//                               value="read"
//                               checked={read}
//                               onChange={this.handleRead}
//                               label={"I have read and accept these terms & conditions"}
//                     />
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button className="btn" variant="secondary"
//                             onClick={this.handleClose}>
//                         Cancel
//                     </Button>
//                     <Button className="btn" variant="primary"
//                             onClick={this.props.acceptOffer}
//                             disabled={!read}>
//                         Accept Offer
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//             </>
//         );
//     }
// };
//
// export default ReadTermsCondition;
