import React, {Component} from "react";
import "react-datepicker/dist/react-datepicker.css";
import {OverlayTrigger, Popover} from "react-bootstrap"

class SupportingDocuments extends Component {

    constructor(props) {
        super(props);
    }

    handleDownloadAll = (supportingDocuments) => {
        supportingDocuments.forEach(document => {
            window.open(document.download_link, '_blank')
        })
    }

    render() {

        const {supportingDocuments} = this.props;

        return (
            <div>

                {supportingDocuments &&
                <table className="listingTable table-striped">
                    <thead>
                    <tr>
                        <th>File</th>
                        <th>Type</th>
                        <th><i className="download"/></th>
                        {/*<th>*/}
                        {/*    <OverlayTrigger placement="right" overlay={*/}
                        {/*    <Popover id="popover-basic">*/}
                        {/*        <Popover.Content>*/}
                        {/*            Download All*/}
                        {/*        </Popover.Content>*/}
                        {/*    </Popover>*/}
                        {/*}>*/}
                        {/*    <a href="#" onClick={() => this.handleDownloadAll(supportingDocuments)}>*/}
                        {/*        <i className="download" onClick={() => this.handleDownloadAll(supportingDocuments)}/>*/}
                        {/*    </a>*/}
                        {/*</OverlayTrigger>*/}

                        {/*</th>*/}
                    </tr>
                    </thead>
                    <tbody>
                    {supportingDocuments.map(document => (
                        <tr key={document.id}>
                            <td>{document.file}</td>
                            <td>{document.document_type_title}
                                {document.document_type_title === "Other" && <React.Fragment>
                                    :<br/>
                                    <div className="listItemDetails">{document.comments}</div>
                                </React.Fragment>}
                            </td>
                            <td><a href={document.download_link} target="_blank"><i className="download"/></a></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                }
            </div>
        );
    }
}

export default SupportingDocuments;
