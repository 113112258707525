import React from "react";

import img from "../../img/completed.png";

export default function EmbeddedDebtSaleCompleted({embedded, sale}) {

    return (
        <div className="text-center mt-5 completed">
            <img src={img} alt="Checkmark" width="80" height="80"/>
            <h1 className="mt-3">Debt sale completed</h1>
            <p>Please check your email for confirmation of assignment and timing for payment.</p>
        </div>
    )

}