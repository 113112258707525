import React from "react";
import _ from "lodash";
import { ReactElement, CSSProperties, ChangeEvent } from 'react';


const Checkbox = ({type = 'checkbox', name, id, checked = false, onChange, value, label, hideCheck = false, ...rest}) => (
    <label className="checkbox container" id={"container-" + id}>{label}
        <input {...rest} type={type} name={name} id={id} checked={checked} onChange={onChange} value={value}/>
        {hideCheck ? null : <span className="checkmark"></span>}
    </label>
);


class CheckboxGroup extends React.Component {

    render() {

        const {items, textProperty, valueProperty, onChange, selectedItems} = this.props;

        return (
            <div className="checkbox-group">
                {items.map(item => (
                    <div key={item[valueProperty]}>
                        <Checkbox name={item[valueProperty]}
                                  id={item[valueProperty]}
                                  checked={selectedItems.includes(item[valueProperty])}
                                  onChange={onChange}
                                  label={item[textProperty]}/>
                    </div>
                ))}
            </div>
        );
    }
};

CheckboxGroup.defaultProps = {
    textProperty: "label",
    valueProperty: "value"
};


function renderCheckbox(data, errors, onChange, path, label, className = "") {

    return (
        <React.Fragment>
            <Checkbox
                name={path}
                id={path}
                checked={_.get(data, path) === true}
                label={label}
                onChange={onChange}
                error={errors[path]}
                className={className}
            />
        </React.Fragment>
    );
}


const ThreeStateCheckbox = ({type = 'checkbox', name, id,
                                checked = false,
                                indeterminate=false,
                                disabled=false,
                                onChange, value, label, hideCheck = false, ...rest}) => (

    <label className="three-state-checkbox checkbox container" id={"container-" + id}>{label}
        <input {...rest} type={type} name={name} id={id}
               ref={input => {
                   if (input) {
                       input.checked = checked;
                       input.indeterminate = indeterminate;
                       input.disabled = disabled;
                   }
               }}
               checked={checked} onChange={onChange} value={value}/>
        {hideCheck ? null : <span className="checkmark"></span>}
    </label>
);


export {
    CheckboxGroup,
    Checkbox,
    renderCheckbox,
    ThreeStateCheckbox
};
