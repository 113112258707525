import {React} from "react";
import {formatCurrency} from "../common/numberFormats";
import {Radio} from "../common/forms/radioGroup";


export default function ListingSaleOrMarketplaceStep({listing, handleChange, handleChooseListingType, saleOffer}) {

    if (!listing) return <></>

    return (
        <div className="form-group listingFormContainer">

            <div id="listingType" className="listingType">
                <div className="row radio-group">

                    <div className="col-6 padding-10" key="sale">
                        <Radio name="listing_type"
                               id="sale"
                               value="sale"
                               checked={listing.listing_type === "sale"}
                               onChange={handleChange}
                               disabled={!saleOffer || !saleOffer.buyer}
                               className="nobox"
                        />
                        <label htmlFor="sale">
                            <div className="tile">

                                <div className="tileHeader">
                                    <span>Sell Debt</span>

                                </div>

                                <hr/>

                                {saleOffer && saleOffer.buyer && <>
                                    <p className="text-center"><strong>Sell your debt now for</strong></p>

                                    <div
                                        className="listingTypePrice text-center">{saleOffer && saleOffer.buyer && formatCurrency(saleOffer.best_offer)}</div>
                                    {/*<ul>*/}
                                    {/*    <li>Automatic posting to marketplace if not resolved in 7 days</li>*/}
                                    {/*</ul>*/}

                                    <button
                                        className="btn btn-primary btn-block"
                                        onClick={() => handleChooseListingType("sale")}
                                        type="button">
                                        Proceed
                                    </button>
                                    <a href="https://debtplacer.com" target="_blank">
                                        <button
                                            className="btn btn-default btn-block"
                                            type="button">
                                            Learn More
                                        </button>
                                    </a>
                                </>}

                                {saleOffer && !saleOffer.buyer && <>
                                    <p className="text-center">Debt Sale Not Available</p>
                                    <a href="https://debtplacer.com" target="_blank">
                                        <button
                                            className="btn btn-default btn-block"
                                            type="button">
                                            Find out why
                                        </button>
                                    </a>
                                </>}

                            </div>
                        </label>
                    </div>

                    <div className="col-6 padding-10" key="marketplace">
                        <Radio name="listing_type"
                               id="marketplace"
                               value="marketplace"
                               checked={listing.listing_type === "marketplace"}
                               onChange={handleChange}
                               className="nobox"
                        />
                        <label htmlFor="marketplace">

                            <div className="tile">

                                <div className="tileHeader">
                                    <span>Send to a Debt Collector</span>
                                    {/*<div className="listingTypePrice">FREE</div>*/}
                                </div>
                                <hr/>
                                <p className="text-center"><strong>No-win, no-fee debt collection</strong></p>

                                <ul>
                                    <li>Choose from a verified network of Collection Partners</li>
                                    <li>Safe & Secure</li>
                                    <li>Privacy Protection</li>
                                </ul>

                                <button
                                    className="btn btn-primary btn-block"
                                    onClick={() => handleChooseListingType("marketplace")}
                                    type="button">
                                    Select
                                </button>
                                {/*<button*/}
                                {/*    className="btn btn-default btn-block"*/}
                                {/*    type="button">*/}
                                {/*    Learn More*/}
                                {/*</button>*/}
                            </div>

                        </label>
                    </div>
                </div>
            </div>

        </div>
    )
}