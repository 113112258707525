import React from "react";
import Select from 'react-select';
import _ from "lodash";


class BetterSelect extends React.Component {

    handleSelect = (selectedOption) => {
        const {onChange, name, valueProperty} = this.props;
        if (!selectedOption) return onChange({currentTarget: {name, value: null}});
        const value = selectedOption[valueProperty];
        return onChange({currentTarget: {name, value}});
    }

    render() {

        const {data, name, options, label, valueProperty, textProperty, emptyLabel, disabled} = this.props;

        const value = _.get(data, name) || "";
        const selectedOption = options.find(option => option[valueProperty] === value);

        return (
            <div className="form-group">
                {label?<label htmlFor={value}>{label}</label>: null}
                <Select options={options}
                        classNamePrefix={name}
                        styles={{
                            singleValue: (provided, state) => {
                                return { ...provided, maxWidth: "150%" };
                            },
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                        onChange={this.handleSelect}
                        value={selectedOption || ""}
                        getOptionValue={option => option[valueProperty]}
                        getOptionLabel={option => option[textProperty]}
                        placeholder={emptyLabel}
                        isDisabled={disabled}
                        isClearable
                />
            </div>
        );
    }
}

BetterSelect.defaultProps = {
    textProperty: "title",
    valueProperty: "pk"
};

export {
    BetterSelect
}
