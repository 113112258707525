import React from "react";
import {baseHandleChange} from "../common/forms/helpers";
import {renderTextArea} from "../common/forms/TextArea"
import {Ratings} from "../common/ratings";
import http from "../../services/httpService";
import config from "../../constants";
import {Button} from 'react-bootstrap'
import Select from 'react-select';
import axios from 'axios'

const Joi = require('joi');


class ReviewForm extends React.Component {
    state = {
        data: {rating: 0, comment: "", tags: []},
        errors: {},
    };

    schema = {
        1: {
            comments: Joi.string()
                .required()
                .label("comments")
        }
    };

    async componentDidMount() {
        const apiPath = "/review_tags/";
        const {data: tags} = await axios.get(config.API_URL + apiPath);
        this.setState({tags})
    }

    handleChange = ({currentTarget: input}) => {
        const {data, errors} = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({data, errors});
    };

    handleSubmit = async (e) => {
        if (e) e.preventDefault();
        return await this.doSubmit();
    };

    doSubmit = async () => {
        const {data} = this.state;
        let review = {
            invite_id: this.props.match.params.id,
            secret: this.props.match.params.secret,
            rating: data.rating,
            tags: data.tags,
            comments: data.comments
        }

        const apiPath = "/reviews/";
        await axios.post(config.API_URL + apiPath, review)
            .then((response) => {
                this.props.history.push("/review/submitted")
            })
            .catch((error) => {
            })
    };

    handleRating = (rating) => {
        let data = this.state.data
        data.rating = rating
        this.setState({data})
    }

    setChecked = (value) => {
        let data = this.state.data
        let found = data.tags.find(tag => tag === value)
        if (found) {
            data.tags = data.tags.filter(tag => tag !== value)
        } else {
            data.tags.push(value)
        }

        this.setState({data})
    }
    isChecked = (value) => {
        let tags = this.state.data.tags
        let found = tags.find(tag => tag === value)
        return !!found
    }

    render() {
        let availableTags = this.state.tags ? this.state.tags.filter(tag => {
            return tag.show_on_rating_from <= this.state.data.rating && tag.show_on_rating_to >= this.state.data.rating
        }).map(tag => {
            return {value: tag.id, label: tag.title}
        }) : []
        return (
            <div className="login-outer">
                <div className="container review">
                    <h1>Review</h1>
                    <br/>
                    <form onSubmit={this.handleSubmit}>
                        <p>Rating</p>
                        <Ratings value={this.state.data.rating} handleRating={this.handleRating} size="fa-lg"/>
                        <br/>
                        <br/>
                        <p>Tags</p>
                        {this.state.data.rating ? availableTags.map(tag => {
                            return <Button
                                style={{margin: 5}}
                                key={tag.value}
                                variant={this.isChecked(tag.value) ? "primary" : "secondary"}
                                value="1"
                                onClick={(e) => this.setChecked(tag.value)}
                            >
                                {tag.label}
                            </Button>
                        }) : null}
                        <br/>
                        <br/>
                        {renderTextArea(this.state.data, this.state.errors, this.handleChange, "comments",
                            "Comments",
                            "Type your comment here"
                        )}
                        <button className="btn btn-primary float-right" type="submit">
                            Submit Review
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default ReviewForm;
