import React from "react";
import moment from "moment";

export function listedDate (date) {

    const daysSinceListed = moment().diff(date, 'days');
    const isToday = moment(date).isSame(moment(), 'day');

    if (isToday) return "Today"

    if (daysSinceListed <= 6) return `${daysSinceListed} days ago`

    if (daysSinceListed > 6 && daysSinceListed <= 11) return "1 week ago"

    if (daysSinceListed > 11 && daysSinceListed <= 14) return "2 weeks ago"

    return ""
}