import React, {useEffect, useState} from "react";

import http from "../../services/httpService";
import config from "../../constants";
import {validateWithoutSteps} from "../common/forms/validation";
import Joi from "joi";

const apiPath = "/placer_listings_embedded/";


export default function EmbeddedListingCreation(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [errors, setErrors] = useState({})
    const [apiError, setApiError] = useState({})
    const params = new URLSearchParams(window.location.search)

    // basic stripped down schema to avoid API errors
    const debtSchema = {
        due_date: Joi.date().required().label("Due Date"),
        amount: Joi.string().required().label("Amount").messages({"string.base": "Please enter the amount owed"}),
        debtor: {
            debtor_type: Joi.string().required(),
        }
    }

    useEffect(() => {
        doCreateListing()
    }, []);

    async function doCreateListing() {
        try {
            const listing = {
                status: "draft",
                debts: [{
                    debtor: {
                        business_name: params.get('business_name'),
                        first_name: params.get('first_name'),
                        last_name: params.get('last_name'),
                        abn: params.get('abn'),
                        address_1: params.get('address_1'),
                        address_2: params.get('address_2'),
                        suburb: params.get('suburb'),
                        zip_code: params.get('zip_code'),
                        state: params.get('state'),
                        contact_phone: params.get('contact_phone'),
                        contact_email: params.get('contact_email'),
                        contact_first_name: params.get('contact_first_name'),
                        contact_last_name: params.get('contact_last_name'),
                        debtor_type: params.get('debtor_type'),
                        date_of_birth: params.get('date_of_birth'),
                    },
                    amount: params.get('amount'),
                    due_date: params.get('due_date')
                }]
            }

            // basic validation to avoid API errors
            const newErrors = validateWithoutSteps(debtSchema, listing.debts[0])
            setErrors(newErrors)

            if (newErrors) return

            const response = await http.post(config.API_URL + apiPath, listing);

            props.history.push({
                pathname: `/embedded/listings/${response.data.id}/edit/?mode=embedded`
            })
        } catch (e) {
            // console.log(e)
            setApiError(e.data)
        } finally {
            setIsLoading(false)
        }

    }

    return (<div>

        {!isLoading && <>
            <h1>Send to third party collection</h1>
            <p>Please fix the following errors:</p>

            {errors && Object.keys(errors).length > 0 &&
                <ul>
                    {Object.entries(errors).map(([key, value], i) => (
                        <li>{key}: {value}</li>
                    ))}

                </ul>}

            {apiError && Object.keys(apiError).length > 0 &&
                <ul>
                    {Object.entries(apiError).map(([key, value], i) => (
                        <li>{key}: {value}</li>
                    ))}

                </ul>}
        </>}

    </div>)

}