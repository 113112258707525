import {React} from "react";
import {renderTextArea} from "../common/forms/TextArea";
import {renderCheckbox} from "../common/forms/Checkbox";


export default function ListingDetailsStep({listing, errors, handleChange, embedded}) {

    if (!listing) return <></>

    let textAreaLabel = "Tell us more about the type of product or service that you provided the customer";
    let textAreaPlaceholder = embedded ? "" : "e.g. We carried out IT consulting services for the customer however they only paid their first invoice. The customer acknowledges the debt that they owe but are no longer responding to our emails or calls requesting payment.";

    if (listing.debts && listing.debts.length > 1) {
        textAreaLabel = "Tell us more about the type of debt owing from these clients";
        textAreaPlaceholder = "e.g. This multi-debt ledger relates to families with overdue school fees.";
    }

    return (
        <div className="form-group listingFormContainer">
            <h4>{embedded ? <>About the product or service</> : <>Listing Detail</>}</h4>
            {renderTextArea(listing, errors, handleChange, "comments", textAreaLabel, textAreaPlaceholder)}
            {!embedded && renderCheckbox(listing, errors, handleChange, "is_personally_guaranteed", "This debt is personally guaranteed")}
            {!embedded && renderCheckbox(listing, errors, handleChange, "is_disputed", "This debt is being disputed")}
            {!embedded && listing.is_disputed && renderTextArea(listing, errors, handleChange, "is_disputed_comments",
                "Tell us more about why the customer is disputing this debt",
                "Describe why the customer is currently disputing the invoices which are owing"
            )}
        </div>
    )
}