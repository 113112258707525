import React, {Component} from "react";
import EditOffer from "../common/editOffer";
import {Button, Media, Spinner} from "react-bootstrap"
import OfferFees from "../common/OfferFees";
import {getInitials} from "../../utils/getInitials";
import ReadTermsCondition from "./readTermsCondition";

class ChatOffer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            hideReject: false
        }
    }

    render() {
        const {chat, accessType, rejectOffer, withdrawOffer, acceptOffer, reload} = this.props
        let withdrawOfferButton = <Button variant="secondary" className='offer-button' onClick={withdrawOffer}>Withdraw
            Offer</Button>

        let acceptOfferButton = <ReadTermsCondition offer={chat.offer} acceptOffer={acceptOffer} hideReject={() => {
            let hideReject = !this.state.hideReject
            this.setState({hideReject})
            rejectOffer(false)
        }}/>

        // const acceptOfferButton = <PandaDocSign offer={chat.offer} reload={reload} />

        let rejectOfferButton = this.state.hideReject ? null :
            <Button variant="secondary" className='offer-button' onClick={()=>rejectOffer(true)}>Message the Collection Partner</Button>

        let offerTitle = (chat) =>
            <h5>{accessType.type === 'collection_partner' ? (chat.offer.status === 'pending' ? "Your offer to " + chat.placer : chat.offer.status === 'withdrawn' ? "Your offer has been withdrawn." : chat.offer.status === 'rejected' ? "Your offer has been rejected by " + chat.placer : chat.offer.status === 'accepted' ? "Your offer has been accepted by " + chat.placer : null) :
                (chat.offer.status === 'pending' ? chat.collection_partner.business_name + " made you an offer" : chat.offer.status === 'withdrawn' ? chat.collection_partner.business_name + " withdrew its offer" : chat.offer.status === 'rejected' ? "You rejected " + chat.collection_partner.business_name + " offer" : chat.offer.status === 'accepted' ? "You accepted " + chat.collection_partner.business_name + " offer" : null)}&nbsp;</h5>

        return <Media as="li" className={'chat-feed'}>
            <div className="avatar avatar-sm">
                <div className="avatar__letters">
                    {getInitials(accessType.type === 'collection_partner' ? chat.placer : chat.collection_partner.business_name)}
                </div>
            </div>
            <Media.Body className={'chat-feed-body'}>
                <div className={'chat-feed-message  chat-offer'}>
                    {offerTitle(chat)}
                    <h6><OfferFees offer={chat.offer}/></h6>
                    <p>
                        <strong>Included in scope</strong><br/>
                        <span>{chat.offer.included_in_scope} </span>
                    </p>

                    {chat.offer.excluded_from_scope && <React.Fragment>
                        <p>
                            <strong>Excluded from scope: </strong><br/>
                            <span>{chat.offer.excluded_from_scope}</span>
                        </p>
                    </React.Fragment>}

                    {accessType.type === 'collection_partner' ?
                        <div className="chat-buttons d-grid gap-2">
                            {chat.offer.status === 'accepted' ? null :
                                <EditOffer reload={reload} offer={chat.offer}
                                           listing={chat.listing}/>}
                            {chat.offer.status === 'pending' ? withdrawOfferButton : null}
                        </div> :
                        <div className="chat-buttons d-grid gap-2">
                            {chat.listing.status === 'listed' && chat.offer.status === 'pending' ? acceptOfferButton : null}
                            {chat.listing.status === 'listed' && chat.offer.status === 'pending' ? rejectOfferButton : null}
                        </div>}
                </div>
            </Media.Body>
        </Media>

    }
}

export default ChatOffer;
