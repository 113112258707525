import { Component } from "react";
import auth from "../../services/authService";

class Logout extends Component {
  async componentDidMount() {
    await auth.logout();
    if (this.props.location?.pathname.startsWith('/embedded/')) {
      window.location = "/embedded/close";
    } else {
      window.location = "/";
    }
  }

  render() {
    return null;
  }
}

export default Logout;
