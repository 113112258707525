

import React from "react";

const InfoContainer = props => {

    const { type, iconClass } = props;

    return (
        <div className={"infoContainer " + type}>
            <div className="infoContainerIcon"><i className={iconClass} /></div>
            <div className="infoContainerContent">{props.children}</div>
        </div>
    );
};

export default InfoContainer;
