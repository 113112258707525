import React, {Component} from "react";
import {Link} from "react-router-dom";

class Register extends Component {

    render() {

        return (
            <div className="container">

                <div className="row">

                    <div className="col-sm-6">
                        <h1>Become a Collection Partner</h1>

                        <p>We’re excited to have you join the Debtplacer movement and we look forward to facilitating
                            debt resolutions with you soon.</p>

                        <Link to="/register-collection-partner">
                            <button className="btn btn-primary btn-block registerCollectionPartner">Become a Collection Partner</button>
                        </Link>

                    </div>

                    <div className="col-sm-6">

                        <h1>Become a Placer</h1>

                        <p>We’re excited to have you join the Debtplacer movement and we look forward to facilitating
                            debt resolutions with you soon.</p>

                        <Link to="/register-client">
                            <button className="btn btn-primary btn-block registerPlacer">Become a Placer</button>
                        </Link>

                    </div>

                </div>

            </div>
        );
    }
}

export default Register;
