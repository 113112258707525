import React from "react";
import './../../progressTracker.scss';
import moment from "moment";
import Resolve from "../common/resolve";

const ProgressTrackerCollectionPartner = ({
                                              listing,
                                              debt,
                                              handleCompleteLetterOfDemand,
                                              handleCommenceNegotiations,
                                              handleMarkCourtActionRequired,
                                              handleNegotiationsOngoing,
                                              handleResolveListing,
                                              resolvedAmountOptions
                                          }) => {

    const letterOfDemandClassName = debt.letter_of_demand_sent ? "completed" : "active";

    let negotiationsClassName = "future";
    if (debt.letter_of_demand_sent != null && debt.negotiations_commenced == null && listing.status === 'resolved') negotiationsClassName = "hidden"
    else if (debt.letter_of_demand_sent != null && debt.negotiations_commenced == null && listing.status === 'offer_accepted') negotiationsClassName = "active";
    else if (debt.negotiations_commenced != null && (listing.status === 'resolved' || debt.court_action_required != null)) negotiationsClassName = "completed";
    else if (debt.negotiations_commenced != null) negotiationsClassName = "completed last";

    const debtSettledStatus = listing.status === 'resolved' ? "completed" : "future";

    return (
        <div className="progress-tracker">

            <div className="step completed">
                {listing.awarded_date &&
                    <div className="date">{moment(listing.awarded_date).format('DD MMM YYYY')}</div>}
                <div className="title">Collection commenced</div>
            </div>

            <div className={"step " + letterOfDemandClassName}>
                {debt.letter_of_demand_sent &&
                    <div className="date">{moment(debt.letter_of_demand_sent).format('DD MMM YYYY')}</div>}
                <div className="title">Letter of demand</div>
                <p>Letter of demand has been sent to the debtor.</p>

                {!debt.letter_of_demand_sent && <div className="actions">
                    <button className="btn btn-secondary" onClick={handleCompleteLetterOfDemand}>Complete</button>
                </div>}
            </div>

            {negotiationsClassName !== 'hidden' &&
                <div className={"step " + negotiationsClassName}>
                    {debt.negotiations_commenced &&
                        <div className="date">{moment(debt.negotiations_commenced).format('DD MMM YYYY')}</div>}
                    <div className="title">Negotiations {debt.negotiations_commenced && "commenced"}</div>
                    <p>Are negotiations required with the debtor or has the debt been settled?</p>

                    {debt.negotiations_commenced &&
                        <div className="progress-updates">Case notes go here</div>}

                    {listing.status === "offer_accepted" && debt.court_action_required == null &&
                        <div className="actions">

                            <Resolve label="Debt Settled"
                                     style="large"
                                     status={listing.status}
                                     onClick={handleResolveListing}
                                     userType="collection_partner"
                                     deactivated={negotiationsClassName === 'future'}
                                     showUnresolved={false}
                                     resolvedAmountOptions={resolvedAmountOptions}/>

                            {!debt.negotiations_commenced &&
                                <button className="btn btn-tertiary ml-3"
                                        onClick={handleCommenceNegotiations}
                                        disabled={negotiationsClassName === 'future'}
                                >Negotiations Commenced</button>}

                            {debt.negotiations_commenced &&
                                <button className="btn btn-secondary ml-3 new-line-on-mobile"
                                        disabled={!debt.negotiations_ongoing_enabled}
                                        onClick={handleNegotiationsOngoing}>Negotiations Ongoing</button>}

                            {debt.negotiations_commenced &&
                                <button className="btn btn-tertiary-danger ml-3 new-line-on-tablet"
                                        onClick={handleMarkCourtActionRequired}>Court Action Required</button>}

                        </div>}

                </div>}

            {debt.court_action_required == null &&
                <div className={"step " + debtSettledStatus + " debt-settled"}>
                    {listing.date_resolved &&
                        <div className="date">{moment(listing.date_resolved).format('DD MMM YYYY')}</div>}
                    <div className="title">Debt Settled</div>
                    {listing.status === 'resolved' &&
                        <p>Great news! We have just notified the Placer that you have been successful in
                            settling this outstanding debt. Please get in touch with the Placer to discuss next
                            steps and arrange for the distribution of funds to their nominated bank account.</p>}
                </div>}

            {debt.court_action_required != null &&
                <div className="step court-action">
                    {debt.court_action_required &&
                        <div className="date">{moment(debt.court_action_required).format('DD MMM YYYY')}</div>}
                    <div className="title">Court action required</div>
                    <p>As you have suggested that the only way to take
                        this debt recovery forward is through the Court
                        process, please get in touch with the Placer to
                        explain the process from here.</p>
                </div>}

        </div>
    );
};

export default ProgressTrackerCollectionPartner;