import React from "react";
import http from "../../services/httpService";
import config from "../../constants";
import {toast} from "react-toastify";
import {lookupAbn, lookupBusinessName} from "../../utils/abnLookup";
import {urlRegEx} from "../../utils/regularExpressions";
import {baseHandleAddressChange, baseHandleChange} from "../common/forms/helpers";
import {validate} from "../common/forms/validation";
import Form from "../common/forms/Form";
import {renderInput} from "../common/forms/Input";
import {renderSelect} from "../common/forms/Select";
import {loadAddressFinderScript} from "../../utils/addressFinder";
import {Tabs, Tab} from "react-bootstrap"

import {AsyncTypeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
const Joi = require('joi');
const apiPath = "/placer_profiles/";

class VendorProfile extends React.Component {

    state = {
        data: {
            business_name: "",
            abn: "",
            address_1: "",
            address_2: "",
            suburb: "",
            zip_code: "",
            state: "",
            user: {
                first_name: "",
                last_name: ""
            },
            contact_role: "",
            phone: "",
            website: null,
            bank_account_name: null,
            bank_bsb: null,
            bank_account_number: null,
        },
        contactRoles: [],
        addressFinderWidget: null,
        addressPathBase: "",
        abnValidatedClass: "",
        errors: {},
    };

    schema = {
        1: {
            id: Joi.number(),
            business_name: Joi.string().label("Business name"),
            abn: Joi.string().label("ABN"),
            address_1: Joi.any(),
            address_2: Joi.any(),
            suburb: Joi.string(),
            zip_code: Joi.string().length(4).label("Post code"),
            state: Joi.string().max(4).label("State"),
            user: {
                first_name: Joi.string(),
                last_name: Joi.string()
            },
            contact_role: Joi.any(),
            phone: Joi.string(),
            website: Joi.string().regex(urlRegEx, {}).allow(null, '')
                .messages({
                    "object.regex": "Please enter a valid URL starting with http:// or https://",
                    "string.pattern.base": "Please enter a valid URL starting with http:// or https://"
                })
                .label("Website"),
            bank_account_name: Joi.string().max(50).label("Account Name").allow(null, ''),
            bank_bsb: Joi.string().min(6).max(6).label("BSB").allow(null, ''),
            bank_account_number: Joi.string().min(6).max(16).label("Account Number").allow(null, '')
        }
    };

    async componentDidMount() {
        loadAddressFinderScript(this.loadWidget); // address finder
        await this.loadData();
    };

    loadWidget = () => {
        this.addressFinderWidget = new AddressFinder.Widget(
            document.getElementById("address_1"),
            config.ADDRESS_FINDER_API_KEY,
            'AU'
        );
        this.addressFinderWidget.on('result:select', (fullAddress, metaData) => {
            const {data} = baseHandleAddressChange(this.state.data, metaData);
            this.setState({data});
        });
    }

    componentWillUnmount() {
        if (this.addressFinderWidget) {
            this.addressFinderWidget.destroy()
            this.addressFinderWidget = null
        }
    };

    async loadData() {
        const {data: placers} = await http.get(config.API_URL + apiPath);
        const {data: optionSets} = await http.get(config.API_URL + "/option_sets/?code=CONTACT_ROLES");


        let options = [{
            value: placers[0].abn, text: placers[0].business_name
        }]
        this.setState({
            singleSelections: options,
            options,
            data: placers[0], contactRoles: optionSets[0].options
        });
    }

    handleChange = ({currentTarget: input}) => {
        const {data, errors} = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
        this.setState({data, errors});
    };

    handleValidate = () => {
        return validate(this.schema, this.state.data)
    }

    handleSubmit = async (e) => {

        if (e) e.preventDefault();

        const errors = validate(this.schema, this.state.data);
        this.setState({errors: errors || {}});
        if (errors) return false;

        // additional function based validation (async)
        const asyncErrors = await this.validateOnSubmit();
        this.setState({errors: asyncErrors || {}});
        if (asyncErrors) return false;

        return await this.doSubmit();

    };

    doSubmit = async () => {
        try {
            await http.patch(config.API_URL + apiPath + this.state.data.id + "/", this.state.data);
            toast.success("Saved successfully.", {autoClose: 3000});
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                const errors = {...this.state.errors};
                this.setState({errors});
                toast.error("Error.");
            }
        }
    };

    validateAbn = async () => {
        // ABN validation
        const abn = this.state.data.abn;
        const abnValid = await lookupAbn(abn);

        if (abnValid) {
            this.setState({abnValidatedClass: "valid"})
            return null;
        }

        this.setState({abnValidatedClass: "invalid"})
        return {'abn': "Please enter a valid ABN"};
    };

    validateOnSubmit = async () => {
        return await this.validateAbn();
    };

    setSingleSelections(singleSelections) {
        let data = this.state.data
        data.abn = singleSelections[0]?.value
        data.business_name = singleSelections[0]?.text
        this.setState({data, singleSelections})
    }

    render() {

        return (
            <div className="container">
                <h1>Manage Profile</h1>
                <Form onSubmit={this.handleSubmit}
                      validate={this.handleValidate}
                > <br/>

                    <Tabs defaultActiveKey="profile" id="profile-tabs">
                        <Tab eventKey="profile" title="Address">
                            <br/>
                            <div>
                                <div className={"form-group mb-2"}>
                                    <label htmlFor={name} className={""}>Business or entity name</label>
                                    <AsyncTypeahead
                                        delay={1500}
                                        filterBy={() => true}
                                        labelKey="text"
                                        minLength={3}
                                        isLoading={this.state.isLoading}
                                        onSearch={async (query) => {
                                            this.setState({isLoading: true})
                                            let items = await lookupBusinessName(query);
                                            const options = items.map((i) => ({
                                                text: i.Name,
                                                value: i.Abn,
                                            }));
                                            this.setState({options, isLoading: false})
                                        }}
                                        options={this.state.options}
                                        placeholder="Business or entity name"
                                        onChange={this.setSingleSelections.bind(this)}
                                        selected={this.state.singleSelections}
                                    />
                                </div>
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "abn", "ABN", "", "text", this.state.abnValidatedClass, this.validateAbn, null, '11 111 111 111')}

                                {renderInput(this.state.data, this.state.errors, this.handleChange, "address_1", "Address Line 1")}
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "address_2", "Address Line 2")}

                                <div className="form-row">
                                    <div className="col-6">
                                        {renderInput(this.state.data, this.state.errors, this.handleChange, "suburb", "Suburb")}
                                    </div>
                                    <div className="col-3">
                                        {renderInput(this.state.data, this.state.errors, this.handleChange, "zip_code", "Post Code")}
                                    </div>
                                    <div className="col-3">
                                        {renderInput(this.state.data, this.state.errors, this.handleChange, "state", "State")}
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="contact" title="Contact">
                            <br/>
                            <div>
                                <div className="form-row">
                                    <div className="col-6">
                                        {renderInput(this.state.data, this.state.errors, this.handleChange, "user.first_name", "First Name")}
                                    </div>
                                    <div className="col-6">
                                        {renderInput(this.state.data, this.state.errors, this.handleChange, "user.last_name", "Last Name")}
                                    </div>
                                </div>

                                {renderSelect(this.state.data, this.state.errors, this.handleChange, "contact_role", "Role", this.state.contactRoles)}
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "phone", "Phone")}
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "website", "Website")}
                            </div>
                        </Tab>
                        <Tab eventKey="bankDetails" title="Payout Details">
                            <br/>
                            <div>
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "bank_account_name", "Account Name")}
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "bank_bsb", "BSB")}
                                {renderInput(this.state.data, this.state.errors, this.handleChange, "bank_account_number", "Account Number")}
                            </div>
                        </Tab>
                    </Tabs>
                </Form>
            </div>
        );
    }
}

export default VendorProfile;
