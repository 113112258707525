import http from './httpService'
import config from '../constants'


export function importInvoices() {
  return http.get(config.API_URL + '/integrations/intuit/invoices/import/', {
    timeout: 0,
  })
}

export function getToken() {
  return http.get(config.API_URL + '/integrations/intuit/oauth2.0/status/')
}

export function getInvoices() {
  return http.get(config.API_URL + '/integrations/intuit/invoice_summary/')
}

export function postCode(code) {
  return http.post(
    config.API_URL + '/integrations/intuit/oauth2.0/exchange/code/',
    { ...code },
  )
}

export function createListingFromInvoices(contacts) {
  return http.post(
      config.API_URL + '/integrations/intuit/create_listing_from_invoices/',
      { "contacts": contacts },
  )
}

export function disconnect() {
  return http.post(
      config.API_URL + '/integrations/intuit/disconnect/'
  )
}